import { useEffect } from 'react'
import {
  Modal,
  createStyles,
  SimpleGrid,
  TextInput,
  Textarea,
  Group,
  Button,
  Tabs,
  Switch,
  TabsValue,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Tools } from 'tabler-icons-react'
import { useMediaQuery } from '@mantine/hooks'
import { useState } from 'react'
import { useForm } from '@mantine/form'
import { createQuestionType, updateQuestionType } from '../../utils/axios'
import { QuestionType } from '../../utils/types'

export type QuestionTypeViewEditProps = {
  isOpen: boolean
  closeModalAction: () => void
  selectedQuestionType: QuestionType | null
  afterInsertObserver: (qTypeCreated: QuestionType) => void
}

const initialVals: QuestionType = {
  id: 0,
  label: '',
  value: '',
  image: null,
  isActive: true,
  isDeleted: false,
  description: null,
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    fieldInput: {
      flex: 1,

      '& + &': {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },

    fieldsGroup: {
      display: 'flex',

      [BREAKPOINT]: {
        flexDirection: 'column',
      },
    },

    title: {
      marginBottom: theme.spacing.xl * 1.5,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.xl,
      },
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  }
})

export const QuestionTypeViewEdit = ({
  isOpen,
  closeModalAction,
  selectedQuestionType,
  afterInsertObserver,
}: QuestionTypeViewEditProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const largeScreen = useMediaQuery('(min-width: 1000px)')
  const [activeTab, setActiveTab] = useState<string | null>('general')

  const onTabChange = (active: TabsValue) => {
    setActiveTab(active)
  }

  const form = useForm<QuestionType>({
    initialValues: selectedQuestionType ?? initialVals,

    validate: (values) => ({
      /* name: values.name.length < 2 ? 'Too short name' : null,
      name: (value) => value.trim().length >= 2,
      age:
        values.age === undefined
          ? 'Age is required'
          : values.age < 18
          ? 'You must be at least 18'
          : null, */
    }),
    //initialErrors: { name: 'Name must include at least 2 letters' },
  })

  useEffect(() => {
    if (selectedQuestionType != null) {
      form.setValues(selectedQuestionType)
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionType])

  return (
    <>
      <Modal
        centered
        opened={isOpen}
        onClose={() => closeModalAction()}
        size={largeScreen ? '50%' : '100%'}
        withCloseButton={false}
      >
        <form
          className={classes.form}
          onSubmit={form.onSubmit((values) => {
            console.log('ON FORM SUBMIT ', values)
            if (selectedQuestionType == null) {
              createQuestionType(values).then((res) => {
                closeModalAction()
                afterInsertObserver(values)
              })
            } else {
              updateQuestionType(values.id, values).then((res) => {
                closeModalAction()
                afterInsertObserver(values)
              })
            }
            form.setValues(initialVals)
          })}
        >
          <Tabs
            orientation='horizontal'
            defaultValue={activeTab}
            onTabChange={onTabChange} /* styles={{ body: { width: '90%' } }} */
          >
            <Tabs.Tab title={t('general') ?? ''} value='general' icon={<Tools size={14} />}>
              <SimpleGrid
                cols={3}
                breakpoints={[
                  { maxWidth: 'lg', cols: 2, spacing: 'sm' },
                  { maxWidth: 'xs', cols: 1, spacing: 'sm' },
                ]}
              >
                <TextInput
                  required
                  label={t('title')}
                  placeholder={t('title') ?? ''}
                  /* defaultValue={selectedQuiz != null ? selectedQuiz.name : form.values.name}
                  onChange={(event) => form.setFieldValue('name', event.currentTarget.value)} */
                  {...form.getInputProps('label')}
                />
                <TextInput
                  required
                  label={t('value')}
                  placeholder={t('value') ?? ''}
                  {...form.getInputProps('value')}
                />
                <Textarea
                  label={t('description')}
                  placeholder={t('description') ?? ''}
                  radius='md'
                  autosize
                  minRows={2}
                  maxRows={3}
                  defaultValue={form.values.description ?? ''}
                  onChange={(event) => form.setFieldValue('description', event.currentTarget.value)}
                />
                <Switch
                  size='md'
                  label={t('active_switch')}
                  defaultChecked={form.values.isActive}
                />
              </SimpleGrid>
            </Tabs.Tab>
          </Tabs>
          <Group position='right' mt='md'>
            <Button type='submit' className={classes.control} onClick={() => {}}>
              {t('save_button')}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  )
}
