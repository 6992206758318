import React, { useCallback, useEffect, useState } from 'react'
import {
  Container,
  createStyles,
  Table,
  Title,
  Text,
  ActionIcon,
  Button,
  Switch,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Edit, Plus, Trash } from 'tabler-icons-react'
import { useModals } from '@mantine/modals'
import { deleteQuestionType } from '../../../utils/axios'
import { QuestionTypeViewEdit } from '../../../components/QuestionTypeViewEdit'
import { fetchQuestionTypes } from '../../../utils/commonFunctions'
import { QuestionType } from '../../../utils/types'
import { ImageRetry } from '../../../components/ImageRetry'

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },
    quizTable: {
      width: '100%',
      marginBottom: theme.spacing.xl,

      '& thead tr th': {
        textAlign: 'center',
      },
    },
    centered: {
      textAlign: 'center',
    },
    right: {
      textAlign: 'right',
    },
  }
})

export const AllQuestionTypes = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const modals = useModals()
  const [questionTypeSelected, setQuestionTypeSelected] = useState<QuestionType | null>(null)
  const [questionTypeViewEditModalOpen, setQuestionTypeViewEditModalOpen] = useState<boolean>(false)
  const [questionTypes, setQuestionTypes] = useState<QuestionType[] | null>(null)

  const startFetchQuestionTypes = useCallback(async (ignoreQTypesFetch?: boolean) => {
    const result = await fetchQuestionTypes()
    if (!ignoreQTypesFetch) {
      setQuestionTypes(result)
    }
  }, [])

  useEffect(() => {
    let ignoreQTypesFetch = false
    startFetchQuestionTypes(ignoreQTypesFetch)
    return () => {
      ignoreQTypesFetch = true
    }
  }, [startFetchQuestionTypes])

  const openDeleteModal = (qTypeId: number) =>
    modals.openConfirmModal({
      title: t('delete_question_type_title'),
      centered: true,
      children: <Text size='sm'>{t('delete_question_type_question')}</Text>,
      labels: { confirm: t('delete_title'), cancel: t('cancel_title') },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        deleteQuestionType(qTypeId).then((res) => {
          modals.closeAll()
          setQuestionTypes(
            questionTypes?.filter((itemForRemoval) => itemForRemoval.id !== qTypeId)!
          )
        })
      },
    })

  const openQuestionTypeViewEditModal = (questionTypeId: number | null) => {
    if (questionTypeId != null) {
      let questionTypeById: QuestionType | null =
        questionTypes?.find((item) => item.id === questionTypeId) ?? null
      setQuestionTypeSelected(questionTypeById)
    }

    setQuestionTypeViewEditModalOpen(true)
  }

  const closeQuestionTypeViewEditModal = () => {
    setQuestionTypeSelected(null)
    setQuestionTypeViewEditModalOpen(false)
  }

  const afterInsertObserver = (qTypeCreated: QuestionType) => {
    startFetchQuestionTypes()
  }

  const questionTypeRows = questionTypes?.map((qType) => (
    <tr key={qType.id}>
      <td>{qType.label}</td>
      <td className={classes.centered}>{qType.image && <ImageRetry src={``} />}</td>
      <td>{qType.description}</td>
      <td>
        <Switch sx={{ justifyContent: 'center' }} size='md' checked={qType.isActive} readOnly />
      </td>
      <td>
        <ActionIcon
          size={'xl'}
          title={t('editTitle')}
          onClick={() => openQuestionTypeViewEditModal(qType.id)}
        >
          <Edit />
        </ActionIcon>
        <ActionIcon
          size={'xl'}
          title={t('delete_title')}
          color={'red'}
          onClick={() => {
            openDeleteModal(qType.id)
          }}
        >
          <Trash />
        </ActionIcon>
      </td>
    </tr>
  ))

  const headers = (
    <tr>
      <th>{t('title')}</th>
      <th>{t('image')}</th>
      <th>{t('description')}</th>
      <th>{t('active_switch')}</th>
      <th></th>
    </tr>
  )

  return (
    <Container my='md' size={'xl'}>
      <Title align='center' className={classes.title}>
        {t('allQuestionTypes')}
      </Title>
      <Button leftIcon={<Plus size={14} />} onClick={() => openQuestionTypeViewEditModal(null)}>
        {t('add_new_question_type')}
      </Button>
      <div className='table-wrapper'>
        <Table highlightOnHover className={classes.quizTable} withColumnBorders>
          <thead>{headers}</thead>
          <tbody>{questionTypeRows}</tbody>
          {/* <tfoot>{headers}</tfoot> */}
        </Table>
      </div>

      <QuestionTypeViewEdit
        key={questionTypeSelected?.id}
        isOpen={questionTypeViewEditModalOpen}
        closeModalAction={closeQuestionTypeViewEditModal}
        selectedQuestionType={questionTypeSelected}
        afterInsertObserver={afterInsertObserver}
      />
    </Container>
  )
}
