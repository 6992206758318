import { LinkPropsCustom, ROLES_ENUM } from './types'
import { Home2, Book, Building, Users } from 'tabler-icons-react'

export const LOCAL_STORAGE_CONSTANTS = {
  selectedLanguage: 'selectedLanguage',
  authToken: 'authToken',
}

export const COOKIES_CONSTANTS = {
  userAcceptedCookie: 'quiz-to-go-user-accepted-cookie',
}

export const APP_LANGUAGES = ['hr', 'en']

export const APP_LANGUAGES_SELECTOR_ITEMS: {
  image: string
  label: string
  description: string
  value: string
}[] = [
  {
    label: 'Hrvatski',
    description: 'Reci kaj ili krepaj!',
    value: 'hr',
    image: '/flags/hr.png',
  },
  {
    label: 'English',
    description: "Hey Ho, Let's go!",
    value: 'en',
    image: '/flags/en.png',
  },
]

export const headerNavigationLinks: Array<LinkPropsCustom> = [
  {
    icon: Home2,
    label: 'Home',
    keyValue: 'home',
    path: '/home',
    initiallyOpened: true,
    isPublic: false,
  },
  {
    icon: Building,
    label: 'Organizations',
    keyValue: 'organizations',
    path: '/organizations',
    initiallyOpened: false,
    isPublic: false,
    roleNeeded: [ROLES_ENUM.SUPER_ADMIN, ROLES_ENUM.ADMIN],
  },
  {
    icon: Users,
    label: 'Users',
    keyValue: 'users',
    path: '/users',
    initiallyOpened: false,
    isPublic: false,
    roleNeeded: [ROLES_ENUM.SUPER_ADMIN],
    /* sublinks: [
      { label: 'Users', keyValue: 'users', path: '/users' },
      { label: 'Roles', keyValue: 'roles', path: '/roles' },
    ], */
  },
  {
    icon: Book,
    label: 'Quiz setup',
    keyValue: 'quizSetupGroup',
    path: '/quiz-management',
    initiallyOpened: false,
    isPublic: false,
    roleNeeded: [ROLES_ENUM.SUPER_ADMIN, ROLES_ENUM.ADMIN],
    /* sublinks: [
      { label: 'All quizzes', keyValue: 'allQuizzes', path: '/quiz-management' },
      {
        label: 'Question type management',
        keyValue: 'questionTypeManagement',
        path: '/question-type-management',
      },
    ], */
  },
]
