import { getAllRoles, getAllUsers } from '../../utils/axios'
import { Role, User } from '../../utils/types'

export const fetchRoles = async (): Promise<Role[]> => {
  const roles = await getAllRoles()
  return roles
}

export const fetchUsers = async (): Promise<User[]> => {
  const users = await getAllUsers()
  return users
}
