import { Modal, createStyles, SimpleGrid, TextInput, Group, Button, Switch } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { Answer } from '../../../utils/types'

export type SimpleAnswerCreatorProps = {
  isOpen: boolean
  closeModalAction: () => void
  saveButtonAction: ({ text, isCorrect }: Answer) => void
  pointsNeeded?: boolean
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  }
})

export const SimpleAnswerCreator = ({
  isOpen,
  closeModalAction,
  saveButtonAction,
  pointsNeeded = true,
}: SimpleAnswerCreatorProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const createAnswerAction = ({ text, isCorrect, points }: Answer) => {
    saveButtonAction({ text, isCorrect, points })
  }

  const form = useForm<Answer>({
    initialValues: { text: '', isCorrect: false, points: 0 },

    validate: (values) => ({
      label: values.text.length < 1 ? t('missing_answer_input') : null,
    }),
  })

  return (
    <Modal centered opened={isOpen} onClose={() => closeModalAction()}>
      <form
        className={classes.form}
        onSubmit={form.onSubmit((values) => {
          createAnswerAction({
            text: values.text,
            isCorrect: values.isCorrect,
            points: pointsNeeded ? values.points : 0,
          })
          form.reset()
        })}
      >
        <div className={classes.fields}>
          <SimpleGrid cols={1}>
            <TextInput
              label={t('answer_text')}
              placeholder={t('answer_text') ?? ''}
              required
              {...form.getInputProps('text')}
            />
            {pointsNeeded && (
              <TextInput
                label={t('answer_points')}
                placeholder={t('answer_points') ?? ''}
                required={pointsNeeded}
                {...form.getInputProps('points')}
                type='number'
              />
            )}
            <Switch
              size='md'
              label={t('correct_answer_flag')}
              {...form.getInputProps('isCorrect', { type: 'checkbox' })}
            />
          </SimpleGrid>
          <Group position='right' mt='md'>
            <Button type='submit' className={classes.control}>
              {t('save_button')}
            </Button>
          </Group>
        </div>
      </form>
    </Modal>
  )
}
