import { LoadingOverlay } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { fetchOrganizations } from '../containers/Organizations/helper'
import { fetchQuestionTypes } from '../utils/commonFunctions'
import { Organization, QuestionType } from '../utils/types'

export type GeneralContextProps = {
  toggleLoadingOverlay: (isLoading: boolean) => void
  organizations: Organization[]
  refreshOrganizations: () => void
  questionTypes: QuestionType[]
}

const GeneralContext = React.createContext<GeneralContextProps>({
  toggleLoadingOverlay: (isLoading: boolean) => {},
  organizations: [],
  refreshOrganizations: () => {},
  questionTypes: [],
})

export const useGeneralContext = () => {
  return React.useContext(GeneralContext)
}

const GeneralProvider = ({ children }: any) => {
  const [isLoadingOverlayVisible, setIsLoadingOverlayVisible] = useState(false)
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([])

  const startFetchOrganizations = useCallback(async (ignoreFetch?: boolean) => {
    const result = await fetchOrganizations()
    if (!ignoreFetch) {
      setOrganizations(result)
    }
  }, [])

  const refreshOrganizations = useCallback(() => {
    startFetchOrganizations(false)
  }, [startFetchOrganizations])

  const startFetchQuestionTypes = useCallback(async (ignoreQTypesFetch?: boolean) => {
    const result = await fetchQuestionTypes()
    if (!ignoreQTypesFetch) {
      setQuestionTypes(result)
    }
  }, [])

  useEffect(() => {
    let ignoreOrganizationsFetch = false
    startFetchOrganizations(ignoreOrganizationsFetch)

    let ignoreQTypesFetch = false
    startFetchQuestionTypes(ignoreQTypesFetch)

    return () => {
      ignoreOrganizationsFetch = true
      ignoreQTypesFetch = true
    }
  }, [startFetchOrganizations, startFetchQuestionTypes])

  const toggleLoadingOverlay = (isLoading: boolean) => {
    setIsLoadingOverlayVisible(isLoading)
  }

  const generalContextValue: GeneralContextProps = useMemo(
    () => ({
      toggleLoadingOverlay: toggleLoadingOverlay,
      organizations: organizations,
      refreshOrganizations: refreshOrganizations,
      questionTypes: questionTypes,
    }),
    [organizations, refreshOrganizations, questionTypes]
  )

  return (
    <GeneralContext.Provider value={generalContextValue}>
      {children}
      <LoadingOverlay
        visible={isLoadingOverlayVisible}
        overlayBlur={1}
        transitionDuration={250}
        pos={'fixed'}
      />
    </GeneralContext.Provider>
  )
}

export { GeneralProvider, GeneralContext }
