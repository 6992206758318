import { showNotification } from '@mantine/notifications'
import {
  getAllQuestionTypes,
  getAllQuizzes,
  getOrganizationAndQuizzes,
  getOrganizationById,
  getQuestion,
  getQuizContent,
  getQuizTeamsNames,
  getResultsForQuiz,
} from './axios'
import {
  Organization,
  OrganizationAndQuizzes,
  QuestionsAndAnswers,
  QuestionType,
  Quiz,
  QuizAndRounds,
  QuizResult,
} from './types'

export const fetchQuestionTypes = async (): Promise<QuestionType[]> => {
  return await getAllQuestionTypes()
}

export const fetchQuestion = async (questionId: number): Promise<QuestionsAndAnswers> => {
  return await getQuestion(questionId)
}

export const fetchQuizzes = async (): Promise<Quiz[]> => {
  return await getAllQuizzes()
}

export const fetchQuiz = async (id: number): Promise<QuizAndRounds> => {
  return await getQuizContent(id)
}

export const fetchQuizTeamsNames = async (id: number): Promise<any> => {
  return await getQuizTeamsNames(id)
}

export const fetchOrganizationById = async (id: number): Promise<Organization> => {
  return await getOrganizationById(id)
}

export const fetchOrganizationWithQuizzes = async (id: number): Promise<OrganizationAndQuizzes> => {
  return await getOrganizationAndQuizzes(id)
}

export const fetchResultsForQuiz = async (quizId: number): Promise<QuizResult> => {
  return await getResultsForQuiz(quizId)
}

export const showSuccessfulNotification = (title: string, message: string) => {
  showNotification({
    autoClose: 5000,
    title: title,
    message: message,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.blue[6],
        borderColor: theme.colors.blue[6],

        '&::before': { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        '&:hover': { backgroundColor: theme.colors.blue[7] },
      },
    }),
  })
}

export const showErrorNotification = (title: string, message: string) => {
  showNotification({
    autoClose: 5000,
    title: title,
    message: message,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.red[6],
        borderColor: theme.colors.red[6],

        '&::before': { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        '&:hover': { backgroundColor: theme.colors.red[7] },
      },
    }),
  })
}

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const escapeHtml = (unsafe: string) => {
  return (
    unsafe
      /* .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;') */
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  )
}

export const unEscapeHtml = (unsafe: string) => {
  return (
    unsafe
      /* .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;') */
      .replace('&quot;', '"')
      .replace('&#039;', "'")
  )
}

export const canShowImage = (newImageUploaded: File | null | undefined, dbImage: any) => {
  return newImageUploaded || dbImage
}
