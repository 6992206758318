import React, { useState } from 'react'
import {
  createStyles,
  Group,
  Code,
  Modal,
  Button,
  Burger,
  Drawer,
  useMantineTheme,
} from '@mantine/core'
import { Logout, Login } from 'tabler-icons-react'
import { useAuth } from '../../contexts/AuthProvider'
import { DEFAULT_ROLE_ID, LinkPropsCustom } from '../../utils/types'
import { useNavigate } from 'react-router-dom'
import { LinksGroup } from '../../components/NavbarLinksGroup'
import { useTranslation } from 'react-i18next'
import { AuthenticationForm } from '../../components/AuthorizationForm'
import { QuizLogo } from '../../components/QuizLogo'
import { headerNavigationLinks } from '../../utils/commonData'
import { isMobile } from '../../utils/commonFunctions'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { UserProfileSubmenu } from './UserProfileSubmenu'

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  return {
    header: {
      padding: theme.spacing.md,
      color: theme.white,
    },
    headerNavigation: {
      display: 'flex',
      alignItems: 'center',
    },
    linkIcon: {
      ref: icon,
      color: '#70f01f',
      marginRight: theme.spacing.sm,
    },
    links: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
    },
    userInfo: {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      cursor: 'pointer',
    },
  }
})

export const Navigation = () => {
  const navigate = useNavigate()
  const { onLogout, userProfile } = useAuth()
  const { classes } = useStyles()
  const [activeMainLink, setActiveMainLink] = useState<string>('home')
  const { t } = useTranslation()
  const [isAuthFormVisible, setIsAuthFormVisible] = useState<boolean>(false)
  const largeScreen = useMediaQuery('(min-width: 1008px)') // why 1008: https://www.browserstack.com/guide/ideal-screen-sizes-for-responsive-design
  const [hamburgerOpened, { toggle }] = useDisclosure(false)
  const hamburgerLabel = hamburgerOpened ? t('title_close_navigation') : t('title_open_navigation')
  const theme = useMantineTheme()

  const onMainLinkClick = (mainLink: LinkPropsCustom) => {
    if ((mainLink.sublinks ?? []).length === 0) {
      setActiveMainLink(mainLink.keyValue)
      navigate(mainLink.path)
      toggle()
    }
  }

  const onSublinkClick = (path: string, keyValue: string) => {
    setActiveMainLink(keyValue)
    navigate(path)
  }

  const links = headerNavigationLinks.map((item) => {
    if (
      userProfile != null &&
      (item.roleNeeded == null || item.roleNeeded?.includes(userProfile?.roleId ?? DEFAULT_ROLE_ID))
    ) {
      return (
        <LinksGroup
          {...item}
          label={t(item.keyValue)}
          key={item.label}
          onClick={() => {
            onMainLinkClick(item)
          }}
          linkSelected={activeMainLink}
          onSublinkClick={onSublinkClick}
        />
      )
    } else {
      return null
    }
  })

  return (
    <>
      <div className={classes.header}>
        <Group position='apart'>
          <Group>
            <QuizLogo isMobile={isMobile() || !largeScreen} />
            {/* {!isMobile() && largeScreen && ( */}
            <Code sx={{ fontWeight: 500 }}>{process.env.REACT_APP_CURRENT_VERSION}</Code>
            {/* )} */}
          </Group>
          {isMobile() || !largeScreen ? (
            <Burger
              color='#45f50d'
              opened={hamburgerOpened}
              onClick={toggle}
              aria-label={hamburgerLabel}
            />
          ) : (
            <>
              <div className={classes.headerNavigation}>{links}</div>
              <Group align='center'>
                {userProfile ? (
                  <>
                    <UserProfileSubmenu
                      userProfile={userProfile}
                      onLogout={() => {
                        toggle()
                        setActiveMainLink('home')
                        onLogout()
                      }}
                    />
                    <Button
                      variant='subtle'
                      onClick={() => {
                        toggle()
                        setActiveMainLink('home')
                        onLogout()
                      }}
                    >
                      <Logout className={classes.linkIcon} />
                      <span>{t('logout')}</span>
                    </Button>
                  </>
                ) : (
                  <Button
                    variant='subtle'
                    onClick={() => {
                      toggle()
                      setIsAuthFormVisible(true)
                    }}
                  >
                    <Login className={classes.linkIcon} />
                    <span>{t('login')}</span>
                  </Button>
                )}
              </Group>
            </>
          )}
        </Group>
      </div>
      {isAuthFormVisible && (
        <Modal
          centered
          opened={isAuthFormVisible}
          onClose={() => setIsAuthFormVisible(false)}
          title={t('login')}
        >
          <AuthenticationForm isModal={true} close={() => setIsAuthFormVisible(false)} />
        </Modal>
      )}
      {isMobile() || !largeScreen ? (
        <Drawer
          opened={hamburgerOpened}
          position='right'
          size={'sm'}
          onClose={toggle}
          overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
          overlayOpacity={0.55}
          overlayBlur={3}
          withCloseButton={false}
        >
          <div style={{ margin: 10 }}>{links}</div>
          <div
            style={{
              width: '100%',
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              padding: 10,
              justifyContent: 'space-evenly',
            }}
          >
            {userProfile ? (
              <>
                <UserProfileSubmenu
                  userProfile={userProfile}
                  onLogout={() => {
                    setActiveMainLink('home')
                    onLogout()
                  }}
                />
                <Button
                  variant='subtle'
                  onClick={() => {
                    toggle()
                    setActiveMainLink('home')
                    onLogout()
                  }}
                >
                  <Logout className={classes.linkIcon} />
                  <span>{t('logout')}</span>
                </Button>
              </>
            ) : (
              <Button
                variant='subtle'
                onClick={() => {
                  toggle()
                  setIsAuthFormVisible(true)
                }}
              >
                <Login className={classes.linkIcon} />
                <span>{t('login')}</span>
              </Button>
            )}
          </div>
        </Drawer>
      ) : null}
    </>
  )
}
