import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  createStyles,
  SimpleGrid,
  TextInput,
  Textarea,
  Group,
  Button,
  Switch,
  Container,
  Card,
  Text,
  Badge,
  ActionIcon,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ChevronLeft, X } from 'tabler-icons-react'
import { useForm } from '@mantine/form'
import { Organization, Tag } from '../../../utils/types'
import { useNavigate, useParams } from 'react-router-dom'
import { canShowImage, fetchOrganizationById } from '../../../utils/commonFunctions'
import { createOrganizationAction, updateOrganizationAction } from '../helper'
import { CategoryCreator } from '../../../components/CategoryCreator'
import { showNotification } from '@mantine/notifications'
import { hostname } from '../../../utils/axios'
import { ImageRetry } from '../../../components/ImageRetry'
import { useAuth } from '../../../contexts/AuthProvider'
import { useGeneralContext } from '../../../contexts/GeneralProvider'

const initialVals: Organization = {
  name: '',
  owner: 0,
  isActive: true,
  isDeleted: false,
  image: '',
  country: '',
  city: '',
  description: '',
  tags: [],
  isPublic: true,
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      position: 'relative',
    },
    label: {
      textTransform: 'uppercase',
      fontSize: theme.fontSizes.xs,
      fontWeight: 700,
    },
    fieldInput: {
      flex: 1,

      '& + &': {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },
    mandatoryBorder: {
      borderColor: theme.colors.red[6],
    },
    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  }
})

export const CreateEditOrganization = () => {
  const { toggleLoadingOverlay, refreshOrganizations } = useGeneralContext()
  const { userProfile } = useAuth()
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const { organizationId } = useParams()
  const [organization, setOrganization] = useState<Organization | null>(null)
  const navigate = useNavigate()
  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(false)
  const [isUploadProfileImageOpen, setIsUploadProfileImageOpen] = useState(false)
  const hiddenInputRef = useRef<HTMLInputElement | null>(null)
  const [uploadedImage, setUploadedImage] = useState<File | null>(null)

  const startFetchOrganization = useCallback(
    async (ignoreOrganizationFetch?: boolean) => {
      const result = await fetchOrganizationById(parseInt(organizationId ?? '0'))
      if (!ignoreOrganizationFetch) {
        setOrganization(result)
      }
    },
    [organizationId]
  )

  useEffect(() => {
    let ignoreOrganizationFetch = false

    if (organizationId == null) {
      setOrganization(initialVals)
      return
    }

    startFetchOrganization(ignoreOrganizationFetch)

    return () => {
      ignoreOrganizationFetch = true
    }
  }, [organizationId, startFetchOrganization])

  useEffect(() => {
    if (organization != null) {
      form.setValues(organization)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  const form = useForm<Organization>({
    initialValues: organization ?? initialVals,

    validate: (values) => ({
      name: values.name.length < 1 ? '' : null,
      country: values.country.length < 1 ? '' : null,
      city: values.city.length < 1 ? '' : null,
      //rounds: values.rounds < 1 ? t("invalidRoundsNumber") : null
      /* name: values.name.length < 2 ? 'Too short name' : null,
      name: (value) => value.trim().length >= 2,
      age:
        values.age === undefined
          ? 'Age is required'
          : values.age < 18
          ? 'You must be at least 18'
          : null, */
    }),
    //initialErrors: { name: 'Name must include at least 2 letters' },
  })

  const removeButton = (tag: string) => {
    return (
      <ActionIcon
        size='xs'
        color='blue'
        radius='xl'
        variant='transparent'
        onClick={() => {
          const tagsNew: Tag[] = form.values.tags.filter((tagItem) => tagItem.label !== tag) ?? []
          form.setFieldValue('tags', tagsNew)
        }}
      >
        <X size={12} />
      </ActionIcon>
    )
  }

  const handleUploadButtonClick = () => {
    // 👇️ open file input box on click of other element
    hiddenInputRef.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    //orgTmp.image = window.URL.createObjectURL(fileObj)
    setUploadedImage(fileObj)

    showNotification({
      autoClose: 5000,
      title: t('information'),
      message: t('save_changes_warning'),
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.blue[6],
          borderColor: theme.colors.blue[6],

          '&::before': { backgroundColor: theme.white },
        },

        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.blue[7] },
        },
      }),
    })
  }

  const submitForm = async (values: Organization) => {
    toggleLoadingOverlay(true)
    if (organizationId != null) {
      //tu je potrebno imati newImage jer values.image ima path koji gledam za brisanje prethodne slike
      values.newImage = uploadedImage != null ? uploadedImage : undefined
      await updateOrganizationAction(values)
    } else {
      if (uploadedImage != null) {
        values.image = uploadedImage
      }
      values.owner = userProfile?.id!
      await createOrganizationAction(values)
    }
    refreshOrganizations()
    toggleLoadingOverlay(false)
    //form.reset() -- this will reset to initial values
  }

  const mandatoryImageBorder = () => {
    return (
      uploadedImage == null &&
      typeof form.values.image === 'string' &&
      form.values.image.length === 0
    )
  }

  return (
    <>
      <Container my='md' size={'xl'}>
        <Button onClick={() => navigate(-1)} leftIcon={<ChevronLeft />} style={{ marginRight: 20 }}>
          {t('back')}
        </Button>
        <form
          className={classes.form}
          onSubmit={form.onSubmit((values: Organization) => {
            submitForm(values)
          })}
        >
          <SimpleGrid
            cols={3}
            breakpoints={[
              { maxWidth: 'lg', cols: 2, spacing: 'sm' },
              { maxWidth: 'xs', cols: 1, spacing: 'sm' },
            ]}
          >
            <TextInput
              required
              label={t('title')}
              placeholder={t('title') ?? ''}
              {...form.getInputProps('name')}
            />
            <TextInput
              required
              label={t('country')}
              placeholder={t('country') ?? ''}
              {...form.getInputProps('country')}
            />
            <TextInput
              required
              label={t('city')}
              placeholder={t('city') ?? ''}
              {...form.getInputProps('city')}
            />
            <Textarea
              label={t('description')}
              placeholder={t('description') ?? ''}
              radius='md'
              autosize
              minRows={2}
              maxRows={3}
              defaultValue={form.values.description ?? ''}
              onChange={(event) => form.setFieldValue('description', event.currentTarget.value)}
            />
            <Card withBorder radius='md' p='md' className={classes.card}>
              <Text className={classes.label} color='dimmed'>
                {t('organizationCategoriesTitle')}
              </Text>
              <Group spacing={7} mt={5}>
                {form.values.tags.map((tag) => (
                  <Badge
                    variant='outline'
                    key={tag.label}
                    leftSection={tag.emoji}
                    rightSection={removeButton(tag.label)}
                    size='lg'
                  >
                    {tag.label}
                  </Badge>
                ))}
              </Group>
              <Button
                variant='light'
                color='blue'
                fullWidth
                style={{ marginTop: 14 }}
                onClick={() => {
                  setIsCreateCategoryModalOpen(true)
                }}
              >
                Dodaj novu kategoriju
              </Button>
            </Card>
            <Card
              withBorder
              radius='md'
              p='md'
              className={`${cx(classes.card, {
                [classes.mandatoryBorder]: mandatoryImageBorder(),
              })}`}
              onMouseEnter={() => setIsUploadProfileImageOpen(true)}
              onMouseLeave={() => setIsUploadProfileImageOpen(false)}
            >
              <div className={`${isUploadProfileImageOpen ? 'light-blur' : ''}`}>
                {canShowImage(uploadedImage, form.values.image) && (
                  <ImageRetry
                    radius='md'
                    src={`${
                      uploadedImage != null
                        ? window.URL.createObjectURL(uploadedImage)
                        : hostname + '/' + form.values.image
                    }`}
                    alt={form.values.name}
                    width='100%'
                  />
                )}
              </div>
              {isUploadProfileImageOpen && (
                <div className='view-upload-image-form'>
                  <ActionIcon
                    size={'xl'}
                    title={t('uploadImage')}
                    onClick={(e: any) => {
                      e.stopPropagation()
                      handleUploadButtonClick()
                    }}
                  >
                    <span className='material-symbols-outlined'>upload</span>
                  </ActionIcon>
                  {/* 👇 Notice the `display: hidden` on the input. Used to invoke upload file form.*/}
                  <input
                    type='file'
                    accept={'image/png, image/jpeg, image/jpg'}
                    ref={hiddenInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </div>
              )}
            </Card>
            <Switch
              size='md'
              label={t('active_switch')}
              {...form.getInputProps('isActive', { type: 'checkbox' })}
            />
            <Switch
              size='md'
              label={t('public_switch')}
              {...form.getInputProps('isPublic', { type: 'checkbox' })}
            />
          </SimpleGrid>
          <Group position='right' mt='md'>
            <Button type='submit' className={classes.control} disabled={!form.isValid()}>
              {t('save_button')}
            </Button>
          </Group>
        </form>
        <CategoryCreator
          key={'category_creator' + organization?.tags.length}
          isOpen={isCreateCategoryModalOpen}
          closeModalAction={() => setIsCreateCategoryModalOpen(false)}
          saveButtonAction={({ emoji, label }: Tag) => {
            const tagsNew: Tag[] = [...form.values.tags, { emoji, label }]
            form.setFieldValue('tags', tagsNew)
            setIsCreateCategoryModalOpen(false)
          }}
        />
      </Container>
    </>
  )
}
