import { FC, forwardRef, useCallback, useEffect, useState } from 'react'
import {
  Group,
  Text,
  Grid,
  Skeleton,
  Select,
  Avatar,
  Container,
  TextInput,
  Button,
  NumberInput,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { QuestionType, QUESTION_TYPES, QuestionsAndAnswers } from '../../utils/types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { fetchQuestion } from '../../utils/commonFunctions'
import { MultiplePickAnswer } from '../../components/QuestionCreationTemplates/MultiplePickAnswer'
import { SiglePickAnswer } from '../../components/QuestionCreationTemplates/SinglePickAnswer'
import { ChevronLeft } from 'tabler-icons-react'
import { ImageQuestion } from '../../components/QuestionCreationTemplates/ImageQuestion'
import { Risk } from '../../components/QuestionCreationTemplates/Risk'
import { Sequence } from '../../components/QuestionCreationTemplates/Sequence'
import { WriteAnswer } from '../../components/QuestionCreationTemplates/WriteAnswer'
import { SubquestionBlankSpaces } from '../../components/QuestionCreationTemplates/SubquestionBlankSpaces'
import { CorrectValueEstimation } from '../../components/QuestionCreationTemplates/CorrectValueEstimation'
import { useGeneralContext } from '../../contexts/GeneralProvider'

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string
  label: string
  description: string
  value: string
  isActive?: boolean
  isDeleted?: boolean
  createdAt?: Date
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    { image, label, description, value, isActive, isDeleted, createdAt, ...others }: ItemProps,
    ref
  ) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image && <Avatar src={image} />}
        <div>
          <Text size='sm'>{label}</Text>
          <Text size='xs' color='dimmed'>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
)

const skeleton = <Skeleton height={150} radius='md' animate={true} />

export const QuestionCreationForm: FC = () => {
  const { questionTypes } = useGeneralContext()
  const { toggleLoadingOverlay } = useGeneralContext()
  const { quizId, questionId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [questionTypeSelected, setQuestionTypeSelected] = useState<string | null>(null)
  const [timeDuration, setTimeDuration] = useState<number>(30)
  const [questionPosition, setQuestionPosition] = useState<number>(state?.position || 1)
  const [questionRound, setQuestionRound] = useState<number>(state?.round || 1)
  const [initialData, setInitialData] = useState<QuestionsAndAnswers>()

  const onQuestionTypeSelectionChange = (selectedQuestionTypeKey: string) => {
    setQuestionTypeSelected(selectedQuestionTypeKey)
  }

  const startFetchQuestion = useCallback(
    async (questionTypes: QuestionType[]) => {
      if (questionId) {
        toggleLoadingOverlay(true)
        const result = await fetchQuestion(parseInt(questionId))
        toggleLoadingOverlay(false)

        if (result?.question) {
          const q = result?.question
          const questionTypeMatch = questionTypes?.find(
            (questionTypeItem) => questionTypeItem.id === q.questionTypeId
          )
          if (questionTypeMatch) {
            setInitialData(result)
            setQuestionTypeSelected(questionTypeMatch.value)
            setQuestionRound(q.round)
            setTimeDuration(q.timeDuration)
            setQuestionPosition(q.position)
          }
        }
      }
    },
    [questionId, toggleLoadingOverlay]
  )

  const refreshQuestionsAndAnswers = () => {
    startFetchQuestion(questionTypes ?? [])
  }

  useEffect(() => {
    if (questionId) {
      startFetchQuestion(questionTypes)
    }
  }, [questionId, questionTypes, startFetchQuestion])

  return (
    <>
      <Container my='md'>
        <Grid>
          <Grid.Col>
            <Button
              onClick={() => navigate(-1)}
              leftIcon={<ChevronLeft />}
              style={{ marginRight: 20 }}
            >
              {t('back')}
            </Button>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Select
              label={t('select_question_type')}
              placeholder={t('select_one') ?? ''}
              itemComponent={SelectItem}
              data={questionTypes == null ? [] : questionTypes}
              searchable
              maxDropdownHeight={400}
              nothingFound={t('no_results')}
              filter={(value, item) =>
                (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim()) ||
                (item.description ?? '').toLowerCase().includes(value.toLowerCase().trim())
              }
              onChange={(value: string) => {
                onQuestionTypeSelectionChange(value)
              }}
              value={questionTypeSelected != null ? questionTypeSelected : null}
              disabled={parseInt(questionId ?? '0') > 0}
            />
          </Grid.Col>
          <Grid.Col xs={1.5}>
            <NumberInput
              decimalSeparator=','
              placeholder={t('questionTimeDuration') ?? ''}
              label={t('questionTimeDuration')}
              value={timeDuration}
              precision={1}
              step={0.5}
              onChange={(nmb) => {
                setTimeDuration(nmb ?? 30)
              }}
            />
          </Grid.Col>
          {(() => {
            switch (questionTypeSelected) {
              case QUESTION_TYPES.SINGLE_PICK_ANSWER:
                return (
                  <SiglePickAnswer
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                    refreshInitialData={refreshQuestionsAndAnswers}
                  />
                )
              case QUESTION_TYPES.MULTIPLE_PICK_ANSWER:
                return (
                  <MultiplePickAnswer
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                    refreshInitialData={refreshQuestionsAndAnswers}
                  />
                )
              case QUESTION_TYPES.WRITE_ANSWER:
                return (
                  <WriteAnswer
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                  />
                )
              case QUESTION_TYPES.SUBQUESTION_BLANK_SPACES:
                return (
                  <SubquestionBlankSpaces
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                  />
                )
              case QUESTION_TYPES.CORRECT_VALUE_ESTIMATION:
                return (
                  <CorrectValueEstimation
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                  />
                )
              case QUESTION_TYPES.RISK:
                return (
                  <Risk
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                  />
                )
              case QUESTION_TYPES.IMAGE_QUESTION:
                return (
                  <ImageQuestion
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                  />
                )
              case QUESTION_TYPES.SEQUENCE:
                return (
                  <Sequence
                    initialData={initialData}
                    quizId={parseInt(quizId ?? '0')}
                    questionRound={questionRound}
                    questionTypeId={
                      questionTypes?.find((findItem) => findItem.value === questionTypeSelected)
                        ?.id!
                    }
                    questionPosition={questionPosition}
                    timeDuration={timeDuration}
                  />
                )
              default:
                return skeleton
            }
          })()}
        </Grid>
      </Container>
    </>
  )
}
