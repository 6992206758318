import React, { useCallback, useEffect, useState } from 'react'
import {
  Container,
  createStyles,
  Table,
  Text,
  ActionIcon,
  Modal,
  Group,
  Badge,
  Menu,
  Divider,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useModals } from '@mantine/modals'
import { ROLES_ENUM, User } from '../../../utils/types'
import { fetchUsers } from '../helper'
import { deleteUserById } from '../../../utils/axios'
import { UserEditForm } from './EditForm'
import { useGeneralContext } from '../../../contexts/GeneralProvider'

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },
    table: {
      width: '100%',
      marginBottom: theme.spacing.xl,

      '& thead tr th': {
        textAlign: 'center',
      },
    },
  }
})

export const Users = () => {
  const { organizations } = useGeneralContext()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const modals = useModals()
  const [users, setUsers] = useState<User[] | null>(null)
  const [userSelected, setUserSelected] = useState<User | null>(null)
  const [isEditFormVisible, setIsEditFormVisible] = useState(false)

  const startFetchUsers = useCallback(async (ignoreFetch?: boolean) => {
    const result = await fetchUsers()
    if (!ignoreFetch) {
      setUsers(result)
    }
  }, [])

  useEffect(() => {
    let ignoreFetch = false
    startFetchUsers(ignoreFetch)

    return () => {
      ignoreFetch = true
    }
  }, [startFetchUsers])

  const openDeleteModal = (userId: number) =>
    modals.openConfirmModal({
      title: t('delete_user_title'),
      centered: true,
      children: <Text size='sm'>{t('delete_user_question')}</Text>,
      labels: { confirm: t('delete_title'), cancel: t('cancel_title') },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        deleteUserById(userId).then((res) => {
          console.log(res)
          if (res.status === 200) {
            let filteredArray: User[] =
              users?.filter((filterItem) => filterItem.id !== userId) ?? []
            setUsers(filteredArray)
          }
        })
      },
    })

  const userRows = users?.map((user: User) => (
    <tr key={user.id}>
      <td>{user.email}</td>
      <td align='center'>{new Date(user.lastLoginDate).toLocaleString()}</td>
      <td>{ROLES_ENUM[user.roleId]}</td>
      <td>
        {
          <Group spacing={7} mt={5}>
            {user.organizations?.map((org) => (
              <Badge variant='light' key={org.id} size='lg'>
                {org.name}
              </Badge>
            ))}
          </Group>
        }
      </td>
      <td>
        <Menu shadow='md'>
          <Menu.Target>
            <ActionIcon>
              <span className='material-symbols-outlined'>more_vert</span>
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<span className='material-symbols-outlined'>edit</span>}
              onClick={(e: any) => {
                e.stopPropagation()
                setIsEditFormVisible(true)
                setUserSelected(user)
              }}
            >
              {t('editTitle')}
            </Menu.Item>
            <Divider />
            <Menu.Item
              color={'red'}
              icon={<span className='material-symbols-outlined'>delete</span>}
              onClick={(e: any) => {
                e.stopPropagation()
                openDeleteModal(user.id!)
              }}
            >
              {t('delete_title')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  ))

  const headers = (
    <tr>
      <th>{t('email')}</th>
      <th>{t('lastLoginDate')}</th>
      <th>{t('title_role')}</th>
      <th>{t('organizations')}</th>
      <th></th>
    </tr>
  )

  return (
    <>
      <Container my='md' size={'xl'}>
        <div className='table-wrapper'>
          <Table highlightOnHover className={classes.table} withColumnBorders>
            <thead>{headers}</thead>
            <tbody>{userRows}</tbody>
            {/* <tfoot>{headers}</tfoot> */}
          </Table>
        </div>

        <Modal
          centered
          opened={isEditFormVisible}
          onClose={() => setIsEditFormVisible(false)}
          title={t('user')}
        >
          <UserEditForm
            isModal={true}
            close={(refreshData: boolean) => {
              setIsEditFormVisible(false)

              if (refreshData) {
                startFetchUsers()
              }
            }}
            user={userSelected}
            organizations={organizations ?? []}
          />
        </Modal>
      </Container>
    </>
  )
}
