import { Icon } from 'tabler-icons-react'

export enum ROLES_ENUM {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  USER = 3,
}

export const DEFAULT_ROLE_ID = 3

export const ROLES_LIST: { label: string; value: string }[] = [
  {
    label: 'SUPER_ADMIN',
    value: ROLES_ENUM.SUPER_ADMIN.toString(),
  },
  {
    label: 'ADMIN',
    value: ROLES_ENUM.ADMIN.toString(),
  },
  {
    label: 'USER',
    value: ROLES_ENUM.USER.toString(),
  },
]

export type LinkPropsCustom = {
  icon: Icon
  label: string
  keyValue: string
  path: string
  initiallyOpened: boolean
  isPublic: boolean
  sublinks?: Array<SublinkProps>
  roleNeeded?: ROLES_ENUM[]
}

export type SublinkProps = {
  label: string
  keyValue: string
  path: string
}

export type QuestionType = {
  id: number
  label: string
  value: string
  description?: string | null
  image?: { data: number[]; type: string } | null
  isActive: boolean
  isDeleted: boolean
}

export enum QUESTION_TYPES {
  SINGLE_PICK_ANSWER = 'single_pick_answer',
  MULTIPLE_PICK_ANSWER = 'multiple_pick_answer',
  WRITE_ANSWER = 'write_answer',
  SUBQUESTION_BLANK_SPACES = 'subquestion_blank_spaces',
  CORRECT_VALUE_ESTIMATION = 'correct_value_estimation',
  RISK = 'risk',
  SOUND_QUESTION = 'sound_question',
  IMAGE_QUESTION = 'image_question',
  SEQUENCE = 'sequence',
}

export type Quiz = {
  id?: number
  name: string
  createdAt: Date
  startDate: Date
  owner: number
  organizationId: number
  rounds: number
  isDeleted: boolean
  isActive: boolean
  qrCode?: string
  description: string | null
}

export type Team = {
  id?: number
  name: string
  quizId: number
}

export type Role = {
  id?: number
  name: string
  isDeleted: boolean
  isActive: boolean
  createdAt: Date
  owner: number
}

export type Organization = {
  id?: number
  image: string | File
  newImage?: string | File
  name: string
  country: string
  city: string
  description: string
  tags: Tag[]
  owner: number
  isActive: boolean
  isDeleted: boolean
  isPublic: boolean
  createdAt?: Date
  rating?: number | null
  ratingCount?: number | null
  difficulty?: number | null
  difficultyCount?: number | null
}

export type OrganizationAndQuizzes = {
  organization: Organization
  quiz: Quiz[]
}

export type QuizAndRounds = {
  pages: any;
  quiz: Quiz
  rounds: Round[]
}

export type Question = {
  id?: number
  image?: any
  points: number
  questionText: string
  questionTypeId: number
  layout?: number
  type?: string
  quizId: number
  round: number
  owner: number
  position: number
  timeDuration: number
  isActive?: boolean
  answers?: Answer[]
  subQuestions?: SubQuestion[]
}

export type Round = {
  number: number
  questions: QuestionPageQuizPlayer[]
}

export type NewQuestionInitialInfo = {
  round: number
  position: number
}

export type QuestionPageQuizPlayer = {
  [x: string]: any;
  id?: number
  image?: any
  points?: number
  questionText?: string
  text?: string
  subtext?: string
  questionTypeId: number
  quizId: number
  round: number
  owner: number
  position: number
  timeDuration: number
  isActive?: boolean
  subQuestions: SubQuestion[]
  answers: Answer[]
}

export type TeamRoundResult = {
  roundNumber: number
  points: number
}
export type TeamResult = {
  name: string
  position: number
  rounds: TeamRoundResult[]
  teamId: number
  totalPoints: number
}

export type QuizResult = {
  teamResults: TeamResult[]
  quiz: Quiz
}

export type QuizRoundResult = {
  id: number
  quizId: number
  teamId: number
  points: number
  roundNumber: number
  teamName: string
  totalPoints: number
}

export type QuizTotalPointsByTeam = {
  teamId: number
  teamName: string
  totalPoints: number
}

export type QuizTeam = {
  id: number
  name: string
  totalPoints: number
  roundData: number[]
}

export type Answer = {
  id?: number
  questionId?: number
  text: string
  image?: string | null
  isCorrect: boolean
  points?: number
  position?: number
}

export type SubQuestion = {
  id?: number
  questionId?: number
  text: string
  image?: string | File
  points: number
  position?: number
}

export type QuestionsAndAnswers = {
  answers: Answer[]
  question: Question
  subQuestions: SubQuestion[]
}

export type Tag = {
  emoji: string
  label: string
}

export type User = {
  id?: number
  firstName: string
  lastName: string
  nickname: string
  lastLoginDate: Date
  email: string
  roleId: number
  isActive: boolean
  isDeleted: boolean
  createdAt: Date
  firebaseUID?: string
  organizations?: Organization[]
}
