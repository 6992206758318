import React, { useEffect, useState } from 'react'
import { fetchResultsForQuiz, showErrorNotification } from '../../../utils/commonFunctions'
import { QuizResult } from '../../../utils/types'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import { QuizResultsTable } from './QuizResultsTable'
import { QuizResultInsertResultsTable } from '../InsertTeamResult/QuizResultInsertResultsTable'

type TeamsRankingProps = {
  isInserting: boolean
}

export const TeamsRanking = ({ isInserting }: TeamsRankingProps) => {
  const [quizResult, setQuizResult] = useState<QuizResult>()
  const { quizId } = useParams()

  useEffect(() => {
    if (quizId) {
      fetchResultsForQuiz(parseInt(quizId))
        .then((r) => {
          setQuizResult(r)
        })
        .catch((e) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${e.message}`)
        })
    }
  }, [quizId])

  return (
    <div>
      {quizResult &&
        (isInserting ? (
          <QuizResultInsertResultsTable quizResult={quizResult} />
        ) : (
          <QuizResultsTable quizResult={quizResult} />
        ))}
    </div>
  )
}
