import axios from 'axios'
import {
  Answer,
  Organization,
  OrganizationAndQuizzes,
  Question,
  QuestionsAndAnswers,
  QuestionType,
  Quiz,
  QuizAndRounds,
  QuizResult,
  Role,
  SubQuestion,
  Team,
  User,
} from './types'
import { QuizFeedback } from '../containers/QuizPlayer/RateQuiz'
import { escapeHtml, unEscapeHtml } from './commonFunctions'
import { QuizResultInsertData } from '../containers/QuizPlayer/InsertTeamResult/QuizResultInsertResultsTable'

const ENTITY_ENDPOINTS = {
  question: '/api/question',
  quiz: '/api/quiz',
  team: '/api/team',
  questionType: '/api/question-type',
  organization: '/api/organization',
  roles: '/api/roles',
  users: '/api/users',
  answer: '/api/answer',
}

const mainApi = axios.create({
  timeout: 20000,
})

//serverbackend
export const hostname = 'https://quiz-to-go.com'
//local backend
//export const hostname = 'http://localhost:8080'

//export const hostname = 'http://23.88.53.167:8080'
//export const hostname = 'http://localhost:3001'

const GET = async (prefix: string, url: string, config?: any) => {
  const response = await mainApi.get(`${prefix}${url}`, config)
  // introduce try catch block
  console.log('GET DONE: ', response)
  return response
}

const POST = async (prefix: string, url: string, data?: any, config?: any) => {
  const response = await mainApi.post(`${prefix}${url}`, data, config)
  // introduce try catch block
  console.log('POST DONE: ', response)
  return response
}

const PUT = async (prefix: string, url: string, data?: any, config?: any) => {
  const response = await mainApi.put(`${prefix}${url}`, data, config)
  // introduce try catch block
  console.log('PUT DONE: ', response)
  return response
}

const DELETE = async (prefix: string, url: string, id: number, config?: any) => {
  const response = await mainApi.delete(`${prefix}${url}/${id}`, config)
  // introduce try catch block
  console.log('DELETE DONE: ', response)
  return response
}

// #region Questions
export const createQuestion = (
  question: Question,
  answers: Answer[],
  subQuestions: SubQuestion[] | null
) => {
  let data = new FormData()

  question.questionText = escapeHtml(question.questionText)
  answers.forEach((answerItem) => {
    answerItem.text = escapeHtml(answerItem.text)
  })

  subQuestions?.forEach((subQ) => {
    subQ.text = escapeHtml(subQ.text)
    if (subQ.image instanceof File) {
      data.append(`SubQuestion`, subQ.image)
    }
  })

  data.append('Question', question.image)
  data.append(
    'data',
    JSON.stringify({
      question: question,
      answers: answers,
      subQuestions: subQuestions,
    })
  )

  return POST(hostname, ENTITY_ENDPOINTS.question, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((res: any) => {
    return res
  })
}

export const getAllQuestions = () => {
  return GET(hostname, ENTITY_ENDPOINTS.question).then((res: any) => {
    let questions: Question[] = res.data as Question[]
    questions.forEach((question) => {
      question.questionText = unEscapeHtml(question.questionText)
    })
    return res.data as Question[]
  })
}

export const updateAnswer = (dirtyPayload: any) => {
  if (dirtyPayload.hasOwnProperty('text')) {
    dirtyPayload.text = escapeHtml(dirtyPayload.text)
  }

  return PUT(hostname, `${ENTITY_ENDPOINTS.answer}/update/${dirtyPayload.id}`, dirtyPayload).then(
    (res: any) => {
      return res
    }
  )
}

export const updateQuestion = (
  id: number,
  question: Question,
  answers: Answer[],
  subQuestions: SubQuestion[] | null
) => {
  let data = new FormData()

  question.questionText = escapeHtml(question.questionText)
  answers.forEach((answerItem) => {
    answerItem.text = escapeHtml(answerItem.text)
  })

  subQuestions?.forEach((subQ) => {
    subQ.text = escapeHtml(subQ.text)
    if (subQ.image instanceof File) {
      data.append(`SubQuestion`, subQ.image)
    }
  })

  if (question.image instanceof File) {
    data.append('Question', question.image)
  }

  data.append(
    'data',
    JSON.stringify({
      question: question,
      answers: answers,
      subQuestions: subQuestions,
    })
  )

  return PUT(hostname, `${ENTITY_ENDPOINTS.question}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((res: any) => {
    return res.data
  })
}

export const getQuestion = (id: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.question}/${id}`).then((res: any) => {
    let questionsAndAnswers: QuestionsAndAnswers = res.data as QuestionsAndAnswers
    questionsAndAnswers.answers.forEach((answer) => {
      answer.text = unEscapeHtml(answer.text)
    })
    questionsAndAnswers.question.questionText = unEscapeHtml(
      questionsAndAnswers.question.questionText
    )
    questionsAndAnswers.subQuestions.forEach((subQuestion) => {
      subQuestion.text = unEscapeHtml(subQuestion.text)
    })
    return questionsAndAnswers
  })
}

export const deleteQuestion = (id: number) => {
  return DELETE(hostname, ENTITY_ENDPOINTS.question, id).then((res: any) => {
    return res.data as Question[]
  })
}
// #endregion

//#region Quizzes
export const getAllQuizzes = () => {
  return GET(hostname, ENTITY_ENDPOINTS.quiz).then((res: any) => {
    res.data.forEach((item: Quiz) => {
      //TODO - check with backend if this can be done on their side
      item.startDate = new Date(item.startDate)
      item.createdAt = new Date(item.createdAt)
    })
    return res.data as Quiz[]
  })
}

export const getQuizContent = (id: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.quiz}/${id}`).then((res: any) => {
    //TODO - check with backend if this can be done on their side
    res.data.quiz.startDate = new Date(res.data.quiz.startDate)
    res.data.quiz.createdAt = new Date(res.data.quiz.createdAt)
    return res.data as any
  })
}

export const getQuizInfo = (id: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.quiz}/${id}/info`).then((res: any) => {
    return res.data as Quiz
  })
}

export const createQuiz = (dataToCreate: Quiz) => {
  return POST(hostname, ENTITY_ENDPOINTS.quiz, dataToCreate).then((res: any) => {
    return res.data as Quiz[]
  })
}

export const updateQuiz = (id: number, dataToUpdate: Quiz) => {
  return PUT(hostname, `${ENTITY_ENDPOINTS.quiz}/${id}`, dataToUpdate).then((res: any) => {
    return res.data as Quiz[]
  })
}

export const deleteQuiz = (id: number) => {
  return DELETE(hostname, ENTITY_ENDPOINTS.quiz, id).then((res: any) => {
    return res.data as Quiz[]
  })
}

export const sendQuizFeedback = (id: number, dataToCreate: QuizFeedback) => {
  return POST(hostname, `${ENTITY_ENDPOINTS.quiz}/${id}/quiz-rating`, dataToCreate).then(
    (res: any) => {
      return res.data as Quiz[]
    }
  )
}

//#endregion

//#region Teams

export const createTeam = (dataToCreate: Team) => {
  return POST(hostname, ENTITY_ENDPOINTS.team, dataToCreate).then((res: any) => {
    return res.data as Team
  })
}

export const getQuizTeamsNames = (quizId: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.team}/${quizId}/all`).then((res) => {
    return res.data as any
  })
}

//#region Question types
export const getAllQuestionTypes = () => {
  return GET(hostname, ENTITY_ENDPOINTS.questionType).then((res: any) => {
    return res.data as QuestionType[]
  })
}

export const createQuestionType = (dataToCreate: QuestionType) => {
  return POST(hostname, ENTITY_ENDPOINTS.questionType, dataToCreate).then((res: any) => {
    return res.data as QuestionType[]
  })
}

export const updateQuestionType = (id: number, dataToUpdate: QuestionType) => {
  return PUT(hostname, `${ENTITY_ENDPOINTS.questionType}/${id}`, dataToUpdate).then((res: any) => {
    return res.data as QuestionType[]
  })
}

export const deleteQuestionType = (id: number) => {
  return DELETE(hostname, ENTITY_ENDPOINTS.questionType, id).then((res: any) => {
    return res.data as QuestionType[]
  })
}

//#endregion

//#region Organizations
export const getAllOrganizations = () => {
  return GET(hostname, ENTITY_ENDPOINTS.organization).then((res: any) => {
    return res.data as Organization[]
  })
}

export const getOrganizationById = (id: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.organization}/${id}`).then((res: any) => {
    return res.data as Organization
  })
}

export const getOrganizationAndQuizzes = (id: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.organization}/info/${id}`).then((res: any) => {
    return res.data as OrganizationAndQuizzes
  })
}

export const getResultsForQuiz = (quizId: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.team}/${quizId}/results`).then((res: any) => {
    return res.data as QuizResult
  })
}

export const createResultsForRound = (
  quizResultInsertDataArr: QuizResultInsertData[],
  quizId: number
) => {
  const updatedData = quizResultInsertDataArr.map((data) => ({ ...data, quizId }))
  return POST(hostname, `${ENTITY_ENDPOINTS.team}/round-results`, {
    data: updatedData,
  }).then((res: any) => {
    return res.data as QuizResult
  })
}

export const createOrganization = (dataToCreate: Organization) => {
  //TODO - delete when we have a real user functionality
  let data = new FormData()

  data.append('image', dataToCreate.image)
  data.append('data', JSON.stringify(dataToCreate))

  return POST(hostname, ENTITY_ENDPOINTS.organization, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((res: any) => {
    return res.data as Organization[]
  })
}

export const deleteOrganization = (id: number) => {
  return DELETE(hostname, ENTITY_ENDPOINTS.organization, id).then((res: any) => {
    return res.data as Organization[]
  })
}

export const updateOrganization = (id: number, dataToUpdate: Organization) => {
  let data = new FormData()

  if (dataToUpdate.newImage) {
    data.append('image', dataToUpdate.newImage)
  }
  data.append('data', JSON.stringify(dataToUpdate))

  return PUT(hostname, `${ENTITY_ENDPOINTS.organization}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((res: any) => {
    return res.data as Organization[]
  })
}
//#endregion

// #region Users
export const getAllRoles = () => {
  return GET(hostname, ENTITY_ENDPOINTS.roles).then((res: any) => {
    return res.data as Role[]
  })
}

export const getAllUsers = () => {
  return GET(hostname, ENTITY_ENDPOINTS.users).then((res: any) => {
    return res.data as User[]
  })
}

export const getUserById = (id: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.users}/${id}`).then((res: any) => {
    return res.data as User | null
  })
}

export const getUserByEmail = (email: string) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.users}/findByEmail/${email}`).then((res: any) => {
    return res.data as User[]
  })
}

export const deleteUserById = (id: number) => {
  return DELETE(hostname, ENTITY_ENDPOINTS.users, id).then((res: any) => {
    return res
  })
}

export const deactivateUserById = (id: number) => {
  return GET(hostname, `${ENTITY_ENDPOINTS.users}/deactivate/${id}`).then((res: any) => {
    return res
  })
}

export const createUser = (dataToCreate: User) => {
  return POST(hostname, ENTITY_ENDPOINTS.users, dataToCreate).then((res: any) => {
    return res.data as User
  })
}

export const updateUser = (id: number, dataToUpdate: any) => {
  return PUT(hostname, `${ENTITY_ENDPOINTS.users}/${id}`, dataToUpdate).then((res: any) => {
    return res
  })
}
// #endregion
