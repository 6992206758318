import React, { forwardRef, useState } from 'react'
import { Avatar, Group, Select, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { APP_LANGUAGES_SELECTOR_ITEMS, LOCAL_STORAGE_CONSTANTS } from '../../utils/commonData'

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string
  label: string
  description: string
  value: string
}

const LanguageItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, value, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image && <Avatar src={image} />}
        <div>
          <Text size='sm'>{label}</Text>
          <Text size='xs' color='dimmed'>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
)

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS.selectedLanguage) ?? 'hr'
  )

  const changeLanguage = (lng: string) => {
    setSelectedLanguage(lng)
    i18n.changeLanguage(lng)
    localStorage.setItem(LOCAL_STORAGE_CONSTANTS.selectedLanguage, lng)
  }

  return (
    <>
      <Select
        placeholder={t('select_language') ?? ''}
        itemComponent={LanguageItem}
        data={APP_LANGUAGES_SELECTOR_ITEMS}
        searchable
        maxDropdownHeight={150}
        nothingFound={t('no_results')}
        filter={(value, item) =>
          (item.label ?? '').toLowerCase().includes(value.toLowerCase().trim()) ||
          (item.description ?? '').toLowerCase().includes(value.toLowerCase().trim())
        }
        onChange={(value: string) => {
          changeLanguage(value)
        }}
        value={selectedLanguage}
      />
    </>
  )
}
