import React, { useCallback, useEffect, useState } from 'react'
import {
  Container,
  createStyles,
  Table,
  Text,
  ActionIcon,
  Button,
  Switch,
  Divider,
  Menu,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Plus } from 'tabler-icons-react'
import { useModals } from '@mantine/modals'
import { QuizViewEdit } from '../../../components/QuizViewEdit'
import { createQuiz, deleteQuiz, updateQuiz } from '../../../utils/axios'
import { Quiz, ROLES_ENUM, User } from '../../../utils/types'
import { fetchQuizzes } from '../../../utils/commonFunctions'
import { useNavigate } from 'react-router'
import { useAuth } from '../../../contexts/AuthProvider'
import { fetchUsers } from '../../Users/helper'
import { useGeneralContext } from '../../../contexts/GeneralProvider'

const useStyles = createStyles((theme, _params) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },
    quizTable: {
      width: '100%',
      marginBottom: theme.spacing.xl,

      '& thead tr th': {
        textAlign: 'center',
      },
    },
    centered: {
      textAlign: 'center',
    },
    right: {
      textAlign: 'right',
    },
  }
})

export const AllQuizzes = () => {
  const { organizations } = useGeneralContext()
  const { userProfile } = useAuth()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const modals = useModals()
  const [quizSelected, setQuizSelected] = useState<Quiz | null>(null)
  const [quizViewEditModalOpen, setQuizViewEditModalOpen] = useState<boolean>(false)
  const [quizzes, setQuizzes] = useState<Quiz[] | null>(null)
  const navigate = useNavigate()
  const [users, setUsers] = useState<User[] | null>(null)

  const startFetchUsers = useCallback(async (ignoreFetch?: boolean) => {
    const result = await fetchUsers()
    if (!ignoreFetch) {
      setUsers(result)
    }
  }, [])

  const startFetchQuizzes = useCallback(
    async (ignoreFetch?: boolean) => {
      let result = await fetchQuizzes()
      if (!ignoreFetch) {
        result = result.filter((quiz) => {
          return (
            userProfile?.organizations?.some((userOrg) => quiz.organizationId === userOrg.id) ||
            quiz.owner === userProfile?.id ||
            userProfile?.roleId === ROLES_ENUM.SUPER_ADMIN
          )
        })
        setQuizzes(result)
      }
    },
    [userProfile?.id, userProfile?.organizations, userProfile?.roleId]
  )

  useEffect(() => {
    let ignoreQuizzesFetch = false
    let ignoreUsersFetch = false

    startFetchUsers(ignoreUsersFetch)
    startFetchQuizzes(ignoreQuizzesFetch)

    return () => {
      ignoreQuizzesFetch = true
      ignoreUsersFetch = true
    }
  }, [startFetchQuizzes, startFetchUsers])

  const openDeleteModal = (quizId: number) =>
    modals.openConfirmModal({
      title: t('delete_quiz_title'),
      centered: true,
      children: <Text size='sm'>{t('delete_quiz_question')}</Text>,
      labels: { confirm: t('delete_title'), cancel: t('cancel_title') },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        deleteQuiz(quizId).then(() => {
          startFetchQuizzes()
        })
      },
    })

  const openQuizViewEditModal = (quizId: number | null) => {
    if (quizId != null) {
      const quizById: Quiz | null = quizzes?.find((item) => item.id === quizId) ?? null
      console.log(quizById)
      setQuizSelected(quizById)
    }

    setQuizViewEditModalOpen(true)
  }

  const closeQuizViewEditModal = () => {
    setQuizSelected(null)
    setQuizViewEditModalOpen(false)
  }

  const createQuizAction = async (dataToCreate: Quiz) => {
    return createQuiz(dataToCreate)
      .then(() => {
        closeQuizViewEditModal()
        startFetchQuizzes()
      })
      .catch((exc) => {
        console.error('create quiz failure -> ', exc)
      })
  }

  const updateQuizAction = async (dataToUpdate: Quiz) => {
    return updateQuiz(dataToUpdate.id!, dataToUpdate)
      .then(() => {
        closeQuizViewEditModal()
        startFetchQuizzes()
      })
      .catch((exc) => {
        console.error('update quiz failure -> ', exc)
      })
  }

  const quizRows = quizzes?.map((quiz: Quiz) => (
    <tr key={quiz.id}>
      <td>{quiz.name}</td>
      <td className={classes.centered}>{quiz.startDate.toLocaleDateString('hr-HR')}</td>
      <td>
        {organizations.find((organization) => organization.id === quiz.organizationId)?.name ?? ''}
      </td>
      <td className={classes.centered}>
        {users?.find((user) => user.id === quiz.owner)?.email ?? '?'}
      </td>
      <td className={classes.centered}>{quiz.rounds}</td>
      <td>
        <Switch
          sx={{ display: 'flex', justifyContent: 'center' }}
          size='md'
          checked={quiz.isActive}
          readOnly
        />
      </td>
      <td>
        <Menu shadow='md'>
          <Menu.Target>
            <ActionIcon>
              <span className='material-symbols-outlined'>more_vert</span>
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<span className='material-symbols-outlined'>edit</span>}
              onClick={(e: any) => {
                e.stopPropagation()
                openQuizViewEditModal(quiz.id!)
              }}
            >
              {t('editTitle')}
            </Menu.Item>
            <Menu.Item
              color={'green'}
              icon={<span className='material-symbols-outlined'>add_notes</span>}
              onClick={(e: any) => {
                e.stopPropagation()
                navigate(`/quiz-management/${quiz.id}`)
              }}
            >
              {t('questions')}
            </Menu.Item>
            <Divider />
            <Menu.Item
              color={'red'}
              icon={<span className='material-symbols-outlined'>delete</span>}
              onClick={(e: any) => {
                e.stopPropagation()
                openDeleteModal(quiz.id!)
              }}
            >
              {t('delete_title')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  ))

  const headers = (
    <tr>
      <th>{t('title')}</th>
      <th>{t('startDateQuiz')}</th>
      <th>{t('organizations')}</th>
      <th>{t('createdBy')}</th>
      <th>{t('rounds')}</th>
      <th>{t('active_switch')}</th>
      <th></th>
    </tr>
  )

  return (
    <>
      <Container my='md' size={'xl'}>
        <Button leftIcon={<Plus size={14} />} onClick={() => openQuizViewEditModal(null)}>
          {t('add_new_quiz')}
        </Button>
        <div className='table-wrapper'>
          <Table highlightOnHover className={classes.quizTable} withColumnBorders>
            <thead>{headers}</thead>
            <tbody>{quizRows}</tbody>
            {/* <tfoot>{headers}</tfoot> */}
          </Table>
        </div>
        <QuizViewEdit
          key={quizSelected?.id}
          isOpen={quizViewEditModalOpen}
          closeModalAction={closeQuizViewEditModal}
          selectedQuiz={quizSelected}
          createQuizAction={(dataToCreate: Quiz) => {
            return createQuizAction(dataToCreate)
          }}
          updateQuizAction={(dataToCreate: Quiz) => {
            return updateQuizAction(dataToCreate)
          }}
          organizations={(organizations ?? []).filter((org) => {
            return (
              userProfile?.organizations?.some((userOrg) => org.id === userOrg.id) ||
              userProfile?.roleId === ROLES_ENUM.SUPER_ADMIN
            )
          })}
        />
      </Container>
    </>
  )
}
