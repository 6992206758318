import { Text } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { User as FirebaseUser } from 'firebase/auth'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { createUser, getUserByEmail, updateUser } from '../utils/axios'
import { LOCAL_STORAGE_CONSTANTS } from '../utils/commonData'
import {
  isMobile,
  showErrorNotification,
  showSuccessfulNotification,
} from '../utils/commonFunctions'
import { useFirebase } from '../utils/customHooks/useFirebase'
import { DEFAULT_ROLE_ID, User } from '../utils/types'
import { useGeneralContext } from './GeneralProvider'

enum ACCOUNT_MODAL_TYPE {
  DEACTIVATED = 'deactivated',
  DELETED = 'deleted',
}

export type AuthContextProps = {
  onLogin?: (email: string, password: string) => Promise<FirebaseUser | null>
  onGoogleLogin: () => Promise<FirebaseUser | null>
  onLogout: () => void
  onRegister?: (email: string, password: string) => Promise<FirebaseUser | null>
  userProfile: User | null
}

const AuthContext = React.createContext<AuthContextProps>({
  /* onLogin: (email: string, password: string): Promise<FirebaseUser | null> => {
    return Promise.resolve(null)
  }, */
  onGoogleLogin: (): Promise<FirebaseUser | null> => {
    return Promise.resolve(null)
  },
  onLogout: () => {},
  /* onRegister: (email: string, password: string): Promise<FirebaseUser | null> => {
    return Promise.resolve(null)
  }, */
  userProfile: null,
})

export const useAuth = () => {
  return React.useContext(AuthContext)
}

export type CustomizedState = {
  from: { pathname: string; hash: string; key: string; search: string }
}

const AuthProvider = ({ children }: any) => {
  const { toggleLoadingOverlay } = useGeneralContext()
  const {
    firebaseAppAuth,
    googleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    GoogleAuthProvider,
    signOut,
    getRedirectResult,
  } = useFirebase()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const modals = useModals()
  const authStorage = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.authToken)
  const [user, setUser] = useState<User | null>(
    authStorage != null ? JSON.parse(authStorage) : null
  )

  const prepareUserProfile = (user: FirebaseUser): User => {
    return {
      firstName: user.displayName?.split(' ')[0] ?? '',
      lastName: user.displayName?.split(' ')[1] ?? '',
      nickname: user.displayName ?? '',
      lastLoginDate: new Date(),
      email: user.email ?? '',
      roleId: DEFAULT_ROLE_ID,
      isActive: true,
      isDeleted: false,
      createdAt: new Date(),
      firebaseUID: user.uid,
    }
  }

  const openActivationOrDeletionModal = useCallback(
    (_user: User | null, type: ACCOUNT_MODAL_TYPE) => {
      modals.openConfirmModal({
        title:
          type === ACCOUNT_MODAL_TYPE.DEACTIVATED
            ? t('auth_modal_deactivated_account_title')
            : t('auth_modal_deleted_account_title'),
        centered: true,
        children: (
          <Text size='sm'>
            {type === ACCOUNT_MODAL_TYPE.DEACTIVATED
              ? t('auth_modal_deactivated_account_text')
              : t('auth_modal_deleted_account_text')}
          </Text>
        ),
        labels: {
          confirm:
            type === ACCOUNT_MODAL_TYPE.DEACTIVATED ? t('button_activate') : t('button_confirm'),
          cancel: t('cancel_title'),
        },
        closeOnClickOutside: false,
        closeOnEscape: false,
        onConfirm: async () => {
          if (type === ACCOUNT_MODAL_TYPE.DEACTIVATED) {
            toggleLoadingOverlay(true)
            await updateUser(_user?.id!, {
              isActive: true,
            })
            setUser(_user)
            localStorage.setItem(LOCAL_STORAGE_CONSTANTS.authToken, JSON.stringify(_user))
            toggleLoadingOverlay(false)
          }
        },
      })
    },
    [modals, t, toggleLoadingOverlay]
  )

  const createUserAction = useCallback(async (user: FirebaseUser | null): Promise<User | null> => {
    if (user == null) {
      return Promise.resolve(user)
    }
    const dbUser: User = prepareUserProfile(user)
    const createdUser = await createUser(dbUser)
    return createdUser
  }, [])

  //#region MOBIL REDIRECT CALLBACK
  useEffect(() => {
    if (!isMobile()) {
      return
    }
    if (firebaseAppAuth == null) return
    async function waitForRedirectResult() {
      const result = await getRedirectResult(firebaseAppAuth!)
      console.log('getRedirectResult - ', result)
      if (result) {
        toggleLoadingOverlay(true)
        //const credential = GoogleAuthProvider.credentialFromResult(result)
        let userProfileTmp: User = prepareUserProfile(result.user)

        const existingUsers: User[] = await getUserByEmail(result.user.email ?? '')
        if (existingUsers.length) {
          userProfileTmp = existingUsers[0]

          await updateUser(userProfileTmp.id!, {
            lastLoginDate: new Date(),
          })

          if (!userProfileTmp.isActive) {
            // show activation modal
            openActivationOrDeletionModal(userProfileTmp, ACCOUNT_MODAL_TYPE.DEACTIVATED)
          } else if (userProfileTmp.isDeleted) {
            // show deleted account modal and dont allow login
            openActivationOrDeletionModal(null, ACCOUNT_MODAL_TYPE.DELETED)
          } else {
            setUser(userProfileTmp)
            localStorage.setItem(LOCAL_STORAGE_CONSTANTS.authToken, JSON.stringify(userProfileTmp))
          }
        } else {
          const createdUserRes = await createUserAction(result.user)
          if (createdUserRes != null) {
            setUser(createdUserRes)
            localStorage.setItem(LOCAL_STORAGE_CONSTANTS.authToken, JSON.stringify(createdUserRes))
          }
        }
        toggleLoadingOverlay(false)
      }
    }
    waitForRedirectResult()
  }, [
    GoogleAuthProvider,
    createUserAction,
    firebaseAppAuth,
    getRedirectResult,
    openActivationOrDeletionModal,
    toggleLoadingOverlay,
  ])
  //#endregion

  const handleGoogleSignIn = useCallback(async (): Promise<FirebaseUser | null> => {
    let userResult: FirebaseUser | null = null
    if (firebaseAppAuth == null || googleAuthProvider == null) {
      showErrorNotification(
        t('error_title'),
        `${t('error_general_message')} - FirebaseAuth initialization failed!`
      )
      return Promise.resolve(userResult)
    }

    toggleLoadingOverlay(true)
    if (!isMobile()) {
      try {
        const result = await signInWithPopup(firebaseAppAuth, googleAuthProvider)
        //const credential = GoogleAuthProvider.credentialFromResult(result)
        userResult = result.user
        let userProfileTmp: User = prepareUserProfile(userResult)

        const existingUsers: User[] = await getUserByEmail(userResult.email ?? '')
        if (existingUsers.length) {
          userProfileTmp = existingUsers[0]

          await updateUser(userProfileTmp.id!, {
            lastLoginDate: new Date(),
          })

          if (!userProfileTmp.isActive) {
            // show activation modal
            userResult = null
            openActivationOrDeletionModal(userProfileTmp, ACCOUNT_MODAL_TYPE.DEACTIVATED)
          } else if (userProfileTmp.isDeleted) {
            // show deleted account modal and dont allow login
            userResult = null
            openActivationOrDeletionModal(null, ACCOUNT_MODAL_TYPE.DELETED)
          } else {
            setUser(userProfileTmp)
            localStorage.setItem(LOCAL_STORAGE_CONSTANTS.authToken, JSON.stringify(userProfileTmp))
          }
        } else {
          // new user creation part
          const createdUserRes = await createUserAction(userResult)
          if (createdUserRes != null) {
            userProfileTmp = createdUserRes
            setUser(userProfileTmp)
            localStorage.setItem(LOCAL_STORAGE_CONSTANTS.authToken, JSON.stringify(userProfileTmp))
          }
        }
      } catch (error: any) {
        showErrorNotification(
          t('error_title'),
          `${t('error_general_message')} - ${error.code}: ${error.message}!`
        )
        userResult = null
      } finally {
        toggleLoadingOverlay(false)
      }

      return Promise.resolve(userResult)
    } else {
      try {
        await signInWithRedirect(firebaseAppAuth, googleAuthProvider)
      } catch (error: any) {
        console.error(error.message)
        showErrorNotification(
          t('error_title'),
          `${t('error_general_message')} - ${error.code}: ${error.message}!`
        )
      } finally {
        toggleLoadingOverlay(false)
      }

      return Promise.resolve(userResult)
    }
  }, [
    createUserAction,
    firebaseAppAuth,
    googleAuthProvider,
    openActivationOrDeletionModal,
    signInWithPopup,
    signInWithRedirect,
    t,
    toggleLoadingOverlay,
  ])

  //#region REGISTER WITH EMAIL AND PASSWORD
  /* const handleRegister = useCallback(
    async (email: string, password: string): Promise<FirebaseUser | null> => {
      let user: FirebaseUser | null = null

      if (firebaseAppAuth == null) {
        showErrorNotification(
          t('error_title'),
          `${t('error_general_message')} - FirebaseAuth initialization failed!`
        )
        return Promise.resolve(user)
      }
      setIsLoadingOverlayVisible(true)
      try {
        const createdUserCredentials = await createUserWithEmailAndPassword(
          firebaseAppAuth,
          email,
          password
        )
        user = createdUserCredentials.user
        console.log('Registered user -> ', user)
        const idTokenRes = await user.getIdToken()
        setToken(idTokenRes)
        // TODO: save data to db
        // TODO: save auth data somewhere so you dont lose it on page reload
      } catch (error: any) {
        showErrorNotification(
          t('error_title'),
          `${t('error_general_message')} - ${error.code}: ${error.message}!`
        )
        user = null
      } finally {
        setIsLoadingOverlayVisible(false)
      }
      return Promise.resolve(user)
    },
    [createUserWithEmailAndPassword, firebaseAppAuth, t]
  ) */

  /* const handleLogin = useCallback(
    async (email: string, password: string): Promise<FirebaseUser | null> => {
      let user: FirebaseUser | null = null

      if (firebaseAppAuth == null) {
        showErrorNotification(
          t('error_title'),
          `${t('error_general_message')} - FirebaseAuth initialization failed!`
        )
        return Promise.resolve(user)
      }

      setIsLoadingOverlayVisible(true)
      try {
        const createdUserCredentials = await signInWithEmailAndPassword(
          firebaseAppAuth,
          email,
          password
        )
        user = createdUserCredentials.user
        console.log('Logged in user -> ', user)
        const idTokenRes = await user.getIdToken()
        setToken(idTokenRes)
        // TODO: save auth data somewhere so you dont lose it on page reload
      } catch (error: any) {
        showErrorNotification(
          t('error_title'),
          `${t('error_general_message')} - ${error.code}: ${error.message}`
        )
        user = null
      } finally {
        setIsLoadingOverlayVisible(false)
      }

      return Promise.resolve(user)
    },
    [signInWithEmailAndPassword, firebaseAppAuth, t]
  ) */
  //#endregion

  const handleLogout = useCallback(async (): Promise<void> => {
    if (firebaseAppAuth == null) {
      showErrorNotification(
        t('error_title'),
        `${t('error_general_message')} - FirebaseAuth initialization failed!`
      )
      return
    }
    toggleLoadingOverlay(true)
    try {
      await signOut(firebaseAppAuth)
      location.state = null
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.authToken)
      setUser(null)
      navigate('/home')
      showSuccessfulNotification(t('information'), t('successfully_loggedout'))
    } catch (error: any) {
      showErrorNotification(
        t('error_title'),
        `${t('error_general_message')} - ${error.code}: ${error.message}`
      )
    } finally {
      toggleLoadingOverlay(false)
    }
  }, [firebaseAppAuth, toggleLoadingOverlay, t, signOut, location, navigate])

  const authValue: AuthContextProps = useMemo(
    () => ({
      onGoogleLogin: handleGoogleSignIn,
      //onLogin: handleLogin,
      onLogout: handleLogout,
      //onRegister: handleRegister,
      userProfile: user,
    }),
    [handleGoogleSignIn, handleLogout, user]
  )

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext }
