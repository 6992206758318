import React, { useEffect, useState } from 'react'
import { createStyles, Table } from '@mantine/core'
import { QuizResult } from '../../../utils/types'

type QuizResultsTableProps = {
  quizResult: QuizResult
}

const useStyles = createStyles((theme, _params) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },

    table: {
      width: '100%',
    },
  }
})

export const QuizResultsTable = ({ quizResult }: QuizResultsTableProps) => {
  const { classes } = useStyles()
  const [numRounds, setNumRounds] = useState(0)
  useEffect(() => {
    setNumRounds(quizResult.quiz.rounds)
  }, [quizResult])

  const renderTableBody = () => {
    return quizResult.teamResults.map((teamResult) => {
      return (
        <tr key={teamResult.teamId}>
          <td>{teamResult.position}</td>
          <td>{teamResult.name}</td>
          {Array.from({ length: numRounds }, (_, i) => i + 1).map((roundNumber) => {
            const round = teamResult.rounds.find((r) => r.roundNumber === roundNumber)
            const points = round ? round.points : '-'

            return <td key={`round-${roundNumber}`}>{points || '-'}</td>
          })}
          <td>{teamResult.totalPoints}</td>
        </tr>
      )
    })
  }

  return (
    <Table verticalSpacing={1} horizontalSpacing={100} fontSize={'xs'} withColumnBorders>
      <thead>
        <tr>
          <th>Position</th>
          <th>Team Name</th>
          {Array.from({ length: numRounds }, (_, i) => i + 1).map((roundNumber) => (
            <th key={`round-${roundNumber}`}>Round {roundNumber}</th>
          ))}
          <th>Total Points</th>
        </tr>
      </thead>
      <tbody>{renderTableBody()}</tbody>
    </Table>
  )
}
