import { QuestionPageQuizPlayer } from '../../utils/types'

export enum QuizPlayerPageType {
  startingPage = 'startingPage',
  signUpQR = 'signUpQR',
  question = 'question',
  round = 'round',
  roundRanking = 'roundRanking',
  pause = 'pause',
  rateQuizQR = 'rateQuizQR',
}

export type QuizPageTypeData =
  | QuizPlayerRoundPage
  | SignUpQR
  | RateQuizQR
  | QuizPlayerQuestionPage
  | RoundScoreboardPage
  | PauseScreen
  | StartingPage

export type StartingPage = {
  idx: number
  pageType: QuizPlayerPageType
}
export type QuizPlayerRoundPage = {
  idx: number
  roundNumber: number
  pageType: QuizPlayerPageType
}

export type PauseScreen = {
  idx: number
  pageType: QuizPlayerPageType.pause
}

export type RoundScoreboardPage = {
  idx: number
  pageType: QuizPlayerPageType.roundRanking
}

export type SignUpQR = {
  idx: number
  quizId: number
  pageType: QuizPlayerPageType
}

export type RateQuizQR = {
  idx: number
  quizId: number
  pageType: QuizPlayerPageType
}

export type QuizPlayerQuestionPage = {
  idx: number
  pageType: QuizPlayerPageType
  question: QuestionPageQuizPlayer
}
