import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import {
  ActionIcon,
  Button,
  Card,
  Center,
  createStyles,
  Divider,
  Grid,
  Image,
  Paper,
  Text,
  Checkbox,
  Stack,
  NumberInput,
} from '@mantine/core'
import { Answer, Question, QuestionsAndAnswers } from '../../../utils/types'
import { createQuestion, hostname, updateAnswer, updateQuestion } from '../../../utils/axios'
import { SimpleAnswerCreator } from '../../PossibleAnswerCreator/Creator'
import { useTranslation } from 'react-i18next'
import {
  canShowImage,
  showErrorNotification,
  showSuccessfulNotification,
} from '../../../utils/commonFunctions'
import { useAuth } from '../../../contexts/AuthProvider'
import '../style.scss'
import {
  handleDragLeave,
  handleDragOver,
  handleDragStart,
  handleDrop,
} from '../../../utils/dragEventHandlers'
import { SimpleAnswerEditor } from '../../PossibleAnswerCreator/Editor'
import { useNavigate } from 'react-router-dom'
import { useGeneralContext } from '../../../contexts/GeneralProvider'
import { RichTextEditor } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Superscript from '@tiptap/extension-superscript'
import SubScript from '@tiptap/extension-subscript'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { ImageRetry } from '../../ImageRetry'

type MultiplePickAnswerProps = {
  initialData?: QuestionsAndAnswers
  questionTypeId: number
  quizId: number
  questionRound: number
  questionPosition: number
  timeDuration: number
  refreshInitialData: () => void
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')
  return {
    control: {
      float: 'right',
      [BREAKPOINT]: {
        flex: 1,
      },
    },
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  }
})

export const MultiplePickAnswer: FC<MultiplePickAnswerProps> = (props) => {
  const { toggleLoadingOverlay } = useGeneralContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userProfile } = useAuth()
  const { classes } = useStyles()
  const [isSaving, setIsSaving] = useState(false)
  const [questionText, setQuestionText] = useState('')
  const [questionImageUploaded, setQuestionImageUploaded] = useState<File>()
  const [points, setPoints] = useState<number>(1)
  const [answersWrapper, setAnswersWrapper] = useState<Answer[]>([])
  const [isPossibleAnswerForVisible, setIsPossibleAnswerForVisible] = useState(false)
  const [isUploadProfileImageOpen, setIsUploadProfileImageOpen] = useState(false)
  const hiddenInputRef = useRef<HTMLInputElement | null>(null)
  const [answerSelected, setAnswerSelected] = useState<Answer | null>(null)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Superscript,
      SubScript,
      Underline,
      Placeholder.configure({ placeholder: t('questionHeader') ?? '' }),
    ],
    content: questionText,
    onUpdate({ editor }) {
      setQuestionText(editor.getHTML())
    },
  })

  useEffect(() => {
    if (props.initialData) {
      setQuestionText(props.initialData?.question.questionText)
      setAnswersWrapper(props.initialData.answers)
      setPoints(props.initialData?.question.points)
      editor?.commands.setContent(props.initialData?.question.questionText)
    }
  }, [editor, props.initialData])

  const handleUploadButtonClick = () => {
    // 👇️ open file input box on click of other element
    hiddenInputRef.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    //orgTmp.image = window.URL.createObjectURL(fileObj)
    setQuestionImageUploaded(fileObj)
  }

  const addPossibleAnswer = (data: Answer) => {
    if (data.isCorrect) {
      setAnswersWrapper([...answersWrapper, data])
    } else {
      setAnswersWrapper((prevState) => [...prevState, data])
    }
    setIsPossibleAnswerForVisible(false)
  }

  const removePossibleAnswer = (idx: number) => {
    setAnswersWrapper((prevState) => [...prevState.slice(0, idx), ...prevState.slice(idx + 1)])
  }

  const onCorrectAnswerChange = (value: string, isChecked: boolean) => {
    const answerWrapperTmp: Answer[] = answersWrapper.map((item, index) =>
      index === parseInt(value)
        ? {
            ...item,
            isCorrect: isChecked,
          }
        : { ...item }
    )
    setAnswersWrapper(answerWrapperTmp)
  }

  const onSaveAction = (goBack: boolean = false) => {
    const isUpdate = props.initialData?.question?.id
    const question: Question = {
      image: questionImageUploaded || props.initialData?.question?.image || null,
      quizId: props.quizId,
      points: points,
      questionTypeId: props.questionTypeId,
      questionText: questionText,
      round: props.questionRound,
      owner: isUpdate ? props.initialData?.question?.owner! : userProfile?.id!,
      position: props.questionPosition,
      timeDuration: props.timeDuration,
    }

    const newAW = answersWrapper.map((answerItem) => {
      answerItem.image = null
      answerItem.questionId = props.initialData?.question?.id
      return answerItem
    })

    toggleLoadingOverlay(true)
    setIsSaving(true)
    if (isUpdate) {
      updateQuestion(props.initialData?.question?.id!, question, newAW, null)
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)
          setIsSaving(false)
        })
    } else {
      createQuestion(question, answersWrapper, null)
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)
          setIsSaving(false)
        })
    }
  }

  const getImgSrc = () => {
    let finalImgSrc = ''

    if (questionImageUploaded != null) {
      finalImgSrc = window.URL.createObjectURL(questionImageUploaded)
    } else {
      finalImgSrc = hostname + '/' + (props.initialData?.question.image ?? '')
    }

    return finalImgSrc
  }

  return (
    <React.Fragment>
      <Grid.Col xs={1.5}>
        <NumberInput
          decimalSeparator=','
          placeholder={t('pointsHeader') ?? ''}
          label={t('pointsHeader')}
          value={points ?? 1}
          precision={1}
          step={0.5}
          onChange={(nmb) => {
            setPoints(nmb ?? 1)
          }}
        />
      </Grid.Col>
      <Grid.Col xs={3}></Grid.Col>
      <Grid.Col xs={1}>
        <Text size='sm' weight={500}>
          {t('questionHeader')}
        </Text>
      </Grid.Col>
      <Grid.Col xs={11}>
        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Code />
              <RichTextEditor.Subscript />
              <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
          <RichTextEditor.Content />
        </RichTextEditor>
      </Grid.Col>
      <Grid.Col xs={1}>
        <Text size='sm' weight={500}>
          {t('image')}
        </Text>
      </Grid.Col>
      <Grid.Col xs={11}>
        <Card
          withBorder
          radius='md'
          p='md'
          className={`${classes.card}`}
          onMouseEnter={() => setIsUploadProfileImageOpen(true)}
          onMouseLeave={() => setIsUploadProfileImageOpen(false)}
          sx={{ minHeight: 100 }}
        >
          <div className={`${isUploadProfileImageOpen ? 'light-blur' : ''}`}>
            {canShowImage(questionImageUploaded, props.initialData?.question.image) && (
              <ImageRetry src={getImgSrc()} alt={''} width='100%' />
            )}
          </div>
          {isUploadProfileImageOpen && (
            <div className='view-upload-image-form'>
              <ActionIcon
                size={'xl'}
                title={t('uploadImage')}
                onClick={(e: any) => {
                  e.stopPropagation()
                  handleUploadButtonClick()
                }}
              >
                <span className='material-symbols-outlined'>upload</span>
              </ActionIcon>
              {/* 👇 Notice the `display: hidden` on the input. Used to invoke upload file form.*/}
              <input
                type='file'
                accept={'image/png, image/jpeg, image/jpg'}
                ref={hiddenInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </div>
          )}
        </Card>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Divider my='sm' label={t('possible_answers_header')} labelPosition='center' size='lg' />
      </Grid.Col>
      <Grid.Col xs={12}>
        <Paper radius='lg' p='md' withBorder>
          {answersWrapper.length > 0 && (
            <Stack spacing='md' mb={20}>
              {answersWrapper.map((answer, index) => (
                <React.Fragment key={`rf-${index}`}>
                  <div
                    className='possible-answer-template'
                    draggable={true}
                    onDragStart={(event) =>
                      handleDragStart(event, index, '.possible-answer-template')
                    }
                    onDragOver={(event: any) => handleDragOver(event, '.possible-answer-template')}
                    onDragLeave={(event: any) =>
                      handleDragLeave(event, '.possible-answer-template')
                    }
                    onDrop={(event: any) =>
                      handleDrop(
                        event,
                        index,
                        '.possible-answer-template',
                        answersWrapper,
                        setAnswersWrapper
                      )
                    }
                  >
                    <div className='d-flex-align-center'>
                      <ActionIcon
                        size={'lg'}
                        title={t('dragTitle')}
                        onClick={(e: any) => {
                          e.stopPropagation()
                        }}
                      >
                        <span className='material-symbols-outlined'>drag_handle</span>
                      </ActionIcon>
                      <Checkbox
                        key={index}
                        value={index.toString()}
                        label={`${answer.text}${
                          (answer.points ?? 0) > 0 ? ' (' + answer.points + ')' : ''
                        }`}
                        size='md'
                        mr={15}
                        checked={answer.isCorrect}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const value = event.target.value
                          onCorrectAnswerChange(value, event.currentTarget.checked)
                        }}
                      />
                    </div>
                    <div className='d-flex'>
                      <ActionIcon
                        size={'lg'}
                        title={t('editTitle')}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setAnswerSelected(answer)
                          setIsPossibleAnswerForVisible(true)
                        }}
                      >
                        <span className='material-symbols-outlined'>edit</span>
                      </ActionIcon>
                      <ActionIcon
                        size={'lg'}
                        color={'red'}
                        title={t('remove_possible_answer')}
                        onClick={() => removePossibleAnswer(index)}
                      >
                        <span className='material-symbols-outlined'>delete</span>
                      </ActionIcon>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </Stack>
          )}
          <Center>
            <Button
              variant='outline'
              compact
              title={t('add_possible_answer')}
              onClick={() => setIsPossibleAnswerForVisible(true)}
            >
              + {t('add_possible_answer')}
            </Button>
          </Center>
        </Paper>
      </Grid.Col>
      <Grid.Col
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button className={classes.control} onClick={() => onSaveAction()} loading={isSaving}>
          {t('save_button')}
        </Button>
        <Button
          ml={10}
          className={classes.control}
          onClick={() => onSaveAction(true)}
          loading={isSaving}
        >
          {t('save_and_back_button')}
        </Button>
      </Grid.Col>
      <SimpleAnswerCreator
        isOpen={isPossibleAnswerForVisible}
        closeModalAction={() => setIsPossibleAnswerForVisible(false)}
        saveButtonAction={({ text, isCorrect, points }: Answer) => {
          addPossibleAnswer({ text, isCorrect, points })
        }}
      />
      {answerSelected && (
        <SimpleAnswerEditor
          isOpen={isPossibleAnswerForVisible}
          closeModalAction={() => setIsPossibleAnswerForVisible(false)}
          saveButtonAction={(dirtyPayload: any) => {
            updateAnswer(dirtyPayload)
              .then((res) => {
                setAnswerSelected(null)
                setIsPossibleAnswerForVisible(false)
                props.refreshInitialData()
                showSuccessfulNotification(t('information'), t('successfully_saved'))
              })
              .catch((exc) => {
                showErrorNotification(
                  t('error_title'),
                  `${t('error_general_message')} ${exc.message}`
                )
              })
          }}
          answerToEdit={answerSelected}
        />
      )}
    </React.Fragment>
  )
}
