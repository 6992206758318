import { Image, MantineNumberSize } from '@mantine/core'
import React, { useState, useEffect, useRef, useCallback } from 'react'

type ImageRetryProps = {
  numRetries?: number
  src: string
  alt?: string
  className?: string
  onError?: (error: any) => void
  onClick?: () => void
  height?: string | number
  width?: string | number
  radius?: MantineNumberSize
}

export const ImageRetry: React.FC<ImageRetryProps> = ({
  numRetries = 2,
  src,
  alt,
  className,
  onError,
  onClick,
  ...props
}) => {
  const [retries, setRetries] = useState<number>(numRetries)
  const [hasError, setHasError] = useState<boolean>(false)
  const [retrying, setRetrying] = useState<boolean>(false)
  const imgRef = useRef<HTMLImageElement>(null)
  const timeoutRef = useRef<any>(0)

  useEffect(() => {
    if (!retrying) {
      if (hasError && retries > 0) {
        setRetrying(true)
        setHasError(false)
        setRetries(retries - 1)
        console.warn(`Retrying '${src}' in ${Math.pow(2, numRetries - retries)} seconds`)
        timeoutRef.current = setTimeout(() => {
          if (imgRef.current != null) {
            imgRef.current.src = src
            setRetrying(false)
          }
        }, Math.pow(2, numRetries - retries) * 1000)
      }
    }
  }, [hasError, numRetries, retries, retrying, src])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const internalOnError = useCallback(
    (error: any) => {
      setHasError(true)
      onError?.(error)
      if (retries === 0) {
        console.error(`Giving up on '${src}'`)
      }
    },
    [onError, retries, src]
  )

  return (
    <Image
      onClick={onClick}
      ref={imgRef}
      alt={alt}
      height={'100%'}
      fit='cover'
      src={src}
      onError={internalOnError}
      {...props}
    />
  )
}
