import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Center, Grid, Title } from '@mantine/core'
import { QUESTION_TYPES, Quiz, QuizAndRounds } from '../../utils/types'
import { fetchQuiz } from '../../utils/commonFunctions'
import {
  QuizPageTypeData,
  QuizPlayerPageType,
  QuizPlayerQuestionPage,
  QuizPlayerRoundPage,
  SignUpQR,
} from './types'
import { MultiplePickAnswerQuizPlayer } from './QuestionPhaseLayouts/MultiplePickAnswer'
import { CorrectValueEstimationQuizPlayer } from './QuestionPhaseLayouts/CorrectValueEstimation'
import { SequenceQuizPlayer } from './QuestionPhaseLayouts/Sequence'
import { SinglePickAnswerQuizPlayer } from './QuestionPhaseLayouts/SinglePickAnswer'
import { RiskQuizPlayer } from './QuestionPhaseLayouts/Risk'
import { ImageQuestionQuizPlayer } from './QuestionPhaseLayouts/ImageQuestion'
import { SubquestionBlankSpacesQuizPlayer } from './QuestionPhaseLayouts/SubquestionBlankSpaces'
import { WriteAnswerQuizPlayer } from './QuestionPhaseLayouts/WriteAnswer'
import QRCode from 'react-qr-code'
import { QuizPlayerButtons } from './utils/QuizPlayerButtons'
import { TeamsRanking } from './TeamsRankingPreview'
import { PreQuizSignedTeamsPreview } from './PreQuizSignedTeamsPreview'
import { useGeneralContext } from '../../contexts/GeneralProvider'
import { t } from 'i18next'

type QuizPlayerProps = {
  handleFullscreenToggle: () => void
  isFullScreen: boolean
}
export const QuizPlayer = ({ handleFullscreenToggle, isFullScreen }: QuizPlayerProps) => {
  const { questionTypes } = useGeneralContext()
  const { quizId } = useParams()
  const [quizPages, setQuizPages] = useState<QuizPageTypeData[]>()
  const [quiz, setQuiz] = useState<Quiz | null>(null)
  const [selectedPage, setSelectedPage] = useState(0)

  const renderPage = (page: QuizPageTypeData) => {
    if (quiz === null) return
    if (page.pageType === QuizPlayerPageType.signUpQR && quiz) {
      // return <PreQuizSignedTeamsPreview quiz={quiz} />
    }

    if (page.pageType === QuizPlayerPageType.round) {
      const roundPage = page as QuizPlayerRoundPage
      return (
        <Grid m={0} grow gutter='xl'>
          <Grid.Col>
            <Center>
              <Title mt={50} order={1} style={{ fontSize: 50 }}>
                {t('round_header')} {roundPage.roundNumber}
              </Title>
            </Center>
          </Grid.Col>
        </Grid>
      )
    }

    if (page.pageType === QuizPlayerPageType.question) {
      const questionPage = page as QuizPlayerQuestionPage
      const question = questionPage.question
      let questionType = null

      if (question.questionTypeId != null) {
        questionType = questionTypes?.find((item) => item.id === question.questionTypeId) ?? null
        switch (questionType?.value) {
          case QUESTION_TYPES.SINGLE_PICK_ANSWER:
            return <SinglePickAnswerQuizPlayer questionPageQuizPlayer={questionPage.question} />
          case QUESTION_TYPES.MULTIPLE_PICK_ANSWER:
            return <MultiplePickAnswerQuizPlayer questionPageQuizPlayer={questionPage.question} />
          case QUESTION_TYPES.WRITE_ANSWER:
            return <WriteAnswerQuizPlayer questionPageQuizPlayer={questionPage.question} />
          case QUESTION_TYPES.SUBQUESTION_BLANK_SPACES:
            return (
              <SubquestionBlankSpacesQuizPlayer questionPageQuizPlayer={questionPage.question} />
            )
          case QUESTION_TYPES.CORRECT_VALUE_ESTIMATION:
            return (
              <CorrectValueEstimationQuizPlayer questionPageQuizPlayer={questionPage.question} />
            )
          case QUESTION_TYPES.RISK:
            return <RiskQuizPlayer questionPageQuizPlayer={questionPage.question} />
          case QUESTION_TYPES.IMAGE_QUESTION:
            return <ImageQuestionQuizPlayer questionPageQuizPlayer={questionPage.question} />
          case QUESTION_TYPES.SEQUENCE:
            return <SequenceQuizPlayer questionPageQuizPlayer={questionPage.question} />
          default:
            return <div>Cannot read given question type</div>
        }
      }
    }
    if (page.pageType === QuizPlayerPageType.pause) {
      return (
        <Grid m={0} grow gutter='xl'>
          <Grid.Col>
            <Center>
              <Title mt={50} order={1} style={{ fontSize: 50 }}>
                {t('pause_header')}
                <br />
                <Button
                  variant={'outline'}
                  onClick={() =>
                    window.open(
                      `https://quiz-to-go.com/quiz-player/${quizId!}/insert-team-results`,
                      '_blank'
                    )
                  }
                >
                  Insert results
                </Button>
              </Title>
            </Center>
          </Grid.Col>
        </Grid>
      )
    }

    if (page.pageType === QuizPlayerPageType.roundRanking) {
      return (
        <Grid m={0} grow gutter='xl'>
          <Grid.Col>
            <Center>
              <TeamsRanking isInserting={false} />
            </Center>
          </Grid.Col>
        </Grid>
      )
    }

    if (page.pageType === QuizPlayerPageType.rateQuizQR) {
      return (
        <Grid m={0} grow gutter='xl'>
          <Grid.Col>
            <Center>
              <Title mt={50} order={1} style={{ fontSize: 50 }}>
                <div style={{ height: 'auto', margin: '0 auto', maxWidth: 300, width: '100%' }}>
                  Rate me
                  {quiz?.id && (
                    <QRCode
                      size={256}
                      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      value={`https://quiz-to-go.com/quiz-player/${quiz?.id.toString()}/rate-quiz`}
                      viewBox={`0 0 256 256`}
                    />
                  )}
                </div>
              </Title>
            </Center>
          </Grid.Col>
        </Grid>
      )
    }
  }

  const generateQuizPages = (result: QuizAndRounds) => {
    let counter = 1
    const flattenedArray: QuizPageTypeData[] = result?.rounds.flatMap((obj) => [
      { idx: counter++, roundNumber: obj.number, pageType: QuizPlayerPageType.round },
      ...obj.questions.map((question) => ({
        idx: counter++,
        pageType: QuizPlayerPageType.question,
        question,
      })),
      { idx: counter++, pageType: QuizPlayerPageType.pause },
      { idx: counter++, pageType: QuizPlayerPageType.roundRanking },
    ])

    const signUpQR: SignUpQR = {
      idx: 0,
      quizId: result.quiz.id!!,
      pageType: QuizPlayerPageType.signUpQR,
    }

    const rateQuizQR = {
      idx: flattenedArray.length + 1,
      quizId: result.quiz.id!!,
      pageType: QuizPlayerPageType.rateQuizQR,
    }

    flattenedArray.unshift(signUpQR)
    flattenedArray.push(rateQuizQR)
    setQuizPages(flattenedArray)
  }

  const startFetchQuiz = useCallback(
    async (ignoreQuizFetch?: boolean) => {
      const result = await fetchQuiz(parseInt(quizId ?? '0'))
      if (!ignoreQuizFetch) {
        generateQuizPages(result)
        setQuiz(result.quiz)
      }
    },
    [quizId]
  )

  useEffect(() => {
    let ignoreQuizFetch = false

    if (quizId == null) return

    startFetchQuiz(ignoreQuizFetch)

    return () => {
      ignoreQuizFetch = true
    }
  }, [quizId, startFetchQuiz])

  return (
    <div style={{ position: 'relative', height: 'calc(100% - 10%)' }}>
      {quizPages?.map((page: QuizPageTypeData, idx: number, array: QuizPageTypeData[]) =>
        page.idx === selectedPage ? (
          <div key={idx} style={{ height: '100%', marginTop: isFullScreen ? '10vh' : '0' }}>
            {renderPage(page)}
            <QuizPlayerButtons
              setSelectedPage={setSelectedPage}
              selectedPage={selectedPage}
              idx={idx}
              isFirst={idx === 0}
              isLast={idx === array.length - 1}
              handleFullscreenToggle={handleFullscreenToggle}
              isFullScreen={isFullScreen}
            />
          </div>
        ) : null
      )}
    </div>
  )
}
