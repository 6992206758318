import { useEffect } from 'react'
import {
  Modal,
  createStyles,
  SimpleGrid,
  TextInput,
  Textarea,
  Group,
  Button,
  Switch,
  Select,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useForm } from '@mantine/form'
import { DatePicker } from '@mantine/dates'
import { Organization, Quiz } from '../../utils/types'
import { useAuth } from '../../contexts/AuthProvider'
import { useGeneralContext } from '../../contexts/GeneralProvider'

export type QuizViewEditProps = {
  isOpen: boolean
  closeModalAction: () => void
  selectedQuiz: Quiz | null
  createQuizAction: (dataToCreate: Quiz) => Promise<any>
  updateQuizAction: (dataToCreate: Quiz) => Promise<any>
  organizations: Organization[]
}

const initialVals: Quiz = {
  name: '',
  owner: 0,
  organizationId: 0,
  createdAt: new Date(),
  startDate: new Date(),
  rounds: 1,
  isActive: true,
  isDeleted: false,
  description: null,
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  }
})

export const QuizViewEdit = (props: QuizViewEditProps) => {
  const { userProfile } = useAuth()
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { toggleLoadingOverlay } = useGeneralContext()

  const form = useForm<Quiz>({
    initialValues: props.selectedQuiz ?? initialVals,

    validate: (values) => ({
      rounds: values.rounds < 1 ? t('invalidRoundsNumber') : null,
      /* name: values.name.length < 2 ? 'Too short name' : null,
      name: (value) => value.trim().length >= 2,
      age:
        values.age === undefined
          ? 'Age is required'
          : values.age < 18
          ? 'You must be at least 18'
          : null, */
    }),
    //initialErrors: { name: 'Name must include at least 2 letters' },
  })

  useEffect(() => {
    if (props.selectedQuiz != null) {
      form.setValues(props.selectedQuiz)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedQuiz])

  const handleSubmit = async (values: Quiz) => {
    console.log('ON FORM SUBMIT ', values)
    const isUpdate = props.selectedQuiz?.id

    toggleLoadingOverlay(true)
    if (isUpdate) {
      await props.updateQuizAction(values)
    } else {
      values.owner = userProfile?.id!
      await props.createQuizAction(values)
    }
    form.reset()
    toggleLoadingOverlay(false)
  }

  return (
    <>
      <Modal
        centered
        opened={props.isOpen}
        onClose={() => {
          props.closeModalAction()
        }}
        withCloseButton={false}
      >
        <form
          className={classes.form}
          onSubmit={form.onSubmit((values: Quiz) => {
            handleSubmit(values)
          })}
        >
          <SimpleGrid
            cols={1}
            /* breakpoints={[
              { maxWidth: 'lg', cols: 2, spacing: 'sm' },
              { maxWidth: 'xs', cols: 1, spacing: 'sm' },
            ]} */
          >
            <TextInput
              required
              label={t('title')}
              placeholder={t('title') ?? ''}
              /* defaultValue={selectedQuiz != null ? selectedQuiz.name : form.values.name}
                  onChange={(event) => form.setFieldValue('name', event.currentTarget.value)} */
              {...form.getInputProps('name')}
            />
            <Select
              required
              label={t('organizations')}
              placeholder={t('select_one') ?? ''}
              value={form.values.organizationId.toString()}
              data={props.organizations.map((org) => ({
                label: org.name,
                value: org.id!.toString(),
              }))}
              onChange={(newValue: string | null) => {
                console.log(newValue)
                form.setFieldValue('organizationId', parseInt(newValue!))
              }}
              searchable={true}
              clearable={false}
            />
            <TextInput
              required
              label={t('rounds')}
              placeholder={t('rounds') ?? ''}
              type={'number'}
              {...form.getInputProps('rounds')}
            />
            <Textarea
              label={t('description')}
              placeholder={t('description') ?? ''}
              radius='md'
              autosize
              minRows={3}
              maxRows={5}
              defaultValue={form.values.description ?? ''}
              onChange={(event) => form.setFieldValue('description', event.currentTarget.value)}
            />
            <DatePicker
              placeholder={t('pickDate') ?? ''}
              label={t('startDateQuiz')}
              {...form.getInputProps('startDate')}
            />
            <Switch
              size='md'
              label={t('active_switch')}
              defaultChecked={form.values.isActive}
              {...form.getInputProps('isActive')}
            />
          </SimpleGrid>
          <Group position='right' mt='md'>
            <Button type='submit' className={classes.control} onClick={() => {}}>
              {t('save_button')}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  )
}
