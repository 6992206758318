import React, { useEffect, useState } from 'react'
import { Grid } from '@mantine/core'
import { useParams } from 'react-router-dom'
import './style.css'
import { QuizContentSection } from './QuizContentSidebar'
import { PageEditBar } from './PageEditBar'
import { getQuizContent } from '../../../utils/axios'
import { PagePreview } from './PagePreview/PagePreview'
import { Quiz } from '../../../utils/types'
import { useGeneralContext } from '../../../contexts/GeneralProvider'

export type PageType = 'signUp' | 'round' | 'question' | 'page' | 'roundResult' | 'rateQuiz'

interface QuestionPageQuizPlayer {
  id: number
  quizId: number
  text?: string
  subText?: string | null
  image?: string | null
  position: number
  round?: number | null
  layout?: number
  isActive?: number
  isDeleted?: number
  createdAt?: string
  points?: number
  questionTypeId?: number
  questionText?: string
  owner?: number
  timeDuration?: number
  answers?: Answer[]
  subQuestions?: any[]
}

interface Answer {
  id: number
  questionId: number
  text: string
  image: string | null
  points: number
  isCorrect: number
  position: number
}

export const SingleQuiz: React.FC = () => {
  const { quizId } = useParams()
  const [currentPositionSelected, setSelectedPage] = useState<number>(0)
  const [pages, setPages] = useState<QuestionPageQuizPlayer[] | null>(null)
  const [isPageDirty, setPageDirty] = useState<boolean>(false)

  let allItems: QuestionPageQuizPlayer

  function addSpecialPages(payload: any) {
    const signUpPage = {
      id: 0,
      quizId: 58,
      text: 'Sign Up Page',
      subText: null,
      image: null,
      position: 0,
      round: null,
      layout: 1,
      isActive: 1,
      isDeleted: 0,
      createdAt: new Date().toISOString(),
      type: 'signUp',
    }

    const rateQuizPage = {
      id: 0,
      quizId: 58,
      text: 'Rate Quiz Page',
      subText: null,
      image: null,
      position: payload.length + 1,
      round: null,
      layout: 1,
      isActive: 1,
      isDeleted: 0,
      createdAt: new Date().toISOString(),
      type: 'rateQuiz',
    }

    payload.unshift(signUpPage)
    payload.push(rateQuizPage)

    return payload
  }

  function addRoundObjectsAfterLast(array: any) {
    const roundsMap = new Map()

    array.forEach((item: any, index: any) => {
      if (item.round !== null && !roundsMap.has(item.round)) {
        roundsMap.set(item.round, index)
      }
    })

    for (const [round] of roundsMap as any) {
      const lastPosition = array.reduceRight((acc: any, cur: any, index: any) => {
        if (cur.round === round && acc === -1) {
          return index
        }
        return acc
      }, -1)

      array.splice(lastPosition + 1, 0, {
        text: 'Round Result ' + round,
        type: 'roundResult',
        round: round,
        position: lastPosition + 1,
      })
    }

    return array
  }

  function addRoundObjects(array: any) {
    const roundsMap = new Map()

    array.forEach((item: any, index: any) => {
      if (item.round !== null && !roundsMap.has(item.round)) {
        roundsMap.set(item.round, index)
      }
    })

    let positionAdjustment = 0
    for (const [round, position] of roundsMap as any) {
      array.splice(position + positionAdjustment, 0, {
        text: 'Round ' + round,
        type: 'round',
        round: round,
        position: position + positionAdjustment - 1,
      })
      positionAdjustment++
    }

    return array
  }

  const parseToQuizPages = (res: any) => {

    const allQuestions = res.rounds.reduce(
      (accumulator: any[], currentRound: { questions: any }) => {
        accumulator.push(...currentRound.questions)
        return accumulator
      },
      []
    )

    const allPages = res.pages
    const combinedArray = [...allQuestions, ...allPages]

    combinedArray.sort((a, b) => a.position - b.position)
    allItems = res


    addRoundObjects(combinedArray)
    const arrayWithRoundObjectsAfterLast = addRoundObjectsAfterLast(combinedArray)

    function addTypeProperty(payload: any[]) {
      payload.forEach((item: { type: string }) => {
        if ('questionTypeId' in item) {
          item.type = 'question'
        } else if ('id' in item) {
          item.type = 'page'
        }
      })
      return arrayWithRoundObjectsAfterLast
    }

    const updatedPayload = addTypeProperty(combinedArray)
    let modifiedPayload1 = addSpecialPages(updatedPayload)

    modifiedPayload1 = modifiedPayload1.map((x: any, idx: any) => {
      x.position = idx

      return x
    })
    return modifiedPayload1
  }

  console.log("IS PAGE DIRTY",isPageDirty)
  useEffect(() => {
    console.log("I SHOULD BE HERE JUST ONE TIME")

    quizId
      ? getQuizContent(parseInt(quizId)).then((res) => {
          setPages(parseToQuizPages(res))
        })
      : console.log('Quiz cannot be fetched')
  }, [isPageDirty])

  return (
    <Grid style={{ margin: 0, height: 'calc(100% - 90px)' }}>
      <Grid.Col span={2} className={'custom-sidebar-left'}>
        <QuizContentSection
          key={pages?.length}
          quizId={quizId!!}
          setSelectedPage={setSelectedPage}
          selectedPage={currentPositionSelected}
          pages={pages}
          setPages={setPages}
          isPageDirty={isPageDirty}
          setPageDirty={setPageDirty}
        />
      </Grid.Col>
      <Grid.Col span={8} className={'quiz-paper-layout-wrapper'}>
        <div className={'quiz-paper-layout'}>
          {pages && (
            <PagePreview
              isPageDirty={isPageDirty}
              setPageDirty={setPageDirty}
              page={pages[currentPositionSelected]}
            />
          )}
        </div>
      </Grid.Col>
      <Grid.Col span={2} className={'custom-sidebar-left'}>
        <PageEditBar page={currentPositionSelected} />
      </Grid.Col>
    </Grid>
  )
}

export type QuizPage = {}
