import React, { useState } from 'react'
import { Box, Collapse, ThemeIcon, Text, UnstyledButton, createStyles } from '@mantine/core'
import { Icon as TablerIcon, ChevronLeft, ChevronRight } from 'tabler-icons-react'
import { SublinkProps } from '../../utils/types'
import { useTranslation } from 'react-i18next'

const useStyles = createStyles((theme, _params, getRef) => {
  const iconTmp: any = getRef('icon')
  const BREAKPOINT = theme.fn.smallerThan(1008)

  return {
    control: {
      fontWeight: 500,
      width: '100%',
      padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
      color: `${theme.colors.white}`,
      fontSize: theme.fontSizes.sm,

      [`:not(:last-child)`]: {
        marginRight: '5px',
      },

      [BREAKPOINT]: {
        marginTop: 5,
        marginBottom: 5,
        [`:not(:last-child)`]: {
          marginRight: '0px',
        },
      },

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        borderRadius: '10px',
      },
    },

    link: {
      fontWeight: 500,
      display: 'flex',
      textDecoration: 'none',
      padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
      paddingLeft: 40,
      marginLeft: 30,
      fontSize: theme.fontSizes.sm,
      color: '#FFF',
      borderLeft: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${iconTmp}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },

    chevron: {
      transition: 'transform 200ms ease',
    },

    mainLink: {
      ref: iconTmp,
      color: '#FFF',
      display: 'flex',
      alignItems: 'space-between',
    },

    mainLinkActive: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[0],
      borderRadius: '10px',
    },
  }
})

type LinksGroupProps = {
  icon: TablerIcon
  label: string
  keyValue: string
  initiallyOpened?: boolean
  sublinks?: SublinkProps[]
  onClick?: () => void
  linkSelected: string
  onSublinkClick: (path: string, keyValue: string) => void
}

export const LinksGroup = ({
  icon: Icon,
  label,
  initiallyOpened,
  keyValue,
  sublinks,
  onClick,
  linkSelected,
  onSublinkClick,
}: LinksGroupProps) => {
  const { t } = useTranslation()
  const { classes, theme, cx } = useStyles()
  const hasLinks = Array.isArray(sublinks)
  const [opened, setOpened] = useState(initiallyOpened || false)
  const ChevronIcon = theme.dir === 'ltr' ? ChevronRight : ChevronLeft

  const items = (hasLinks ? sublinks : []).map((sublink) => (
    <Text<'a'>
      component='a'
      className={`${cx(classes.link, {
        [classes.mainLinkActive]: keyValue.concat('/').concat(sublink.keyValue) === linkSelected,
      })}`}
      href={sublink.path}
      key={sublink.label}
      onClick={(event) => {
        event.preventDefault()
        onSublinkClick(sublink.path, keyValue.concat('/').concat(sublink.keyValue))
      }}
    >
      {t(sublink.keyValue)}
    </Text>
  ))

  return (
    <>
      <UnstyledButton
        onClick={() => {
          setOpened((o) => !o)
          if (onClick) onClick()
        }}
        className={`${cx(classes.mainLink, classes.control, {
          [classes.mainLinkActive]: keyValue === linkSelected,
        })}`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ThemeIcon radius={1000} size={30}>
            <Icon size={18} />
          </ThemeIcon>
          <Box ml='md'>{label}</Box>
        </Box>
        {hasLinks && (
          <ChevronIcon
            className={classes.chevron}
            size={14}
            style={{
              transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
            }}
          />
        )}
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  )
}
