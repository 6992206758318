import React, { useEffect, useState } from 'react'
import { Button, createStyles, Table } from '@mantine/core'
import { QuizResult } from '../../../utils/types'
import { createResultsForRound } from '../../../utils/axios'
import { t } from 'i18next'

type QuizResultInsertResultsTableProps = {
  quizResult: QuizResult
}

const useStyles = createStyles((theme, _params) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },
    table: {
      width: '100%',

      '& thead tr th.small-col': {
        width: '30px',
      },

      '& thead tr th': {
        textAlign: 'center',
      },
    },
  }
})

export type QuizResultInsertData = {
  teamId: number
  quizId: number
  roundNumber: number
  points: number
}
export const QuizResultInsertResultsTable = ({ quizResult }: QuizResultInsertResultsTableProps) => {
  const { classes } = useStyles()
  const [formValues, setFormValues] = useState<QuizResultInsertData[]>([])
  const [numRounds, setNumRounds] = useState(0)
  useEffect(() => {
    setNumRounds(quizResult.quiz.rounds)
  }, [quizResult])

  const handlePointsChange = (
    value: number | undefined,
    teamId: number,
    roundNumber: number,
    index: number
  ) => {
    if (value !== undefined) {
      const updatedValues = [...formValues]
      updatedValues[index] = {
        teamId: teamId,
        quizId: quizResult.quiz.id!!,
        roundNumber: roundNumber,
        points: value,
      }
      setFormValues(updatedValues)
    }
  }

  const renderTableBody = () => {
    return quizResult.teamResults.map((teamResult, index) => {
      const roundsWithResults = teamResult.rounds.filter((r) => r.points !== undefined)
      const maxRoundNumber =
        roundsWithResults.length > 0 ? Math.max(...roundsWithResults.map((r) => r.roundNumber)) : 0

      return (
        <tr key={teamResult.teamId}>
          <td>{teamResult.position}</td>
          <td>{teamResult.name}</td>
          {Array.from({ length: numRounds }, (_, i) => i + 1).map((roundNumber) => {
            const round = teamResult.rounds.find((r) => r.roundNumber === roundNumber)
            const isEditable = roundNumber === maxRoundNumber + 1
            const points = isEditable ? formValues[index]?.points || '' : round ? round.points : '-'

            return (
              <td key={`round-${roundNumber}`}>
                {isEditable ? (
                  <input
                    type='number'
                    value={points}
                    onChange={(e) =>
                      handlePointsChange(
                        parseInt(e.target.value),
                        teamResult.teamId,
                        roundNumber,
                        index
                      )
                    }
                  />
                ) : (
                  points
                )}
              </td>
            )
          })}
          <td>{teamResult.totalPoints}</td>
        </tr>
      )
    })
  }

  return (
    <>
      <Table
        verticalSpacing={1}
        horizontalSpacing={100}
        fontSize={'xs'}
        className={classes.table}
        withColumnBorders
      >
        <thead>
          <tr>
            <th>Position</th>
            <th>Team Name</th>
            {Array.from({ length: numRounds }, (_, i) => i + 1).map((roundNumber) => (
              <th key={`round-${roundNumber}`}>Round {roundNumber}</th>
            ))}
            <th>Total Points</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button onClick={() => createResultsForRound(formValues, quizResult.quiz.id!!)}>
          {t('save_button')}
        </Button>
      </div>
    </>
  )
}
