import { Paper, Group, Button } from '@mantine/core'
import { GoogleIcon } from '../SocialButtons/GoogleIcon'
import { CustomizedState, useAuth } from '../../contexts/AuthProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { User } from 'firebase/auth'

//type AuthFormValues = { email: string; nickname: string; password: string }

type AuthenticationFormProps = {
  close?: () => void
  isModal: boolean
}

export const AuthenticationForm = (props: AuthenticationFormProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { onGoogleLogin } = useAuth()
  //const [type, toggle] = useToggle(['login', 'register'])
  /* const form = useForm({
    initialValues: {
      email: '',
      nickname: '',
      password: '',
    } as AuthFormValues,

    validate: {
      email: (val) => (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val) ? null : 'Invalid email'),
      password: (val) => (val.length < 8 ? t('password_char_minimum') : null),
    },
  }) */

  /* const handleSubmit = async (values: AuthFormValues) => {
    let userResult: User | null =
      type === 'login'
        ? await onLogin(values.email, values.password)
        : await onRegister(values.email, values.password)

    if (userResult == null) {
      return
    }

    closeAndNavigate()
  } */

  const handleGoogleLogin = async () => {
    let userResult: User | null = await onGoogleLogin()

    if (props.close && props.isModal) {
      props.close()
    }

    if (userResult == null) {
      return
    }

    navigateAway()
  }

  const navigateAway = () => {
    const state = location.state as CustomizedState //github.com/reach/router/issues/414
    const origin = state?.from.pathname || '/home'

    navigate(origin)
  }

  return (
    <Paper radius='md' p='xl' withBorder>
      <Group grow mb='md'>
        <Button
          leftIcon={<GoogleIcon />}
          variant='default'
          color='gray'
          onClick={handleGoogleLogin}
          radius='xl'
        >
          Google
        </Button>
      </Group>
      {/* <Divider label={t('continue_with_email')} labelPosition='center' my='lg' />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stack>
          {type === 'register' && (
            <TextInput
              label={t('your_nickname')}
              placeholder={t('your_nickname') ?? ''}
              value={form.values.nickname}
              onChange={(event) => form.setFieldValue('nickname', event.currentTarget.value)}
            />
          )}
          <TextInput
            required
            label={t('email')}
            placeholder={t('email') ?? ''}
            value={form.values.email}
            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
            error={form.errors.email && t('invalid_email')}
          />
          <PasswordInput
            required
            label={t('password')}
            placeholder={t('password') ?? ''}
            value={form.values.password}
            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
            error={form.errors.password && t('password_char_minimum')}
          />
          {type === 'register' && (
            <Checkbox
              label='I accept terms and conditions'
              checked={form.values.terms}
              onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
            />
          )}
        </Stack>
        <Group position='apart' mt='xl'>
          <Anchor
            component='button'
            type='button'
            color='dimmed'
            onClick={() => toggle()}
            size='xs'
          >
            {type === 'register' ? t('auth_already_user') : t('auth_register_text')}
          </Anchor>
          <Button type='submit' disabled={!form.isValid()}>
            {t(type)}
          </Button>
        </Group>
      </form> */}
    </Paper>
  )
}
