import React from 'react'
import { QuestionPageQuizPlayer } from '../../../../utils/types'
import { Grid, Image } from '@mantine/core'
import { hostname } from '../../../../utils/axios'
import { ImageRetry } from '../../../../components/ImageRetry'

type SubquestionBlankSpacesProps = {
  questionPageQuizPlayer: QuestionPageQuizPlayer
}

export const SubquestionBlankSpacesQuizPlayer = ({
  questionPageQuizPlayer,
}: SubquestionBlankSpacesProps) => {
  return (
    <Grid m={0} grow gutter='xl' style={{ height: '100%' }}>
      <div style={{ display: 'grid', placeItems: 'center', height: '80%', width: '100%' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            gridGap: '20px',
            placeItems: 'center',
          }}
        >
          {questionPageQuizPlayer.subQuestions.map((x, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: index % 2 === 0 ? 'row-reverse' : 'row',
                alignItems: 'center',
              }}
            >
              <ImageRetry src={hostname + '/' + x.image} width={'300px'} height={'300px'} />
              <h2 style={{ marginLeft: '28px', marginRight: '28px' }}>{x.text}</h2>
            </div>
          ))}
        </div>
      </div>
    </Grid>
  )
}
