import React, { useCallback, useEffect, useState } from 'react'
import { Accordion } from '@mantine/core'

import { Button, Card, Center, Container, Grid } from '@mantine/core'
import { fetchOrganizationWithQuizzes } from '../../../utils/commonFunctions'
import { useNavigate, useParams } from 'react-router-dom'
import { OrganizationAndQuizzes, Quiz } from '../../../utils/types'
import { OrganizationCard } from '../../../components/OrganizationCards'
import moment from 'moment'
import i18n, { t } from 'i18next'
import 'moment/locale/hr' // Add Croatian locale to Moment.js

export const PublicOrganizationInfo = () => {
  const { organizationId } = useParams()
  const navigate = useNavigate()
  const [organizationAndQuizzes, setOrganizationAndQuizzes] =
    useState<OrganizationAndQuizzes | null>(null)
  const [previousQuizzes, setPreviousQuizzes] = useState<Quiz[]>([])
  const [nextQuizzes, setNextQuizzes] = useState<Quiz[]>([])

  const currentLanguage = i18n.language

  const startFetchOrganization = useCallback(
    async (ignoreOrganizationFetch?: boolean) => {
      const result = await fetchOrganizationWithQuizzes(parseInt(organizationId ?? '0'))
      if (!ignoreOrganizationFetch) {
        setOrganizationAndQuizzes(result)

        const prevToSave: Quiz[] = []
        const nextToSave: Quiz[] = []

        result.quiz.forEach((x) => {
          const hehe = new Date(x.startDate)
          if (hehe.getTime() < Date.now()) {
            prevToSave.push(x)
          } else {
            nextToSave.push(x)
          }
        })

        setPreviousQuizzes(prevToSave)
        setNextQuizzes(nextToSave)
      }
    },
    [organizationId]
  )

  function getFormattedDateByLocale(dateString: string): string {
    const inputFormat: string = 'ddd, DD MMM YYYY HH:mm:ss [GMT]'
    const outputFormatCroatian: string = 'dddd, DD.MM.YYYY. u HH:mm'
    const outputFormatEnglish: string = 'dddd, MMMM DD, YYYY [at] h:mm A'

    const date = moment.utc(dateString, inputFormat)
    date.local().locale(currentLanguage)

    const formattedDate = date.format(
      currentLanguage === 'hr' ? outputFormatCroatian : outputFormatEnglish
    )

    return formattedDate.replace(/\b\w/g, (l) => l.toUpperCase())
  }

  const quizDateInfo = (quizzes: Quiz[]) => {
    return quizzes.map((x, idx) => {
      const quizStartDate = new Date(x.startDate)
      return (
        <Card
          key={idx}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <>{getFormattedDateByLocale(quizStartDate.toUTCString())}</>
          {quizStartDate.getTime() < Date.now() ? (
            <Button>Show</Button>
          ) : (
            <Button onClick={() => navigate(`/quiz-player/${x.id}`)}>
              {t('start_quiz_signup')}
            </Button>
          )}
        </Card>
      )
    })
  }

  useEffect(() => {
    let ignoreOrganizationFetch = false

    startFetchOrganization(ignoreOrganizationFetch)

    return () => {
      ignoreOrganizationFetch = true
    }
  }, [organizationId, startFetchOrganization])

  return (
    <Container my='md' size={'xl'}>
      {organizationAndQuizzes && (
        <Grid mt={50}>
          <Grid.Col xs={6}>
            <Center>
              {organizationAndQuizzes?.organization.id && (
                <OrganizationCard
                  organization={organizationAndQuizzes?.organization}
                  isHomepage={false}
                />
              )}
            </Center>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Accordion defaultValue='customization'>
              <Accordion.Item value='customization'>
                <Accordion.Control>{t('next_quizzes')}</Accordion.Control>
                <Accordion.Panel>{quizDateInfo(nextQuizzes)}</Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value='flexibility'>
                <Accordion.Control>{t('previous_quizzes')}</Accordion.Control>
                <Accordion.Panel>{quizDateInfo(previousQuizzes)}</Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Grid.Col>
        </Grid>
      )}
    </Container>
  )
}
