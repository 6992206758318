import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  ActionIcon,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Textarea,
  Title,
  createStyles,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mantine/hooks'
import QRCode from 'react-qr-code'
import { Answer, Question, SubQuestion } from '../../../../utils/types'
import { EditCircle } from 'tabler-icons-react'
import { ArrowLeft } from 'tabler-icons-react'
import { hostname, updateQuestion } from '../../../../utils/axios'
import React from 'react'
import { ImageRetry } from '../../../../components/ImageRetry'
import { canShowImage } from '../../../../utils/commonFunctions'

type PagePreviewProps = {
  page: any
  isPageDirty: boolean
  setPageDirty: (isPageDirty: boolean) => void
}

export const SignUpPage = ({ page }: PagePreviewProps) => {
  const [teamNames, setTeamNames] = useState<{ name: string }[]>()
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery('(max-width: 599px)')

  return (
    <Grid m={0} grow gutter='xl'>
      <Grid.Col span={4}>
        <Center mb={16}>
          <Title order={1}>{t('sign_up_team')}</Title>
        </Center>
        <Center>
          <div style={{ margin: '0 auto', width: '100%' }}>
            <QRCode
              size={700}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`https://www.quiz-to-go.com/quiz-player/tu-stavit-quizID/sign-up-team`}
              viewBox={`0 0 256 256`}
            />
          </div>
        </Center>
      </Grid.Col>
      <Grid.Col span={isSmallScreen ? 12 : 8}>
        <Center mb={16}>
          <Title order={1}>{t('teams')}</Title>
        </Center>
        <Grid m={0} gutter='xl'>
          {teamNames?.map((team: { name: string }, idx) => (
            <Grid.Col key={idx} md={4} lg={3} span={4} style={{ height: 70 }}>
              <Title order={3}>{team.name}</Title>
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export const PagePage = ({ page }: PagePreviewProps) => {
  return (
    <div>
      <p>{page.text}</p>
    </div>
  )
}

export const RoundPage = ({ page }: PagePreviewProps) => {
  return (
    <div>
      <p>{page.question}</p>
    </div>
  )
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')
  return {
    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
    card: {
      height: '100%',
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  }
})

export const QuestionPage = ({ page, isPageDirty, setPageDirty }: PagePreviewProps) => {
  const [isEditingQuestionText, setIsEditingQuestionText] = useState<boolean>(false)
  const [isEditingAnswerSection, setIsEditingAnswerSection] = useState<boolean>(false)
  const [isUploadProfileImageOpen, setIsUploadProfileImageOpen] = useState(false)
  const { t } = useTranslation()
  const hiddenInputRef = useRef<HTMLInputElement | null>(null)
  const [questionImageUploaded, setQuestionImageUploaded] = useState<File | null>(null)

  const { classes } = useStyles()

  const [question, setQuestion] = useState<Question>({
    image: page?.image || null,
    points: page.points,
    questionText: page.questionText,
    questionTypeId: page.questionTypeId,
    quizId: page.quizId,
    round: page.round,
    owner: page.owner,
    position: page.position,
    timeDuration: page.timeDuration,
  })

  const [answers, setAnswers] = useState<Answer[]>(page.answers || [])
  const [subQuestions, setSubquestions] = useState<SubQuestion[]>(page.subQuestions || [])

  const getImgSrc = () => {
    let finalImgSrc = ''

    if (questionImageUploaded != null) {
      finalImgSrc = window.URL.createObjectURL(questionImageUploaded)
    } else {
      finalImgSrc = hostname + '/' + (question.image ?? '')
    }

    return finalImgSrc
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    //orgTmp.image = window.URL.createObjectURL(fileObj)

    console.log('FILEOBJ', fileObj)
    setQuestionImageUploaded(fileObj)
    setPageDirty(true)
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (isPageDirty) {
      timeout = setTimeout(() => {
        const newQuestion = { ...question, image: questionImageUploaded }
        setQuestion(newQuestion)

        updateQuestion(page.id, newQuestion, answers, subQuestions).then(() => {
          setIsEditingQuestionText(false)
          setIsEditingAnswerSection(false)
        })
        setPageDirty(false)
      }, 2000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isPageDirty, question, answers, subQuestions, page.id])

  const handleQuestionTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedQuestion = { ...question, questionText: event.target.value }
    setQuestion(updatedQuestion)
    setPageDirty(true)
  }

  const handleAnswerTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>, id: number) => {
    const updatedAnswers = answers.map((answer) => {
      if (answer.id === id) {
        return { ...answer, text: event.target.value }
      }
      return answer
    })
    setAnswers(updatedAnswers)
    setPageDirty(true)
  }
  const handleUploadButtonClick = () => {
    // 👇️ open file input box on click of other element
    hiddenInputRef.current?.click()
  }

  return (
    <div style={{ height: '100%' }}>
      <Grid style={{ height: '100%' }}>
        <Grid.Col span={8} style={{ height: '100%' }}>
          <Flex
            mih={50}
            gap='lg'
            align='flex-start'
            direction='column'
            wrap='wrap'
            style={{ height: '100%', justifyContent: 'space-between' }}
          >
            {isEditingQuestionText ? (
              <div style={{ width: '100%' }}>
                <Textarea
                  style={{ width: '100%' }}
                  placeholder={page.questionText}
                  withAsterisk
                  value={question.questionText}
                  onChange={handleQuestionTextChange}
                />
                <Button
                  variant='subtle'
                  size='xs'
                  leftIcon={isEditingQuestionText ? <ArrowLeft /> : <EditCircle />}
                  onClick={() => setIsEditingQuestionText(false)}
                >
                  Back
                </Button>
              </div>
            ) : (
              <Title>
                {question.questionText}{' '}
                {isEditingQuestionText ? null : (
                  <Button
                    variant='subtle'
                    color='grape'
                    size='xs'
                    m={16}
                    leftIcon={isEditingQuestionText ? <ArrowLeft /> : <EditCircle />}
                    onClick={() => setIsEditingQuestionText(!isEditingQuestionText)}
                  >
                    Edit
                  </Button>
                )}
              </Title>
            )}

            {answers.map((answer) => {
              return (
                <React.Fragment key={answer.id}>
                  {isEditingAnswerSection ? (
                    <Textarea
                      placeholder={answer.text}
                      withAsterisk
                      value={answer.text}
                      onChange={(e) => handleAnswerTextChange(e, answer.id!!)}
                    />
                  ) : (
                    <Title>
                      {answer.text}{' '}
                      {isEditingQuestionText ? null : (
                        <Button
                          m={16}
                          variant='subtle'
                          color='grape'
                          size='xs'
                          leftIcon={isEditingQuestionText ? <ArrowLeft /> : <EditCircle />}
                          onClick={() => setIsEditingAnswerSection(!isEditingAnswerSection)}
                        >
                          {isEditingQuestionText ? 'Save' : 'Edit'}
                        </Button>
                      )}
                    </Title>
                  )}
                </React.Fragment>
              )
            })}
          </Flex>
        </Grid.Col>
        <Grid.Col span={4}>
          {' '}
          <Card
            withBorder
            radius='md'
            p='md'
            className={`${classes.card}`}
            onMouseEnter={() => setIsUploadProfileImageOpen(true)}
            onMouseLeave={() => setIsUploadProfileImageOpen(false)}
            sx={{ minHeight: 100 }}
          >
            <div className={`${isUploadProfileImageOpen ? 'light-blur' : ''}`}>
              {canShowImage(questionImageUploaded, question?.image) && (
                <ImageRetry src={getImgSrc()} alt={''} />
              )}
            </div>
            {isUploadProfileImageOpen && (
              <div className='view-upload-image-form'>
                <ActionIcon
                  size={'xl'}
                  title={t('uploadImage')}
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleUploadButtonClick()
                  }}
                >
                  <span className='material-symbols-outlined'>upload</span>
                </ActionIcon>
                {/* 👇 Notice the `display: hidden` on the input. Used to invoke upload file form.*/}
                <input
                  type='file'
                  accept={'image/png, image/jpeg, image/jpg'}
                  ref={hiddenInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            )}
          </Card>
        </Grid.Col>
      </Grid>
    </div>
  )
}

export const RoundResultPage = ({ page }: PagePreviewProps) => {
  return (
    <div>
      <p>{page.subQuestion}</p>
    </div>
  )
}

export const RateQuizPage = ({ page }: PagePreviewProps) => {
  return (
    <div>
      <p>{page.text}</p>
    </div>
  )
}

export const PagePreview = ({ page, isPageDirty, setPageDirty }: any) => {
  switch (page.type) {
    case 'signUp':
      return <SignUpPage isPageDirty={isPageDirty} setPageDirty={setPageDirty} page={page} />
    case 'page':
      return <PagePage isPageDirty={isPageDirty} setPageDirty={setPageDirty} page={page} />
    case 'round':
      return <RoundPage isPageDirty={isPageDirty} setPageDirty={setPageDirty} page={page} />
    case 'question':
      return (
        <QuestionPage
          isPageDirty={isPageDirty}
          setPageDirty={setPageDirty}
          key={page.id}
          page={page}
        />
      )
    case 'roundResult':
      return <RoundResultPage isPageDirty={isPageDirty} setPageDirty={setPageDirty} page={page} />
    case 'rateQuiz':
      return <RateQuizPage isPageDirty={isPageDirty} setPageDirty={setPageDirty} page={page} />
    default:
      return null
  }
}
