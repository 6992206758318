import React from 'react'
import {
  Container,
  createStyles,
  Title,
  SimpleGrid,
  Group,
  Button,
  TextInput,
  Card,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { SwitchesCard, SwitchesCardProps } from '../../components/CardWithSwitches'
import { ProgressButton } from '../../components/ProgressButton'
import ProfileImage from '../../components/ProfileImage'
import { Stack } from 'tabler-icons-react'

const useStyles = createStyles((theme, _params, getRef) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },

    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },

    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    cardProfileImage: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }
})

const switches_mock: SwitchesCardProps = {
  title: 'Configure notifications',
  description: 'Choose what notifications you want to receive',
  data: [
    {
      title: 'Chat',
      translationTitleKey: 'chat_title',
      description: 'Direct messages you have received from other users',
      translationDescriptionKey: 'chat_description',
    },
  ],
}

export const Profile = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Container my='md'>
      <Title align='center' className={classes.title}>
        {t('profile')}
      </Title>
      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }]}>
        <Card withBorder radius='md' p='xl' className={classes.cardProfileImage}>
          <ProfileImage />
        </Card>
        <Card withBorder radius='md' p='xl' className={classes.card}>
          <form className={classes.form} onSubmit={(event) => event.preventDefault()}>
            <div className={classes.fields}>
              <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                <TextInput label={t('your_name_title')} placeholder={t('your_name_title') ?? ''} />
                <TextInput
                  label={t('your_last_name_title')}
                  placeholder={t('your_last_name_title') ?? ''}
                />
              </SimpleGrid>
              <TextInput
                mt='md'
                label={t('your_email_title')}
                placeholder='hello@mail.com'
                required
              />
              <Group position='right' mt='md'>
                <ProgressButton
                  label={t('export_data_button')}
                  processingDoneLabel={t('processing_done_label')}
                  processingLabel={t('processing_label')}
                />
                <Button type='submit' className={classes.control} onClick={() => {}}>
                  {t('save_button')}
                </Button>
              </Group>
            </div>
          </form>
        </Card>
        <Stack>
          <SwitchesCard
            {...switches_mock}
            title={t('configure_notifications_title')}
            description={t('configure_notifications_description')}
          />
        </Stack>
      </SimpleGrid>
      {/* <SimpleGrid cols={2} spacing='md' breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        
        <Skeleton height={BASE_HEIGHT} radius='md' animate={false} />
        <Grid gutter='md'>
          <Grid.Col><Skeleton height={PRIMARY_COL_HEIGHT} radius='md' animate={false} /></Grid.Col>
          <Grid.Col span={6}><Skeleton height={PRIMARY_COL_HEIGHT} radius='md' animate={false} /></Grid.Col>
          <Grid.Col span={6}><Skeleton height={PRIMARY_COL_HEIGHT} radius='md' animate={false} /></Grid.Col>
          <Grid.Col xs={6}>{getChild(getSubHeight(3, theme.spacing.md))}</Grid.Col>
        </Grid>
      </SimpleGrid> */}
    </Container>
  )
}
