import React from 'react'
import { Avatar, Badge, createStyles, Divider, Menu } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ManualGearbox, Settings, Trash, User as UserIcon } from 'tabler-icons-react'
import { LanguageSelector } from '../../../components/LanguageSelector'
import { User } from '../../../utils/types'
import { deactivateUserById } from '../../../utils/axios'
import { showErrorNotification, showSuccessfulNotification } from '../../../utils/commonFunctions'
import { useAuth } from '../../../contexts/AuthProvider'

type UserProfileSubmenuProps = {
  userProfile: User | null
  onLogout: () => void
}

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    userInfo: {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      cursor: 'pointer',
    },
  }
})

export const UserProfileSubmenu = (props: UserProfileSubmenuProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { userProfile } = useAuth()

  const getUsersInitials = (userFullName: string) =>
    userFullName
      .split(' ')
      .map((item) => item.charAt(0))
      .join('')
      .toUpperCase()

  const deleteUserAction = async (id: number) => {
    const deleteRes = await deactivateUserById(id)

    if (deleteRes.status === 200) {
      showSuccessfulNotification(t('information'), t('delete_user_account_success'))
      props.onLogout()
    } else {
      // account se ne moze izbrisati
      showErrorNotification(
        t('error_title'),
        `${t('error_general_message')} ${t('delete_user_account_failed')}`
      )
    }
  }

  return (
    <Menu shadow='md' width={200}>
      <Menu.Target>
        <Avatar className={classes.userInfo} size={40} color='white'>
          {getUsersInitials(`${props.userProfile?.firstName} ${props.userProfile?.lastName}`)}
        </Avatar>
      </Menu.Target>
      <Menu.Dropdown>
        <Badge variant='gradient' fullWidth mb={5} gradient={{ from: 'orange', to: 'red' }}>
          {userProfile?.email}
        </Badge>
        <Menu.Label>{t('general')}</Menu.Label>
        <Menu.Item icon={<UserIcon size={14} />} onClick={() => navigate('/profile')}>
          {t('profile')}
        </Menu.Item>
        <Menu.Item icon={<Settings size={14} />} onClick={() => navigate('/settings')}>
          {t('settings')}
        </Menu.Item>
        <Divider />
        <Menu.Label>{t('dangerZone')}</Menu.Label>
        <Menu.Item icon={<ManualGearbox size={14} />} onClick={() => navigate('/admin')}>
          {t('admin')}
        </Menu.Item>
        <Menu.Item
          color='red'
          onClick={() => deleteUserAction(props.userProfile?.id!)}
          icon={<Trash size={14} />}
        >
          {t('deleteMyAccount')}
        </Menu.Item>
        <LanguageSelector />
      </Menu.Dropdown>
    </Menu>
  )
}
