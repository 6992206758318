import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import {
  Button,
  createStyles,
  Grid,
  TextInput,
  Divider,
  Paper,
  Textarea,
  Text,
  NumberInput,
  ActionIcon,
  Card,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Answer, Question, QuestionsAndAnswers, SubQuestion } from '../../../utils/types'
import _set from 'lodash/set'
import { createQuestion, hostname, updateQuestion } from '../../../utils/axios'
import {
  canShowImage,
  showErrorNotification,
  showSuccessfulNotification,
} from '../../../utils/commonFunctions'
import { useAuth } from '../../../contexts/AuthProvider'
import { useGeneralContext } from '../../../contexts/GeneralProvider'
import { useNavigate } from 'react-router-dom'
import { RichTextEditor } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Superscript from '@tiptap/extension-superscript'
import SubScript from '@tiptap/extension-subscript'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { ImageRetry } from '../../ImageRetry'
import {
  handleDragLeave,
  handleDragOver,
  handleDragStart,
  handleDrop,
} from '../../../utils/dragEventHandlers'

type SequenceProps = {
  initialData?: QuestionsAndAnswers
  questionTypeId: number
  quizId: number
  questionRound: number
  questionPosition: number
  timeDuration: number
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')
  return {
    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  }
})

export const Sequence: FC<SequenceProps> = (props) => {
  const navigate = useNavigate()
  const { toggleLoadingOverlay } = useGeneralContext()
  const { t } = useTranslation()
  const { userProfile } = useAuth()
  const { classes } = useStyles()
  const [isSaving, setIsSaving] = useState(false)
  const [questionText, setQuestionText] = useState('')
  const [subQuestions, setSubQuestions] = useState<SubQuestion[]>([])
  const [subQuestionNewImageUploadIndex, setSubQuestionNewImageUploadIndex] = useState(0) // it will hold the info which subQuestion index is getting new image uploaded
  const [isUploadImageOpen, setIsUploadImageOpen] = useState(false)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Superscript,
      SubScript,
      Underline,
      Placeholder.configure({ placeholder: t('questionHeader') ?? '' }),
    ],
    content: questionText,
    onUpdate({ editor }) {
      setQuestionText(editor.getHTML())
    },
  })

  const initialSubQuestionValue = {
    text: '',
    points: 1,
  }

  const hiddenInputRef = useRef<HTMLInputElement | null>(null)
  //todo Talk about question/answer points in Risk category, will it be user input, max possible
  // points(array.length value) or should we set it to 0/null/whatever, Same thing will be with Sequence category

  const [answer, setAnswer] = useState<Answer>({
    text: 'a) c) b) d)',
    image: null,
    points: 4,
    isCorrect: true,
  })

  useEffect(() => {
    if (props.initialData) {
      setQuestionText(props.initialData.question.questionText)
      setSubQuestions(props.initialData.subQuestions)
      setAnswer(props.initialData.answers[0])
      editor?.commands.setContent(props.initialData.question.questionText)
    }
  }, [editor, props.initialData])

  const handleUploadButtonClick = (idx: number) => {
    setSubQuestionNewImageUploadIndex(idx)
    // 👇️ open file input box on click of other element
    hiddenInputRef.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files)
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    console.log(fileObj)

    if (subQuestions[subQuestionNewImageUploadIndex] != null) {
      let sqTmp = subQuestions
      sqTmp[subQuestionNewImageUploadIndex].image = fileObj
      setSubQuestions(sqTmp)
    }
  }

  const addSubQuestion = () => {
    setSubQuestions((prevState) => [...prevState, initialSubQuestionValue])
  }

  const removeSubQuestion = (idx: number) => {
    setSubQuestions((prevState) => [...prevState.slice(0, idx), ...prevState.slice(idx + 1)])
  }

  const handleSubQuestionTextChange = (index: number, text: string) => {
    setCustomPropertyValue(index, 'text', text)
  }

  const handleSubQuestionPointsChange = (index: number, points: number) => {
    setCustomPropertyValue(index, 'points', points)
  }

  const setCustomPropertyValue = (index: number, propertyName: string, value: any) => {
    const fileToPersist: File | string | undefined = subQuestions[index].image
    let subQuestionTmp: SubQuestion[] = JSON.parse(JSON.stringify(subQuestions))
    _set(subQuestionTmp, `[${index}].${propertyName}`, value)
    subQuestionTmp[index].image = fileToPersist
    console.log(subQuestionTmp)
    setSubQuestions(subQuestionTmp)
  }

  const getImgSrc = (idx: number) => {
    let finalImgSrc = ''
    if (subQuestions[idx].image instanceof File) {
      finalImgSrc = window.URL.createObjectURL(subQuestions[idx].image as File)
    } else {
      finalImgSrc = hostname + '/' + (subQuestions[idx]?.image ?? '')
    }

    return finalImgSrc
  }

  const renderSubquestionComponent = (item: SubQuestion, idx: number) => {
    return (
      <div
        key={`subquestion-div-${idx}-${item.id}`}
        style={{
          width: '100%',
        }}
        className='subquestion-template'
        draggable={true}
        onDragStart={(event) => handleDragStart(event, idx, '.subquestion-template')}
        onDragOver={(event: any) => handleDragOver(event, '.subquestion-template')}
        onDragLeave={(event: any) => handleDragLeave(event, '.subquestion-template')}
        onDrop={(event: any) => {
          handleDrop(event, idx, '.subquestion-template', subQuestions, setSubQuestions)
        }}
      >
        <React.Fragment>
          <Grid.Col xs={12}>
            <Divider
              my='sm'
              label={`${idx + 1}. ${t('subquestionHeader')}`}
              labelPosition='center'
              size='lg'
            />
            <Grid>
              <Grid.Col xs={7}>
                <Textarea
                  placeholder={`${idx + 1}. ${t('subquestion_text')}`}
                  autosize
                  minRows={2}
                  onChange={(event) => {
                    handleSubQuestionTextChange(idx, event.target.value)
                  }}
                  value={item.text}
                />
              </Grid.Col>
              <Grid.Col xs={5}>
                <Card
                  withBorder
                  radius='md'
                  p='md'
                  className={`${classes.card}`}
                  onMouseEnter={() => setIsUploadImageOpen(true)}
                  onMouseLeave={() => setIsUploadImageOpen(false)}
                  sx={{ minHeight: 100 }}
                >
                  <div className={`${isUploadImageOpen ? 'light-blur' : ''}`}>
                    {canShowImage(null, subQuestions[idx]?.image) && (
                      <ImageRetry src={getImgSrc(idx)} alt={''} width='100%' />
                    )}
                  </div>
                  {isUploadImageOpen && (
                    <div className='view-upload-image-form'>
                      <ActionIcon
                        size={'xl'}
                        title={t('uploadImage')}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          handleUploadButtonClick(idx)
                        }}
                      >
                        <span className='material-symbols-outlined'>upload</span>
                      </ActionIcon>
                      {/* 👇 Notice the `display: hidden` on the input. Used to invoke upload file form.*/}
                      <input
                        type='file'
                        accept={'image/png, image/jpeg, image/jpg'}
                        ref={hiddenInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  )}
                </Card>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col
                xs={10.5}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  style={{ marginTop: '24px' }}
                  color={'red'}
                  variant={'outline'}
                  title={t('delete_title')}
                  onClick={(e: any) => {
                    e.stopPropagation()
                    removeSubQuestion(idx)
                  }}
                >
                  {t('remove_subquestion')}
                </Button>
              </Grid.Col>
              <Grid.Col
                xs={1.5}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <NumberInput
                  decimalSeparator=','
                  placeholder={t('pointsHeader') ?? ''}
                  label={t('pointsHeader')}
                  value={item.points ?? 1}
                  precision={1}
                  step={0.5}
                  onChange={(nmb) => {
                    handleSubQuestionPointsChange(idx, nmb ?? 1)
                  }}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </React.Fragment>
      </div>
    )
  }

  const onSaveAction = (goBack: boolean = false) => {
    const isUpdate = props.initialData?.question?.id
    //todo Talk about question/answer points in Risk category, will it be user input, max possible
    // points(array.length value) or should we set it to 0/null/whatever, Same thing will be with Sequence category
    const question: Question = {
      image: null,
      quizId: props.quizId,
      points: 4,
      questionTypeId: props.questionTypeId,
      questionText: questionText,
      round: props.questionRound,
      owner: isUpdate ? props.initialData?.question?.owner! : userProfile?.id!,
      timeDuration: props.timeDuration,
      position: props.questionPosition,
    }

    toggleLoadingOverlay(true)
    setIsSaving(true)
    console.log('onSaveAction - ', subQuestions)
    if (isUpdate) {
      updateQuestion(props.initialData?.question?.id!, question, [answer], subQuestions)
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)

          setIsSaving(false)
        })
    } else {
      createQuestion(question, [answer], subQuestions)
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)
          setIsSaving(false)
        })
    }
  }

  return (
    <React.Fragment>
      <Grid.Col xs={5}></Grid.Col>
      <Grid.Col xs={1}>
        <Text size='sm' weight={500}>
          {t('questionHeader')}
        </Text>
      </Grid.Col>
      <Grid.Col xs={11}>
        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Code />
              <RichTextEditor.Subscript />
              <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
          <RichTextEditor.Content />
        </RichTextEditor>
      </Grid.Col>
      {subQuestions.map((subQuestion, idx) => {
        return renderSubquestionComponent(subQuestion, idx)
      })}
      <Grid.Col xs={12}>
        <Button variant={'outline'} className={classes.control} onClick={addSubQuestion}>
          {t('add_new_subquestion')}
        </Button>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Paper radius='lg' p='md' withBorder>
          <TextInput
            label={t('requestedAnswerTitle')}
            required
            value={answer.text}
            placeholder={t('requestedAnswerTitle') ?? ''}
            onChange={(event) => {
              const answerTextValue = event.currentTarget.value || 'No answer text entered'
              const updatedAnswer = { ...answer, text: answerTextValue }
              setAnswer(updatedAnswer)
            }}
          />
        </Paper>
      </Grid.Col>
      <Grid.Col
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button className={classes.control} onClick={() => onSaveAction()} loading={isSaving}>
          {t('save_button')}
        </Button>
        <Button
          ml={10}
          className={classes.control}
          onClick={() => onSaveAction(true)}
          loading={isSaving}
        >
          {t('save_and_back_button')}
        </Button>
        {/* 👇 Notice the `display: hidden` on the input. Used to invoke upload file form.*/}
        <input
          type='file'
          accept={'image/png, image/jpeg, image/jpg'}
          ref={hiddenInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Grid.Col>
    </React.Fragment>
  )
}
