import { Container, createStyles, Title } from '@mantine/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },
  }
})

export const AdminDashboard = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Container my='md'>
      <Title align='center' className={classes.title}>
        {t('admin')}
      </Title>
    </Container>
  )
}
