import React from 'react'
import ReactDOM from 'react-dom/client'
import './i18n'
import './index.css'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom'

window.addEventListener('beforeunload', () => {
  const pendingRedirectKey = Object.keys(window.sessionStorage).find((key) =>
    /^firebase:pendingRedirect:/.test(key)
  )
  if (!pendingRedirectKey) {
    console.log(
      'firebase:pendingRedirect: key missing from sessionStorage, getRedirectResult() will return null'
    )
  } else {
    console.log('firebase:pendingRedirect key is present')
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    {' '}
    <App />
  </BrowserRouter>
)
