import React, { useCallback, useEffect, useState } from 'react'
import { Container, createStyles, Table, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Role } from '../../../utils/types'
import { fetchRoles } from '../helper'

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },
    table: {
      width: '100%',
      marginBottom: theme.spacing.xl,

      '& thead tr th': {
        textAlign: 'center',
      },
    },
    centered: {
      textAlign: 'center',
    },
    right: {
      textAlign: 'right',
    },
  }
})

export const Roles = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [roles, setRoles] = useState<Role[] | null>(null)
  const navigate = useNavigate()

  const startFetchRoles = useCallback(async (ignoreFetch?: boolean) => {
    const result = await fetchRoles()
    if (!ignoreFetch) {
      setRoles(result)
    }
  }, [])

  useEffect(() => {
    let ignoreRolesFetch = false

    startFetchRoles(ignoreRolesFetch)

    return () => {
      ignoreRolesFetch = true
    }
  }, [startFetchRoles])

  const orgRows = roles?.map((role: Role) => (
    <tr onClick={() => navigate(`/role/${role.id}`)} key={role.id}>
      <td>{role.name}</td>
    </tr>
  ))

  const headers = (
    <tr>
      <th>{t('title')}</th>
    </tr>
  )

  return (
    <Container my='md' size={'xl'}>
      <Title align='center' className={classes.title}>
        {t('allRoles')}
      </Title>
      <Table highlightOnHover className={classes.table}>
        <thead>{headers}</thead>
        <tbody>{orgRows}</tbody>
        {/* <tfoot>{headers}</tfoot> */}
      </Table>
    </Container>
  )
}
