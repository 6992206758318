import { Button } from '@mantine/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './style.scss'
import Cookies from 'js-cookie'
import { COOKIES_CONSTANTS } from '../../utils/commonData'

export const CookiePanel = () => {
  const [isCookiePromptVisible, setIsCookiePromptVisible] = useState(
    Cookies.get(COOKIES_CONSTANTS.userAcceptedCookie) == null
  )
  const { t } = useTranslation()

  const acceptAllAndClose = () => {
    Cookies.set(COOKIES_CONSTANTS.userAcceptedCookie, 'all', { expires: 30 })
    setIsCookiePromptVisible(false)
  }

  return (
    <>
      {isCookiePromptVisible && (
        <div className='cookie-panel'>
          <p>
            {t('cookie_text')} <Link to='/privacypanel'>https://quiz-to-go.com/privacypanel</Link>.
          </p>
          <div className='buttons'>
            <Button
              onClick={() => acceptAllAndClose()}
              size='sm'
              variant='outline'
              leftIcon={<span className='material-symbols-outlined'>cookie</span>}
            >
              {t('cookie_button_accept')}
            </Button>
          </div>
          <p>
            {t('cookie_learn_more')}{' '}
            <a href='https://www.google.com' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>
          </p>
        </div>
      )}
    </>
  )
}
