import React from 'react'
import { createStyles, Card, Group, Switch, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  item: {
    '& + &': {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },
  },

  switch: {
    '& *': {
      cursor: 'pointer',
    },
  },

  title: {
    lineHeight: 1,
  },
}))

export interface SwitchesCardProps {
  title: string
  description: string
  data: {
    title: string
    description: string,
    translationTitleKey: string,
    translationDescriptionKey: string
  }[]
}

export function SwitchesCard({ title, description, data }: SwitchesCardProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const items = data.map((item, idx) => (
    <Group key={idx} position='apart' className={classes.item} noWrap spacing='xl'>
      <div>
        <Text>{ t(item.translationTitleKey) }</Text>
        <Text size='xs' color='dimmed'>
          { t(item.translationDescriptionKey) }
        </Text>
      </div>
      <Switch onLabel='ON' offLabel='OFF' className={classes.switch} size='lg' />
    </Group>
  ))

  return (
    <Card withBorder radius='md' p='xl' className={classes.card}>
      <Text size='lg' className={classes.title} weight={500}>
        {title}
      </Text>
      <Text size='xs' color='dimmed' mt={3} mb='xl'>
        {description}
      </Text>
      {items}
    </Card>
  )
}
