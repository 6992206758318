import { createOrganization, getAllOrganizations, updateOrganization } from '../../utils/axios'
import { Organization } from '../../utils/types'

export const fetchOrganizations = async (): Promise<Organization[]> => {
  const organizations = await getAllOrganizations()
  return organizations
}

export const updateOrganizationAction = async (
  dataToUpdate: Organization
): Promise<Organization[]> => {
  const organizations = await updateOrganization(dataToUpdate.id!, dataToUpdate)
  return organizations
}

export const createOrganizationAction = async (
  dataToCreate: Organization
): Promise<Organization[]> => {
  const organizations = await createOrganization(dataToCreate)
  return organizations
}
