import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Button,
  createStyles,
  Grid,
  TextInput,
  ActionIcon,
  Card,
  Text,
  Paper,
  NumberInput,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { createQuestion, hostname, updateQuestion } from '../../../utils/axios'
import { Answer, Question, QuestionsAndAnswers } from '../../../utils/types'
import { useNavigate } from 'react-router-dom'
import {
  canShowImage,
  showErrorNotification,
  showSuccessfulNotification,
} from '../../../utils/commonFunctions'
import { ImageRetry } from '../../ImageRetry'
import { useAuth } from '../../../contexts/AuthProvider'
import { useGeneralContext } from '../../../contexts/GeneralProvider'
import { RichTextEditor } from '@mantine/tiptap'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Superscript from '@tiptap/extension-superscript'
import SubScript from '@tiptap/extension-subscript'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'

type CorrectValueEstimationProps = {
  initialData?: QuestionsAndAnswers
  questionTypeId: number
  quizId: number
  questionRound: number
  questionPosition: number
  timeDuration: number
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')
  return {
    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  }
})

export const CorrectValueEstimation: React.FC<CorrectValueEstimationProps> = (props) => {
  const { toggleLoadingOverlay } = useGeneralContext()
  const { t } = useTranslation()
  const { userProfile } = useAuth()
  const { classes } = useStyles()
  const [isSaving, setIsSaving] = useState(false)
  const navigate = useNavigate()
  const [questionText, setQuestionText] = useState('')
  const [questionImageUploaded, setQuestionImageUploaded] = useState<File | null>(null)
  const [points, setPoints] = useState<number>(1)
  const [isUploadProfileImageOpen, setIsUploadProfileImageOpen] = useState(false)
  const hiddenInputRef = useRef<HTMLInputElement | null>(null)
  const [answer, setAnswer] = useState<Answer>({
    text: '',
    image: null,
    points: points,
    isCorrect: true,
  })

  const editor = useEditor({
    extensions: [
      StarterKit,
      Superscript,
      SubScript,
      Underline,
      Placeholder.configure({ placeholder: t('questionHeader') ?? '' }),
    ],
    content: questionText,
    onUpdate({ editor }) {
      setQuestionText(editor.getHTML())
    },
  })

  useEffect(() => {
    if (props.initialData) {
      setQuestionText(props.initialData?.question.questionText)
      setAnswer(props.initialData.answers[0])
      setPoints(props.initialData?.question.points)
      editor?.commands.setContent(props.initialData?.question.questionText)
    }
  }, [editor, props.initialData])

  const handleUploadButtonClick = () => {
    // 👇️ open file input box on click of other element
    hiddenInputRef.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    //orgTmp.image = window.URL.createObjectURL(fileObj)
    setQuestionImageUploaded(fileObj)
  }

  const getImgSrc = () => {
    let finalImgSrc = ''

    if (questionImageUploaded != null) {
      finalImgSrc = window.URL.createObjectURL(questionImageUploaded)
    } else {
      finalImgSrc = hostname + '/' + (props.initialData?.question.image ?? '')
    }

    return finalImgSrc
  }

  const onSaveAction = (goBack: boolean = false) => {
    const isUpdate = props.initialData?.question?.id
    const question: Question = {
      image: questionImageUploaded || props.initialData?.question?.image || null,
      quizId: props.quizId,
      points: points,
      questionTypeId: props.questionTypeId,
      questionText: questionText,
      round: props.questionRound,
      owner: isUpdate ? props.initialData?.question?.owner! : userProfile?.id!,
      position: props.questionPosition,
      timeDuration: props.timeDuration,
    }

    toggleLoadingOverlay(true)
    setIsSaving(true)
    if (isUpdate) {
      updateQuestion(props.initialData?.question?.id!, question, [answer], null)
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)
          setIsSaving(false)
        })
    } else {
      createQuestion(question, [answer], null)
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)
          setIsSaving(false)
        })
    }
  }

  return (
    <React.Fragment>
      <Grid.Col xs={1.5}>
        <NumberInput
          decimalSeparator=','
          placeholder={t('pointsHeader') ?? ''}
          label={t('pointsHeader')}
          value={points ?? 1}
          precision={1}
          step={0.5}
          onChange={(nmb) => {
            setPoints(nmb ?? 1)
          }}
        />
      </Grid.Col>
      <Grid.Col xs={3}></Grid.Col>
      <Grid.Col xs={1}>
        <Text size='sm' weight={500}>
          {t('questionHeader')}
        </Text>
      </Grid.Col>
      <Grid.Col xs={11}>
        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Code />
              <RichTextEditor.Subscript />
              <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
          <RichTextEditor.Content />
        </RichTextEditor>
      </Grid.Col>
      <Grid.Col xs={1}>
        <Text size='sm' weight={500}>
          {t('image')}
        </Text>
      </Grid.Col>
      <Grid.Col xs={11}>
        <Card
          withBorder
          radius='md'
          p='md'
          className={`${classes.card}`}
          onMouseEnter={() => setIsUploadProfileImageOpen(true)}
          onMouseLeave={() => setIsUploadProfileImageOpen(false)}
          sx={{ minHeight: 100 }}
        >
          <div className={`${isUploadProfileImageOpen ? 'light-blur' : ''}`}>
            {canShowImage(questionImageUploaded, props.initialData?.question.image) && (
              <ImageRetry src={getImgSrc()} alt={''} width='100%' />
            )}
          </div>
          {isUploadProfileImageOpen && (
            <div className='view-upload-image-form'>
              <ActionIcon
                size={'xl'}
                title={t('uploadImage')}
                onClick={(e: any) => {
                  e.stopPropagation()
                  handleUploadButtonClick()
                }}
              >
                <span className='material-symbols-outlined'>upload</span>
              </ActionIcon>
              {/* 👇 Notice the `display: hidden` on the input. Used to invoke upload file form.*/}
              <input
                type='file'
                accept={'image/png, image/jpeg, image/jpg'}
                ref={hiddenInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </div>
          )}
        </Card>
      </Grid.Col>
      <Grid.Col xs={12}>
        <Paper radius='lg' p='md' withBorder>
          <TextInput
            label={t('requestedAnswerTitle')}
            required
            value={answer.text}
            placeholder={t('requestedAnswerTitle') ?? ''}
            onChange={(event) => {
              const answerTextValue = event.currentTarget.value || 'No answer text entered'
              const updatedAnswer = { ...answer, text: answerTextValue }
              setAnswer(updatedAnswer)
            }}
          />
        </Paper>
      </Grid.Col>
      <Grid.Col
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button className={classes.control} onClick={() => onSaveAction()} loading={isSaving}>
          {t('save_button')}
        </Button>
        <Button
          ml={10}
          className={classes.control}
          onClick={() => onSaveAction(true)}
          loading={isSaving}
        >
          {t('save_and_back_button')}
        </Button>
      </Grid.Col>
    </React.Fragment>
  )
}
