import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Grid, SimpleGrid } from '@mantine/core'
import './style.css'
import { Card, Image, Button } from '@mantine/core'
import { Answer, QUESTION_TYPES, Question, QuestionPageQuizPlayer, SubQuestion } from '../../../../../../utils/types'
import { useGeneralContext } from '../../../../../../contexts/GeneralProvider'
import { createQuestion } from '../../../../../../utils/axios'

type QuizContentSectionProps = {
  pages: any | null
  setPages: React.Dispatch<React.SetStateAction<QuestionPageQuizPlayer[] | null>>
  selectedPage: number
  setSelectedPage: (posiition: number) => void
  setShowAddPageOptions: Dispatch<SetStateAction<boolean>>
  quizId: number
  isPageDirty: boolean
  setPageDirty: (isPageDirty: boolean) => void
}

export const AddPageComponent = ({
  pages,
  setPages,
  selectedPage,
  setSelectedPage,
  setShowAddPageOptions,
  quizId,
  isPageDirty,
  setPageDirty,
}: QuizContentSectionProps) => {
  const navLinkRef = useRef<HTMLButtonElement>(null)
  const [cardPosition, setCardPosition] = useState({ top: 200, left: 400 })
  const [previewQuestionTypePicker, setPreviewQuestionTypePicker] = useState<boolean>(false)
  const { questionTypes } = useGeneralContext()

  const rateQuizPageLastPosition = (pages: any[]) => {
    const rateQuizPage = pages.find((item: { type: string }) => item.type === 'rateQuiz')
    if (rateQuizPage) {
      const filteredData = pages.filter((item: { type: string }) => item.type !== 'rateQuiz')
      rateQuizPage.position = pages.length - 1
      filteredData.push(rateQuizPage)

      return filteredData
    }

    return pages
  }

  useEffect(() => {
    const navLinkElement = navLinkRef.current

    if (navLinkElement) {
      const { top, left, width } = navLinkElement.getBoundingClientRect()
      const cardHeight = document.getElementById('card')?.offsetHeight ?? 0
      setCardPosition({ top: top - cardHeight, left: left + width + 16 })
    }
  }, [navLinkRef])

  const createPage = () => {
    console.log('I SHOULD CREATE PAGE')
  }

  const createDefaultQuestion = (questionType: string) => {
    const newPages = rateQuizPageLastPosition([...pages])

    const doesHaveEvenOneRound = newPages.some((x) => x.type === 'round')
    if (!doesHaveEvenOneRound) {
      const roundPage: any = {
        text: 'Round 1',
        type: 'round',
        round: 1,
        position: selectedPage + 1,
      }

      console.log("SHOULD NOT BE HERE!")
      let defaultQuestion: Question = {
        id: 0,
        quizId: quizId,
        points: 1,
        questionTypeId: 22,
        questionText: 'Enter here text of your Question',
        layout: 1,
        image: 'images/Question-1708460848257--eiffel-tower-in-paris-151-medium.jpg',
        round: 1,
        owner: 21,
        timeDuration: 30,
        position: selectedPage + 2,
      }

      let defaultAnswers = [
        { text: 'Enter your answer here', image: null, points: 1, isCorrect: true },
      ]
      let defaultSubquestions = null

      if (questionType === QUESTION_TYPES.WRITE_ANSWER) {
        defaultQuestion.questionTypeId = 22
      }

      createQuestion(defaultQuestion, defaultAnswers, defaultSubquestions)

      const roundResult: any = {
        text: 'Round Result 1',
        type: 'roundResult',
        round: 1,
        position: selectedPage + 3,
      }


      setPageDirty(true)
      setPages(rateQuizPageLastPosition([...pages, roundPage, defaultQuestion, roundResult]))
      setSelectedPage(selectedPage + 2)
    } else {
      const findPageByPositionOfSelectedPage = pages.find(
        (x: { position: number }) => x.position === selectedPage
      )
      const roundOfAbove = findPageByPositionOfSelectedPage?.round

      let defaultQuestion: Question = {
        quizId: quizId,
        points: 1,
        questionTypeId: 22,
        questionText: 'Enter here text of your Question',
        layout: 1,
        image: 'images/Question-1708460848257--eiffel-tower-in-paris-151-medium.jpg',
        round: roundOfAbove,
        owner: 21,
        timeDuration: 30,
        position: selectedPage + 1,
        type: 'question'
      }

      let defaultAnswers: Answer[] = [
        { text: 'Enter your answer here', image: null, points: 1, isCorrect: true },
      ]
      
      console.log("NEW QUESTION POSITION", selectedPage + 1)
      let defaultSubquestions: SubQuestion[] = []

      if (roundOfAbove) {

        defaultQuestion.answers = defaultAnswers
        defaultQuestion.subQuestions = defaultSubquestions

        createQuestion(defaultQuestion, defaultAnswers, defaultSubquestions)
        const newPages = [
          ...pages.slice(0, selectedPage + 1),
          defaultQuestion,
          ...pages.slice(selectedPage + 1),
        ]

        console.log("HERE IS PAGES", newPages)
       
        const updatedPages = newPages.map(page => {
          if (page.position > selectedPage && page !== defaultQuestion) {
            return { ...page, position: page.position + 1 };
          }
          return page;
        });
    

        console.log("HERE IS UPDATED PAGES", updatedPages)

        setPages(updatedPages);
        setSelectedPage(selectedPage + 1)
/*         setPageDirty(true)
 */      }
    }

    setShowAddPageOptions(false)
    setPreviewQuestionTypePicker(true)
  }

  const createRound = () => {
    console.log('pages', pages)
    const lastRoundResultPage = pages.filter((page: any) => page.type === 'roundResult').pop()
    const thisRound: number = lastRoundResultPage.round + 1

    const roundPage: any = {
      text: 'Round ' + thisRound,
      type: 'round',
      round: thisRound,
      position: lastRoundResultPage.position + 1,
    }

    let defaultQuestion: Question = {
      id: 0,
      quizId: quizId,
      points: 1,
      questionTypeId: 22,
      questionText: 'Enter here text of your Question',
      layout: 1,
      image: 'images/Question-1708460848257--eiffel-tower-in-paris-151-medium.jpg',
      round: thisRound,
      owner: 21,
      timeDuration: 30,
      position: lastRoundResultPage.position + 2,
    }

    let defaultAnswers = [
      { text: 'Enter your answer here', image: null, points: 1, isCorrect: true },
    ]
    let defaultSubquestions = null

    createQuestion(defaultQuestion, defaultAnswers, defaultSubquestions)

    const roundResult: any = {
      text: 'Round Result ' + thisRound,
      type: 'roundResult',
      round: thisRound,
      position: lastRoundResultPage.position + 3,
    }

    setPages(rateQuizPageLastPosition([...pages, roundPage, defaultQuestion, roundResult]))
    setSelectedPage(selectedPage + 2)
    setPageDirty(true)

    console.log('lastRoundResultPage', lastRoundResultPage)
  }

  return (
    <Card
      id='card'
      shadow='sm'
      radius='md'
      withBorder
      style={{ position: 'absolute', ...cardPosition, zIndex: 100 }}
    >
      {previewQuestionTypePicker ? (
        <Grid>
          <Button onClick={() => setPreviewQuestionTypePicker(false)}>Back</Button>
          <Card className='addPageCard' withBorder radius={'md'}>
            <SimpleGrid cols={2} spacing='xl'>
              {questionTypes.map((x) => (
                <Button key={x.id} onClick={() => createDefaultQuestion(x.value)}>
                  {x.value}
                </Button>
              ))}
            </SimpleGrid>
          </Card>
        </Grid>
      ) : (
        <Grid>
          <Card className='addPageCard' withBorder radius={'md'}>
            {' '}
            <Card.Section>
              <Image
                src='https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png'
                height={160}
                width={200}
                alt='Question'
              />
            </Card.Section>
            <Button
              color='blue'
              onClick={() => setPreviewQuestionTypePicker(true)}
              fullWidth
              mt='md'
              radius='md'
            >
              Add Question
            </Button>
          </Card>

          <Card className='addPageCard' withBorder radius={'md'}>
            {' '}
            <Card.Section>
              <Image
                src='https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png'
                height={160}
                width={200}
                alt='Page'
              />
            </Card.Section>
            <Button color='blue' onClick={() => createPage()} fullWidth mt='md' radius='md'>
              Add Page
            </Button>
          </Card>

          <Card className='addPageCard' withBorder radius={'md'}>
            {' '}
            <Card.Section>
              <Image
                src='https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png'
                height={160}
                width={200}
                alt='Round'
              />
            </Card.Section>
            <Button color='blue' onClick={() => createRound()} fullWidth mt='md' radius='md'>
              Add Round
            </Button>
          </Card>
        </Grid>
      )}
    </Card>
  )
}
