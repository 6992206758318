import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthProvider'

export const ProtectedRoute = ({ children }: any) => {
  const { userProfile } = useAuth()
  const location = useLocation()

  if (userProfile == null) {
    return <Navigate to='/home' replace state={{ from: location }} />
  }

  return children
}
