// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app'
import { useEffect, useState } from 'react'
import {
  Auth,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  getRedirectResult,
} from 'firebase/auth'
import { getDatabase, Database } from 'firebase/database'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APP_KEY,
  authDomain: 'quiz-to-go-prod.firebaseapp.com',
  projectId: 'quiz-to-go-prod',
  storageBucket: 'quiz-to-go-prod.appspot.com',
  messagingSenderId: '35173969650',
  appId: '1:35173969650:web:31edb5b12c0afdd9182a6b',
}

const fbDBEndpoint = 'https://quiz-to-go-prod-default-rtdb.europe-west1.firebasedatabase.app'

export const useFirebase = () => {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null)
  const [firebaseAppAuth, setFirebaseAppAuth] = useState<Auth | null>(null)
  const [firebaseDB, setFirebaseDB] = useState<Database | null>(null)
  const [googleAuthProvider, setGoogleAuthProvider] = useState<GoogleAuthProvider | null>(null)

  useEffect(() => {
    // Initialize Firebase
    if (firebaseApp == null) {
      const app = initializeApp(firebaseConfig)
      setFirebaseApp(app)
    } else {
      if (firebaseAppAuth == null) {
        const auth = getAuth(firebaseApp)
        setFirebaseAppAuth(auth)

        const googleProvider = new GoogleAuthProvider()
        setGoogleAuthProvider(googleProvider)

        const db = getDatabase(firebaseApp, fbDBEndpoint)
        setFirebaseDB(db)
      }
    }
  }, [firebaseApp, firebaseAppAuth])

  return {
    firebaseApp,
    firebaseAppAuth,
    googleAuthProvider,
    firebaseDB,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
    GoogleAuthProvider,
    signOut,
    getRedirectResult,
  }
}
