import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import {
  Button,
  createStyles,
  Grid,
  Textarea,
  Divider,
  Card,
  ActionIcon,
  NumberInput,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Answer, Question, QuestionsAndAnswers, SubQuestion } from '../../../utils/types'
import _set from 'lodash/set'
import { createQuestion, hostname, updateQuestion } from '../../../utils/axios'
import {
  canShowImage,
  showErrorNotification,
  showSuccessfulNotification,
} from '../../../utils/commonFunctions'
import { ImageRetry } from '../../ImageRetry'
import { useAuth } from '../../../contexts/AuthProvider'
import { useGeneralContext } from '../../../contexts/GeneralProvider'
import { useNavigate } from 'react-router-dom'
import {
  handleDragLeave,
  handleDragOver,
  handleDragStart,
  handleDrop,
} from '../../../utils/dragEventHandlers'
import '../style.scss'

export type ImageUploadedIndex = {
  idx: number
  file: File
}

type SubquestionBlankSpacesProps = {
  initialData?: QuestionsAndAnswers
  questionTypeId: number
  quizId: number
  questionRound: number
  questionPosition: number
  timeDuration: number
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')
  return {
    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  }
})

export const SubquestionBlankSpaces: FC<SubquestionBlankSpacesProps> = (props) => {
  const { toggleLoadingOverlay } = useGeneralContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userProfile } = useAuth()
  const { classes } = useStyles()
  const [isSaving, setIsSaving] = useState(false)
  const [subQuestions, setSubQuestions] = useState<SubQuestion[]>([])
  const [answers, setAnswers] = useState<Answer[]>([])
  const initialSubQuestionValue: SubQuestion = {
    text: '',
    points: 1,
    image: undefined,
  }
  const initialAnswerValue: Answer = {
    text: '',
    points: 1,
    isCorrect: true,
  }
  const [isUploadImageOpen, setIsUploadImageOpen] = useState(false)
  const hiddenInputRef = useRef<HTMLInputElement | null>(null)
  const [subQuestionNewImageUploaded, setSubQuestionNewImageUploaded] = useState<
    ImageUploadedIndex[]
  >([]) // this will be populated only on newly added images per subquestion
  const [subQuestionNewImageUploadIndex, setSubQuestionNewImageUploadIndex] = useState(0) // it will hold the info which subQuestion index is getting new image uploaded

  useEffect(() => {
    if (props.initialData) {
      setSubQuestions(props.initialData.subQuestions)
      setAnswers(props.initialData.answers)
    }
  }, [props.initialData])

  const handleUploadButtonClick = (idx: number) => {
    setSubQuestionNewImageUploadIndex(idx)
    // 👇️ open file input box on click of other element
    hiddenInputRef.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    // before upload we remember the index of subquestion for which image is being uploaded - subquestionImageUploadIndex
    // if file is successfully uploaded we will set the state with the index and file information
    // if already exists perform an update, if not perform an insert
    if (
      subQuestionNewImageUploaded.filter(
        (searchElement: ImageUploadedIndex) => searchElement.idx === subQuestionNewImageUploadIndex
      ).length > 0
    ) {
      setSubQuestionNewImageUploaded((prevState) =>
        prevState.map((item: ImageUploadedIndex, idx: number) =>
          idx === subQuestionNewImageUploadIndex
            ? {
                ...item,
                file: fileObj,
              }
            : { ...item }
        )
      )
    } else {
      setSubQuestionNewImageUploaded((prevState) => [
        ...prevState,
        { idx: subQuestionNewImageUploadIndex, file: fileObj },
      ])
    }
  }

  const addSubQuestion = () => {
    setSubQuestions((prevState) => [...prevState, initialSubQuestionValue])
    setAnswers((prevState) => [...prevState, initialAnswerValue])
  }

  const removeSubQuestion = (idx: number) => {
    setSubQuestions((prevState) => [...prevState.slice(0, idx), ...prevState.slice(idx + 1)])
    setAnswers((prevState) => [...prevState.slice(0, idx), ...prevState.slice(idx + 1)])
    const tmpQuestionImageUploaded = subQuestionNewImageUploaded
      .filter((item: ImageUploadedIndex) => item.idx !== idx)
      .map((newItem: ImageUploadedIndex) => {
        if (newItem.idx > idx) {
          return { idx: newItem.idx - 1, file: newItem.file }
        } else {
          return newItem
        }
      })
    setSubQuestionNewImageUploaded(tmpQuestionImageUploaded)
  }

  const handleSubQuestionTextChange = (index: number, text: string) => {
    setCustomPropertyValue(index, 'text', text)
  }

  const handleAnswerTextChange = (index: number, text: string) => {
    setCustomPropertyValueAnswer(index, 'text', text)
  }

  const handleSubQuestionPointsChange = (index: number, points: number) => {
    setCustomPropertyValue(index, 'points', points)
  }

  const setCustomPropertyValueAnswer = (index: number, propertyName: string, value: any) => {
    let answerTmp: Answer[] = JSON.parse(JSON.stringify(answers))

    _set(answerTmp, `[${index}].${propertyName}`, value)
    setAnswers(answerTmp)
  }

  const setCustomPropertyValue = (index: number, propertyName: string, value: any) => {
    let subQuestionTmp: SubQuestion[] = JSON.parse(JSON.stringify(subQuestions))
    _set(subQuestionTmp, `[${index}].${propertyName}`, value)
    setSubQuestions(subQuestionTmp)
  }

  const getImgSrc = (idx: number) => {
    let finalImgSrc = ''
    const newlyUploadedImage = subQuestionNewImageUploaded.find(
      (searchElement: ImageUploadedIndex) => searchElement.idx === idx
    )

    if (newlyUploadedImage != null) {
      finalImgSrc = window.URL.createObjectURL(newlyUploadedImage.file)
    } else {
      finalImgSrc = hostname + '/' + (subQuestions[idx]?.image ?? '')
    }

    return finalImgSrc
  }

  const renderSubQuestionComponent = (item: SubQuestion, idx: number) => {
    return (
      <div
        key={`subquestion-div-${idx}-${item.id}`}
        style={{
          width: '100%',
        }}
        className='subquestion-template'
        draggable={true}
        onDragStart={(event) => handleDragStart(event, idx, '.subquestion-template')}
        onDragOver={(event: any) => handleDragOver(event, '.subquestion-template')}
        onDragLeave={(event: any) => handleDragLeave(event, '.subquestion-template')}
        onDrop={(event: any) => {
          handleDrop(event, idx, '.subquestion-template', subQuestions, setSubQuestions)
          handleDrop(event, idx, '', answers, setAnswers)
        }}
      >
        <React.Fragment>
          <Grid.Col xs={12}>
            <Divider
              my='sm'
              label={`${idx + 1}. ${t('subquestionHeader')}`}
              labelPosition='center'
              size='lg'
            />
            <Grid>
              <Grid.Col xs={4}>
                <Textarea
                  placeholder={`${idx + 1}. ${t('subquestion_text')}`}
                  autosize
                  minRows={2}
                  onChange={(event) => {
                    handleSubQuestionTextChange(idx, event.target.value)
                  }}
                  value={item.text}
                />
              </Grid.Col>
              <Grid.Col xs={5}>
                <Card
                  withBorder
                  radius='md'
                  p='md'
                  className={`${classes.card}`}
                  onMouseEnter={() => setIsUploadImageOpen(true)}
                  onMouseLeave={() => setIsUploadImageOpen(false)}
                  sx={{ minHeight: 100 }}
                >
                  <div className={`${isUploadImageOpen ? 'light-blur' : ''}`}>
                    {canShowImage(
                      subQuestionNewImageUploaded.find(
                        (searchElement: ImageUploadedIndex) => searchElement.idx === idx
                      )?.file,
                      subQuestions[idx]?.image
                    ) && <ImageRetry src={getImgSrc(idx)} alt={''} width='100%' />}
                  </div>
                  {isUploadImageOpen && (
                    <div className='view-upload-image-form'>
                      <ActionIcon
                        size={'xl'}
                        title={t('uploadImage')}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          handleUploadButtonClick(idx)
                        }}
                      >
                        <span className='material-symbols-outlined'>upload</span>
                      </ActionIcon>
                      {/* 👇 Notice the `display: hidden` on the input. Used to invoke upload file form.*/}
                      <input
                        type='file'
                        accept={'image/png, image/jpeg, image/jpg'}
                        ref={hiddenInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  )}
                </Card>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Textarea
                  placeholder={`${idx + 1}. ${t('answer_text')}`}
                  autosize
                  minRows={2}
                  onChange={(event) => {
                    handleAnswerTextChange(idx, event.target.value)
                  }}
                  value={answers[idx]?.text || ''}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col
                xs={10.5}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  style={{ marginTop: '24px' }}
                  color={'red'}
                  variant={'outline'}
                  title={t('delete_title')}
                  onClick={(e: any) => {
                    e.stopPropagation()
                    removeSubQuestion(idx)
                  }}
                >
                  {t('remove_subquestion')}
                </Button>
              </Grid.Col>
              <Grid.Col
                xs={1.5}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <NumberInput
                  decimalSeparator=','
                  placeholder={t('pointsHeader') ?? ''}
                  label={t('pointsHeader')}
                  value={item.points ?? 1}
                  precision={1}
                  step={0.5}
                  onChange={(nmb) => {
                    handleSubQuestionPointsChange(idx, nmb ?? 1)
                  }}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </React.Fragment>
      </div>
    )
  }

  const onSaveAction = (goBack: boolean = false) => {
    const isUpdate = props.initialData?.question?.id
    //todo Talk about question/answer points in Risk category, will it be user input, max possible
    // points(array.length value) or should we set it to 0/null/whatever, Same thing will be with Sequence category
    const question: Question = {
      image: null,
      quizId: props.quizId,
      points: 4,
      questionTypeId: props.questionTypeId,
      questionText: '',
      round: props.questionRound,
      owner: isUpdate ? props.initialData?.question?.owner! : userProfile?.id!,
      timeDuration: props.timeDuration,
      position: props.questionPosition,
    }

    const subQToSend = subQuestions.map((subQuestionItem, idx) => {
      subQuestionNewImageUploaded.forEach((subQImgUploadedItem) =>
        subQImgUploadedItem.idx === idx ? (subQuestionItem.image = subQImgUploadedItem.file) : null
      )
      return subQuestionItem
    })

    const newAW = answers.map((answerItem) => {
      answerItem.image = null
      answerItem.questionId = props.initialData?.question?.id
      return answerItem
    })

    toggleLoadingOverlay(true)
    setIsSaving(true)
    if (isUpdate) {
      updateQuestion(props.initialData?.question?.id!, question, newAW, subQToSend)
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)
          setIsSaving(false)
        })
    } else {
      createQuestion(question, answers, subQToSend) // include subQuestion Images - subQuestionNewImageUploaded
        .then((res) => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          if (goBack!!) {
            navigate(-1)
          }
        })
        .catch((exc) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${exc.message}`)
        })
        .finally(() => {
          toggleLoadingOverlay(false)
          setIsSaving(false)
        })
    }
  }

  return (
    <>
      <Grid.Col xs={12}>
        <Textarea placeholder={`${t('questionHeader')}`} autosize minRows={2} />
      </Grid.Col>
      {subQuestions.map((subQuestionItem, idx) => {
        return renderSubQuestionComponent(subQuestionItem, idx)
      })}
      <Grid.Col xs={12}>
        <Button variant={'outline'} className={classes.control} onClick={addSubQuestion}>
          {t('add_new_subquestion')}
        </Button>
      </Grid.Col>
      <Grid.Col
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <Button className={classes.control} onClick={() => onSaveAction()} loading={isSaving}>
          {t('save_button')}
        </Button>
        <Button
          ml={10}
          className={classes.control}
          onClick={() => onSaveAction(true)}
          loading={isSaving}
        >
          {t('save_and_back_button')}
        </Button>
      </Grid.Col>
    </>
  )
}
