import React from 'react'
import { useForm } from '@mantine/form'
import { Box, Button, Group, TextInput } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import { createTeam } from '../../../utils/axios'
import { showErrorNotification, showSuccessfulNotification } from '../../../utils/commonFunctions'
import { t } from 'i18next'
import { Team } from '../../../utils/types'

export const QuizPlayerSignUp = () => {
  const { quizId } = useParams()
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      name: '',
    },

    validate: {
      name: (value) => (/^[a-zA-Z0-9\s_-]{3,25}$/.test(value) ? null : 'Invalid team name'),
    },
  })

  const submitSignUpTeam = (name: string) => {
    if (quizId) {
      const payload: Team = {
        name: name,
        quizId: parseInt(quizId),
      }

      createTeam(payload)
        .then(() => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
          navigate(`/quiz-player/${quizId}`)
        })
        .catch((e) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${e.message}`)
        })
    }
  }

  return (
    <Box maw={300} mx='auto'>
      <form onSubmit={form.onSubmit((values) => submitSignUpTeam(values.name))}>
        <TextInput
          withAsterisk
          label={t('team_name')}
          placeholder={t('team_name_placeholder') || ''}
          {...form.getInputProps('name')}
        />
        <Group position='right' mt='md'>
          <Button type='submit'>{t('sign_up_team')}</Button>
        </Group>
      </form>
    </Box>
  )
}
