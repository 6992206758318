import { Modal, createStyles, Text, SimpleGrid, TextInput, Group, Button } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../utils/types'

export type CategoryCreatorProps = {
  isOpen: boolean
  closeModalAction: () => void
  saveButtonAction: ({ emoji, label }: Tag) => void
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    fieldInput: {
      flex: 1,

      '& + &': {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },

    fieldsGroup: {
      display: 'flex',

      [BREAKPOINT]: {
        flexDirection: 'column',
      },
    },

    title: {
      marginBottom: theme.spacing.xl * 1.5,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.xl,
      },
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  }
})

export const CategoryCreator = ({
  isOpen,
  closeModalAction,
  saveButtonAction,
}: CategoryCreatorProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const createTagAction = ({ emoji, label }: Tag) => {
    saveButtonAction({ emoji, label })
  }

  const form = useForm<Tag>({
    initialValues: { emoji: '', label: '' },

    validate: (values) => ({
      label: values.label.length < 1 ? 'Missing label' : null,
      emoji: values.emoji.length < 1 ? 'Missing emoji' : null,
    }),
  })

  return (
    <Modal centered opened={isOpen} onClose={() => closeModalAction()}>
      <form
        className={classes.form}
        onSubmit={form.onSubmit((values) => {
          createTagAction({ emoji: values.emoji, label: values.label })
          form.reset()
        })}
      >
        <Text size='lg' weight={700} className={classes.title}>
          {t('create_category')}
        </Text>
        <div className={classes.fields}>
          <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
            <TextInput
              label={t('emoji')}
              placeholder={t('emoji') ?? ''}
              required
              {...form.getInputProps('emoji')}
            />
            <TextInput
              label={t('title')}
              placeholder={t('title') ?? ''}
              required
              {...form.getInputProps('label')}
            />
          </SimpleGrid>
          <Group position='right' mt='md'>
            <Button type='submit' className={classes.control}>
              {t('save_button')}
            </Button>
          </Group>
        </div>
      </form>
    </Modal>
  )
}
