import { Paper, Group, Button, Stack, TextInput, Select, Switch, MultiSelect } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useForm } from '@mantine/form'
import { Organization, ROLES_ENUM, ROLES_LIST, User } from '../../../../utils/types'
import { useEffect } from 'react'
import { updateUser } from '../../../../utils/axios'
import { useGeneralContext } from '../../../../contexts/GeneralProvider'

type UserEditFormProps = {
  close?: (refreshData: boolean) => void
  isModal: boolean
  user: User | null
  organizations: Organization[]
}

export const UserEditForm = (props: UserEditFormProps) => {
  const { t } = useTranslation()
  const { toggleLoadingOverlay } = useGeneralContext()
  const form = useForm({
    initialValues:
      props.user ??
      ({
        firstName: '',
        lastName: '',
        nickname: '',
        lastLoginDate: new Date(),
        email: '',
        roleId: 1,
        isActive: true,
        isDeleted: false,
        createdAt: new Date(),
      } as User),

    validate: {},
  })

  const handleSubmit = async (values: User) => {
    let userDirtyPayload: { [key: string]: any } = { id: values.id }

    for (const [key, value] of Object.entries(values)) {
      if (form.isDirty(key)) {
        userDirtyPayload[key] = value
      }
    }

    console.log(userDirtyPayload)
    toggleLoadingOverlay(true)
    await updateUser(values.id!, userDirtyPayload)
    toggleLoadingOverlay(false)

    if (props.close && props.isModal) {
      props.close(true)
    }
  }

  useEffect(() => {
    if (props.user != null) {
      form.setValues(props.user)
      form.resetDirty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  return (
    <Paper radius='md' p='xl' withBorder>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stack>
          <TextInput
            label={t('title_first_name')}
            placeholder={t('title_first_name') ?? ''}
            value={form.values.firstName}
            disabled={true}
          />
          <TextInput
            label={t('title_last_name')}
            placeholder={t('title_last_name') ?? ''}
            value={form.values.lastName}
            disabled={true}
          />
          <TextInput
            label={t('your_nickname')}
            placeholder={t('your_nickname') ?? ''}
            value={form.values.nickname}
            onChange={(event) => form.setFieldValue('nickname', event.currentTarget.value)}
          />
          <TextInput
            label={t('email')}
            placeholder={t('email') ?? ''}
            value={form.values.email}
            disabled={true}
          />
          <Select
            label={t('roles')}
            placeholder={t('select_one') ?? ''}
            value={form.values.roleId.toString()}
            data={ROLES_LIST}
            onChange={(newValue: string | null) =>
              form.setFieldValue('roleId', parseInt(newValue ?? '0'))
            }
          />
          <MultiSelect
            data={props.organizations.map((org) => ({
              label: org.name,
              value: org.id!.toString(),
            }))}
            label={t('organizations')}
            placeholder={t('select_one') ?? ''}
            searchable
            nothingFound={t('no_results')}
            onChange={(value) => {
              const newOrgs: Organization[] = props.organizations.filter((org) =>
                value.includes(org.id!.toString())
              )
              form.setFieldValue('organizations', newOrgs)
            }}
            value={form.values.organizations?.map((org) => org.id!.toString())}
            disabled={form.values.roleId === ROLES_ENUM.SUPER_ADMIN}
          />
          <Switch
            size='md'
            label={t('active_switch')}
            defaultChecked={form.values.isActive}
            {...form.getInputProps('isActive')}
          />
          <Switch
            size='md'
            label={t('delete_switch')}
            defaultChecked={form.values.isDeleted}
            disabled
          />
        </Stack>
        <Group position='apart' mt='xl'>
          <Button type='submit' disabled={!form.isValid()}>
            {t('save_button')}
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
