import React from 'react'
import { Card, createStyles, Skeleton } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    cursor: 'pointer',
  },
}))

type OrganizationSkeletonCardProps = {
  count: number
}

export const OrganizationSkeletonCard = ({ count }: OrganizationSkeletonCardProps) => {
  const { classes } = useStyles()

  const renderOrganizationSkeletonCards = (count: number) => {
    let skeletonCards = []

    for (let i = 0; i < count; i++) {
      skeletonCards.push(
        <Card key={i} withBorder radius='md' p='md' className={classes.card}>
          <Skeleton height={300} radius='md' animate={true} />
        </Card>
      )
    }

    return skeletonCards
  }

  return <>{renderOrganizationSkeletonCards(count)}</>
}
