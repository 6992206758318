import React from 'react'
import { Container } from '@mantine/core'
import { OrganizationCard } from '../../components/OrganizationCards'
import './style.scss'
import { Organization } from '../../utils/types'
import { OrganizationSkeletonCard } from '../../components/OrganizationCards/SkeletonCards'
import { useGeneralContext } from '../../contexts/GeneralProvider'

const renderOrganizationCards = (organizationArray: Organization[]) => {
  if (organizationArray.length === 0) {
    return <OrganizationSkeletonCard count={5} />
  } else {
    return organizationArray.map((organization: Organization) => {
      return <OrganizationCard organization={organization} isHomepage={true} />
    })
  }
}

export const Homepage = () => {
  const { organizations } = useGeneralContext()
  return (
    <Container my='lg'>
      <div className='card-collection-div'>{renderOrganizationCards(organizations)}</div>
    </Container>
  )
}
