import { useCallback, useEffect, useState } from 'react'
import { Grid, Button, NavLink } from '@mantine/core'
import { Lock, SquareRoundedLetterP } from 'tabler-icons-react'
import { ArrowBigDownLines } from 'tabler-icons-react'
import { Stars } from 'tabler-icons-react'
import { UserPlus } from 'tabler-icons-react'
import './style.css'
/* import { QuestionAnswerSimpleViewer } from '../../../../components/QuestionAnswerViewer'
 */ import './style.css'
import { SquareRoundedLetterQ } from 'tabler-icons-react'
import { fetchQuiz } from '../../../../utils/commonFunctions'
import { Round } from '../../../../utils/types'
import { AddPageComponent } from './components/AddPageComponent'

type QuizContentSectionProps = {
  quizId: string
  setSelectedPage: (posiition: number) => void
  selectedPage: number
  pages: any | null
  setPages: any
  isPageDirty: boolean
  setPageDirty: (isPageDirty: boolean) => void
}

export const QuizContentSection = ({
  selectedPage,
  setSelectedPage,
  pages,
  setPages,
  quizId,
  isPageDirty,
  setPageDirty,
}: QuizContentSectionProps) => {
  const [showAddPageOptions, setShowAddPageOptions] = useState(false)
  const [rounds, setRounds] = useState<Round[]>([{ number: 1, questions: [] }])

  const startFetchQuiz = useCallback(
    async (ignoreQuizFetch?: boolean) => {
      const result = await fetchQuiz(parseInt(quizId ?? '0'))
      if (!ignoreQuizFetch) {
        setRounds(result.rounds)
      }
    },
    [quizId]
  )

  useEffect(() => {
    let ignoreQuizFetch = false

    if (quizId == null) return

    startFetchQuiz(ignoreQuizFetch)

    return () => {
      ignoreQuizFetch = true
    }
  }, [quizId, startFetchQuiz])

  const renderSidebarIcons = (page: any) => {
    switch (page.type) {
      case 'signUp':
        return <UserPlus size={24} strokeWidth={1} color={'#FFF'} />
      case 'start':
        return <ArrowBigDownLines size={24} strokeWidth={1} color={'#FFF'} />
      case 'page':
        return <SquareRoundedLetterP size={24} strokeWidth={1} color={'#FFF'} />
      case 'round':
        return <div />
      case 'question':
        return <SquareRoundedLetterQ size={24} strokeWidth={1} color={'#FFF'} />
      case '  ':
        return <Lock size={24} strokeWidth={2} color={'#FFF'} />
      case 'rateQuiz':
        return <Stars size={24} strokeWidth={2} color={'#FFD700'} />
      default:
        return <div />
    }
  }

  const rightSectionSidebar = (page: any) => {
    switch (page.type) {
      case 'signUp':
        return <Lock size={24} strokeWidth={1} color={'rgba(255,255,255,0.3)'} />
      case 'rateQuiz':
        return <Lock size={24} strokeWidth={2} color={'rgba(255,255,255,0.3)'} />
      default:
        return <div />
    }
  }

  return (
    <>
      <Grid.Col xs={12} p={0}>
        <Grid.Col>
          <Button onClick={() => setShowAddPageOptions(true)}>Add</Button>
        </Grid.Col>
      </Grid.Col>
      {pages !== null &&
        pages.map((page: any, idx: number) => (
          <NavLink
            className={`navlinkCustom ${page.type === 'round' ? 'roundNavlink' : 'none'}`}
            key={page?.id + page.questionText || page.text || page.type}
            active={page.position === selectedPage}
            label={
              (page.questionText || page.text || page.type).length > 50
                ? (page.questionText || page.text || page.type).substring(0, 50) + '...'
                : page.questionText || page.text || page.type
            }
            rightSection={rightSectionSidebar(page)}
            variant={page.position === selectedPage ? 'filled' : 'subtle'}
            icon={renderSidebarIcons(page)}
            color='gray'
            onClick={() => {
              setSelectedPage(page.position)
            }}
          />
        ))}
      {showAddPageOptions && (
        <AddPageComponent
          quizId={parseInt(quizId)}
          pages={pages}
          setPages={setPages}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setShowAddPageOptions={setShowAddPageOptions}
          isPageDirty={isPageDirty}
          setPageDirty={setPageDirty}
        />
      )}
    </>
  )
}
