import React from 'react'

import { Card, Text, Group, Badge, createStyles, Rating } from '@mantine/core'
import { Organization, Tag } from '../../utils/types'
import { useTranslation } from 'react-i18next'
import { hostname } from '../../utils/axios'
import { useNavigate } from 'react-router-dom'
import { ImageRetry } from '../ImageRetry'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: '0.2s ease-in-out',
    cursor: 'pointer',
    '&.with-shadow:hover': {
      boxShadow: '0px 0px 25px 2px rgba(255,255,255,0.3)',
    },
    '&.with-cursor': {
      cursor: 'pointer',
    },
  },
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },
  like: {
    color: theme.colors.red[6],
  },
  label: {
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}))

export type OrganizationCardProps = {
  organization: Organization
  isHomepage: boolean
}

export const OrganizationCard = ({ organization, isHomepage }: OrganizationCardProps) => {
  const { classes, theme } = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const features = organization.tags.map((tag) => (
    <Badge
      color={theme.colorScheme === 'dark' ? 'dark' : 'gray'}
      key={tag.label}
      leftSection={tag.emoji}
    >
      {tag.label}
    </Badge>
  ))

  return (
    <Card
      withBorder
      radius='md'
      p='md'
      style={{ cursor: isHomepage ? 'pointer' : 'default' }}
      className={`${classes.card} ${!isHomepage ? 'with-cursor' : ''} ${
        !isHomepage ? '' : 'with-shadow'
      }`}
      onClick={() => {
        navigate(`/organization-info/${organization.id!}`)
      }}
    >
      <Card.Section>
        <ImageRetry
          src={`${hostname}/${organization.image}`}
          alt={organization.name}
          height={540}
        />
      </Card.Section>
      <Card.Section className={classes.section} mt='md'>
        <Group position='apart'>
          <Text size='lg' weight={500}>
            {organization.name}
          </Text>
          <Badge size='sm'>{organization.city}</Badge>
        </Group>
        <Group spacing={10} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {organization.rating && (
            <Text size={'sm'}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>{t('quiz_rating')}</div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Rating defaultValue={organization.rating} readOnly />
                <div>({organization?.ratingCount})</div>
              </div>
            </Text>
          )}
          {organization.difficulty && (
            <Text size={'sm'}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {t('quiz_difficulty')}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Rating defaultValue={organization.difficulty} readOnly />
                <div>({organization?.difficultyCount})</div>
              </div>
            </Text>
          )}
        </Group>
        <Text size='sm' mt='xs'>
          {organization.description}
        </Text>
      </Card.Section>
      <Card.Section className={classes.section}>
        <Text mt='md' className={classes.label} color='dimmed'>
          {t('tagSectionTitle')}
        </Text>
        <Group spacing={7} mt={5}>
          {features}
        </Group>
      </Card.Section>
    </Card>
  )
}
