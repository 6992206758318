import { Modal, createStyles, SimpleGrid, TextInput, Group, Button } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Answer } from '../../../utils/types'

export type SimpleAnswerEditorProps = {
  isOpen: boolean
  closeModalAction: () => void
  saveButtonAction: (dirtyPayload: any) => void
  pointsNeeded?: boolean
  answerToEdit: Answer
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  }
})

export const SimpleAnswerEditor = ({
  isOpen,
  closeModalAction,
  saveButtonAction,
  answerToEdit,
  pointsNeeded = true,
}: SimpleAnswerEditorProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const editAnswerAction = (dirtyPayload: any) => {
    saveButtonAction(dirtyPayload)
  }

  const form = useForm<Answer>({
    initialValues: answerToEdit ?? { id: 0, text: '', isCorrect: false, points: 0 },

    validate: (values) => ({
      label: values.text.length < 1 ? t('missing_answer_input') : null,
    }),
  })

  useEffect(() => {
    if (answerToEdit != null) {
      form.setValues(answerToEdit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerToEdit])

  const handleSubmit = (values: Answer) => {
    let answerDirtyPayload: { [key: string]: any } = { id: values.id }

    for (const [key, value] of Object.entries(values)) {
      if (form.isDirty(key)) {
        answerDirtyPayload[key] = value
      }
    }

    console.log(answerDirtyPayload)

    editAnswerAction(answerDirtyPayload)
    form.reset()
  }

  return (
    <Modal centered opened={isOpen} onClose={() => closeModalAction()}>
      <form
        className={classes.form}
        onSubmit={form.onSubmit((values) => {
          handleSubmit(values)
        })}
      >
        <div className={classes.fields}>
          <SimpleGrid cols={1}>
            <TextInput
              label={t('answer_text')}
              placeholder={t('answer_text') ?? ''}
              required
              {...form.getInputProps('text')}
            />
            {pointsNeeded && (
              <TextInput
                label={t('answer_points')}
                placeholder={t('answer_points') ?? ''}
                required={pointsNeeded}
                {...form.getInputProps('points')}
                type='number'
              />
            )}
          </SimpleGrid>
          <Group position='right' mt='md'>
            <Button type='submit' className={classes.control}>
              {t('save_button')}
            </Button>
          </Group>
        </div>
      </form>
    </Modal>
  )
}
