import React, { useCallback, useEffect, useState } from 'react'
import {
  Container,
  createStyles,
  Table,
  Text,
  ActionIcon,
  Button,
  Group,
  Badge,
  Switch,
  Menu,
  Divider,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Plus } from 'tabler-icons-react'
import { useModals } from '@mantine/modals'
import { useNavigate } from 'react-router-dom'
import { Organization, ROLES_ENUM, User } from '../../utils/types'
import { deleteOrganization } from '../../utils/axios'
import { useAuth } from '../../contexts/AuthProvider'
import { fetchUsers } from '../Users/helper'
import { useGeneralContext } from '../../contexts/GeneralProvider'

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    title: {
      color: theme.white,
      fontSize: 52,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xl * 1.5,
    },
    table: {
      width: '100%',
      marginBottom: theme.spacing.xl,

      '& thead tr th': {
        textAlign: 'center',
      },
    },
    centered: {
      textAlign: 'center',
    },
    right: {
      textAlign: 'right',
    },
  }
})

export const Organizations = () => {
  const { organizations, refreshOrganizations } = useGeneralContext()
  const { userProfile } = useAuth()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const modals = useModals()
  const [userOrganizations, setUserOrganizations] = useState<Organization[] | null>(null)
  const [users, setUsers] = useState<User[] | null>(null)
  const navigate = useNavigate()

  const startFetchUsers = useCallback(async (ignoreFetch?: boolean) => {
    const result = await fetchUsers()
    if (!ignoreFetch) {
      setUsers(result)
    }
  }, [])

  const filterUserOrganizations = useCallback(() => {
    let userOrganizationsTmp: Organization[] = []

    userOrganizationsTmp = organizations.filter((org) => {
      return (
        userProfile?.organizations?.some((userOrg) => org.id === userOrg.id) ||
        org.owner === userProfile?.id ||
        userProfile?.roleId === ROLES_ENUM.SUPER_ADMIN
      )
    })
    setUserOrganizations(userOrganizationsTmp)
  }, [organizations, userProfile?.id, userProfile?.organizations, userProfile?.roleId])

  useEffect(() => {
    let ignoreUsersFetch = false
    startFetchUsers(ignoreUsersFetch)
    filterUserOrganizations()

    return () => {
      ignoreUsersFetch = true
    }
  }, [filterUserOrganizations, organizations, startFetchUsers])

  const openDeleteModal = (orgId: number) =>
    modals.openConfirmModal({
      title: t('delete_quiz_title'),
      centered: true,
      children: <Text size='sm'>{t('delete_organization')}</Text>,
      labels: { confirm: t('delete_title'), cancel: t('cancel_title') },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        deleteOrganization(orgId).then((res) => {
          refreshOrganizations()
        })
      },
    })

  const orgRows = userOrganizations?.map((organization: Organization) => (
    <tr key={organization.id}>
      <td>{organization.name}</td>
      <td>{organization.country}</td>
      <td>{organization.city}</td>
      <td>
        {
          <Group spacing={7} mt={5}>
            {organization.tags.map((tag) => (
              <Badge variant='outline' key={tag.label} leftSection={tag.emoji} size='lg'>
                {tag.label}
              </Badge>
            ))}
          </Group>
        }
      </td>
      <td>
        <Switch
          label={t('active_switch')}
          labelPosition='left'
          size='md'
          sx={{ display: 'flex', justifyContent: 'center' }}
          checked={organization.isActive}
          readOnly
        />
      </td>
      <td>
        <Switch
          label={t('delete_switch')}
          labelPosition='left'
          size='md'
          sx={{ display: 'flex', justifyContent: 'center' }}
          checked={organization.isDeleted}
          readOnly
        />
      </td>
      <td align='center'>{users?.find((user) => user.id === organization.owner)?.email ?? '?'}</td>
      <td>
        <Menu shadow='md'>
          <Menu.Target>
            <ActionIcon>
              <span className='material-symbols-outlined'>more_vert</span>
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<span className='material-symbols-outlined'>edit</span>}
              onClick={(e: any) => {
                e.stopPropagation()
                navigate(`/organizations/${organization.id}`)
              }}
            >
              {t('editTitle')}
            </Menu.Item>
            <Divider />
            <Menu.Item
              color={'red'}
              icon={<span className='material-symbols-outlined'>delete</span>}
              onClick={(e: any) => {
                e.stopPropagation()
                openDeleteModal(organization.id!)
              }}
            >
              {t('delete_title')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  ))

  const headers = (
    <tr>
      <th>{t('title')}</th>
      <th>{t('country')}</th>
      <th>{t('city')}</th>
      <th>{t('organizationCategoriesTitle')}</th>
      <th>{t('active_switch')}</th>
      <th>{t('delete_switch')}</th>
      <th>{t('createdBy')}</th>
      <th></th>
    </tr>
  )

  return (
    <Container my='md' size={'xl'}>
      <Button
        leftIcon={<Plus size={14} />}
        onClick={() => {
          navigate(`/organizations/new`)
        }}
      >
        {t('add_new_organization')}
      </Button>
      <div className='table-wrapper'>
        <Table highlightOnHover className={classes.table} withColumnBorders>
          <thead>{headers}</thead>
          <tbody>{orgRows}</tbody>
          {/* <tfoot>{headers}</tfoot> */}
        </Table>
      </div>
    </Container>
  )
}
