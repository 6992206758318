import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Homepage } from './containers/Homepage'
import { AuthProvider } from './contexts/AuthProvider'
import { AdminDashboard } from './containers/AdminDashboard'
import { ProtectedRoute } from './containers/ProtectedRoutes'
import { MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import { Navigation } from './containers/NavigationHeader'
import { NoMatch } from './containers/NoMatch'
import { Profile } from './containers/Profile'
import { AllQuizzes } from './containers/QuizManagement/AllQuizzes'
import { ModalsProvider } from '@mantine/modals'
import { AllQuestionTypes } from './containers/QuizManagement/QuestionTypesManagement'
import { SingleQuiz } from './containers/QuizManagement/SingleQuiz'
import { QuestionCreationForm } from './containers/QuestionCreation'
import { Organizations } from './containers/Organizations'
import { Roles } from './containers/Users/Roles'
import { Users } from './containers/Users/Users'
import { CreateEditOrganization } from './containers/Organizations/CreateEditOrganization'
import { PublicOrganizationInfo } from './containers/Organizations/PublicOrganizationInfo'
import { QuizPlayer } from './containers/QuizPlayer'
import { QuizPlayerSignUp } from './containers/QuizPlayer/QuizPlayerSignUp'
import { CookiePanel } from './components/CookiePanel'
import { useTranslation } from 'react-i18next'
import { LOCAL_STORAGE_CONSTANTS } from './utils/commonData'
import { RateQuiz } from './containers/QuizPlayer/RateQuiz'
import { GeneralProvider } from './contexts/GeneralProvider'
import { TeamsRanking } from './containers/QuizPlayer/TeamsRankingPreview'
import { useFullscreenToggle } from './utils/customHooks/useFullScreenToggle'

export const App = () => {
  const { i18n } = useTranslation()
  const { isFullscreen, toggleFullscreen } = useFullscreenToggle()

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem(LOCAL_STORAGE_CONSTANTS.selectedLanguage) ?? 'hr')
  }, [i18n])

  return (
    <MantineProvider
      theme={{ colorScheme: 'dark', loader: 'bars' }}
      withGlobalStyles
      withNormalizeCSS
    >
      <NotificationsProvider position='top-center'>
        <GeneralProvider>
          <ModalsProvider>
            <AuthProvider>
              <div className={isFullscreen ? 'fullscreen' : ''} style={{ height: '100%' }}>
                {isFullscreen ? null : <Navigation />}
                <Routes>
                  <Route index element={<Navigate to='/home' replace />} />
                  <Route path='home' element={<Homepage />} />
                  <Route
                    path='users'
                    element={
                      <ProtectedRoute>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='roles'
                    element={
                      <ProtectedRoute>
                        <Roles />
                      </ProtectedRoute>
                    }
                  />
                  <Route path='organization-info'>
                    <Route path={':organizationId'} element={<PublicOrganizationInfo />} />
                  </Route>
                  <Route path='organizations'>
                    <Route
                      index
                      element={
                        <ProtectedRoute>
                          <Organizations />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=':organizationId'
                      element={
                        <ProtectedRoute>
                          <CreateEditOrganization />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='new'
                      element={
                        <ProtectedRoute>
                          <CreateEditOrganization />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route
                    path='admin'
                    element={
                      <ProtectedRoute>
                        <AdminDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='profile'
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={'quiz-player/:quizId'}
                    element={
                      <ProtectedRoute>
                        <QuizPlayer
                          handleFullscreenToggle={toggleFullscreen}
                          isFullScreen={isFullscreen}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={'quiz-player/:quizId/sign-up-team'}
                    element={
                      <ProtectedRoute>
                        <QuizPlayerSignUp />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={'quiz-player/:quizId/rate-quiz'}
                    element={
                      <ProtectedRoute>
                        <RateQuiz />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={'quiz-player/:quizId/insert-team-results'}
                    element={
                      <ProtectedRoute>
                        <TeamsRanking isInserting={true} />
                      </ProtectedRoute>
                    }
                  />
                  <Route path='quiz-management'>
                    <Route
                      index
                      element={
                        <ProtectedRoute>
                          <AllQuizzes />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=':quizId'
                      element={
                        <ProtectedRoute>
                          <SingleQuiz />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=':quizId/:questionId'
                      element={
                        <ProtectedRoute>
                          <QuestionCreationForm />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path=':quizId/question-creation'
                      element={
                        <ProtectedRoute>
                          <QuestionCreationForm />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route
                    path='question-type-management'
                    element={
                      <ProtectedRoute>
                        <AllQuestionTypes />
                      </ProtectedRoute>
                    }
                  />
                  <Route path='*' element={<NoMatch />} />
                </Routes>
              </div>
            </AuthProvider>
          </ModalsProvider>
        </GeneralProvider>
      </NotificationsProvider>
      <CookiePanel />
    </MantineProvider>
  )
}
