import { Avatar, createStyles, Title } from '@mantine/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    userName: {
      color: theme.white,
      fontSize: 20,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginTop: theme.spacing.xl,
    },
  }
})

function ProfileImage() {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <>
      <Avatar src={'/user_profile_img.png'} alt={t('profile_img_alt') ?? ''} size={192} />
      <Title align='center' className={classes.userName}>
        Adam Mehman
      </Title>
    </>
  )
}

export default React.memo(ProfileImage)
