import React, { useState } from 'react'
import { Button, Container, createStyles, SimpleGrid, Title, Text } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContactSupport } from '../../components/ContactSupport'
import { ImageRetry } from '../../components/ImageRetry'

export const NoMatch = () => {
  const useStyles = createStyles((theme) => ({
    root: {
      paddingTop: 80,
      paddingBottom: 80,
    },

    title: {
      fontWeight: 900,
      fontSize: 34,
      marginBottom: theme.spacing.md,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [theme.fn.smallerThan('sm')]: {
        fontSize: 32,
      },
    },

    control: {
      [theme.fn.smallerThan('sm')]: {
        width: '100%',
      },
    },

    mobileImage: {
      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },

    desktopImage: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },
  }))

  const { classes } = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [supportDetailsOpened, setSupportDetailsOpened] = useState<boolean>(false)

  const triggerSupportDetailsForm = () => {
    setSupportDetailsOpened((prevState) => !prevState)
  }

  return (
    <>
      <Container className={classes.root}>
        <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
          <ImageRetry src={'/404_image.svg'} className={classes.mobileImage} />
          <div>
            <Title className={classes.title}>{t('page_not_exists_title')}</Title>
            <Text color='dimmed' size='lg'>
              {t('page_not_exists_message')}
            </Text>
            <Button
              variant='outline'
              size='md'
              mt='xl'
              className={classes.control}
              onClick={() => {
                triggerSupportDetailsForm()
              }}
            >
              {t('contact_support_button')}
            </Button>
            <br />
            <Button
              variant='filled'
              size='md'
              mt='xl'
              className={classes.control}
              onClick={() => {
                // TODO - Navigation bar should be refreshed on navigate result
                navigate('/home')
              }}
            >
              {t('go_to_home_button')}
            </Button>
          </div>
          <ImageRetry src={'/404_image.svg'} className={classes.desktopImage} />
        </SimpleGrid>
      </Container>
      <ContactSupport closeModalAction={triggerSupportDetailsForm} isOpen={supportDetailsOpened} />
    </>
  )
}
