import { ActionIcon, Container, Grid } from '@mantine/core'
import React from 'react'
import { ArrowsDiagonal, ArrowsDiagonalMinimize2, PlayerPlay } from 'tabler-icons-react'
import { PlayerSkipBack } from 'tabler-icons-react'
import { PlayerSkipForward } from 'tabler-icons-react'

type QuizPlayerButtonsProps = {
  selectedPage: number
  setSelectedPage: (number: number) => void
  idx: number
  isLast: boolean
  isFirst: boolean
  handleFullscreenToggle: () => void
  isFullScreen: boolean
}

export const QuizPlayerButtons = ({
  selectedPage,
  setSelectedPage,
  idx,
  isLast,
  isFirst,
  handleFullscreenToggle,
  isFullScreen,
}: QuizPlayerButtonsProps) => {
  return (
    <Container
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '10%',
        left: 0,
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      fluid
    >
      <div style={{ margin: '0 auto' }}>
        <Grid style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Grid.Col
            span={2}
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <ActionIcon
              variant='outline'
              color='gray'
              radius='xl'
              disabled={isFirst}
              style={{
                borderRadius: '50%',
                height: '20%',
                width: '2vw',
                aspectRatio: 1,
                display: 'grid',
                placeItems: 'center',
              }}
              size='xl'
              onClick={() => {
                if (isFirst) return
                setSelectedPage(selectedPage - 1)
              }}
            >
              <PlayerSkipBack size={20} strokeWidth={1} color={'white'} />
            </ActionIcon>
            <ActionIcon
              variant='outline'
              color='gray'
              radius='xl'
              size='xl'
              style={{
                borderRadius: '50%',
                height: '100%',
                width: '4vw',
                aspectRatio: 1,
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <PlayerPlay size={30} strokeWidth={1} color={'white'} />
            </ActionIcon>
            <ActionIcon
              variant='outline'
              color='gray'
              radius='xl'
              disabled={isLast}
              style={{
                borderRadius: '50%',
                height: '20%',
                width: '2vw',
                aspectRatio: 1,
                display: 'grid',
                placeItems: 'center',
              }}
              size='xl'
              onClick={() => {
                if (isLast) return
                setSelectedPage(selectedPage + 1)
              }}
            >
              <PlayerSkipForward
                size={20}
                style={{ fill: 'white' }}
                strokeWidth={1}
                color={'white'}
              />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={2} style={{ position: 'absolute', right: '20px' }}>
            <ActionIcon
              style={{
                borderRadius: '10%',
                height: '20%',
                width: '2vw',
                aspectRatio: 1,
                display: 'grid',
                placeItems: 'center',
              }}
              size={'md'}
              onClick={handleFullscreenToggle}
            >
              {isFullScreen ? (
                <ArrowsDiagonalMinimize2 size={30} strokeWidth={2} color={'white'} />
              ) : (
                <ArrowsDiagonal size={30} strokeWidth={2} color={'white'} />
              )}
            </ActionIcon>
          </Grid.Col>
        </Grid>
      </div>
    </Container>
  )
}
