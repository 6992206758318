import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Center, Container, Group, Rating, Stack, Title } from '@mantine/core'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { getQuizInfo, sendQuizFeedback } from '../../../utils/axios'
import { showErrorNotification, showSuccessfulNotification } from '../../../utils/commonFunctions'
import { Quiz } from '../../../utils/types'

export type QuizFeedback = {
  rating: number
  difficulty: number
}
export const RateQuiz = () => {
  const { quizId } = useParams()
  const navigate = useNavigate()

  const [quizInfo, setQuizInfo] = useState<Quiz>()
  const [rating, setRating] = useState<number>(4)
  const [difficulty, setDifficulty] = useState<number>(3)

  useEffect(() => {
    if (quizId) {
      getQuizInfo(parseInt(quizId))
        .then((r) => {
          setQuizInfo(r)
        })
        .catch((e) => {
          showErrorNotification(t('error_title'), `${t('error_general_message')} ${e.message}`)
        })
    }
  }, [quizId])

  const submitQuizFeedback = () => {
    if (quizId) {
      const data: QuizFeedback = {
        rating: rating,
        difficulty: difficulty,
      }

      sendQuizFeedback(parseInt(quizId), data)
        .then(() => {
          showSuccessfulNotification(t('information'), t('successfully_saved'))
        })
        .catch(() => showErrorNotification(t('error_title'), `${t('error_general_message')}`))
        .finally(() => {
          navigate('/home')
        })
    }
  }

  return (
    <Container>
      <Center>
        <Box maw={300} mx='auto'>
          <Stack>
            <Center>
              <Title order={1}>{quizInfo?.name ?? ''}</Title>
            </Center>
            <Group>
              <div> {t('quiz_rating')}</div>
              <Rating value={rating} onChange={setRating} />
            </Group>
            <Group>
              <div>{t('quiz_difficulty')}</div>
              <Rating value={difficulty} onChange={setDifficulty} />
            </Group>
            <Button onClick={() => submitQuizFeedback()}>{t('save_button')}</Button>
          </Stack>
        </Box>
      </Center>
    </Container>
  )
}
