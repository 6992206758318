export const handleDragStart = (event: any, index: number, draggableItemClass: string) => {
  event.dataTransfer.setData('index', index)
  event.target.closest(draggableItemClass).classList.add('moving')
}

export const handleDragOver = (event: any, draggableItemClass: string) => {
  event.preventDefault()
  event.dataTransfer.dropEffect = 'move'
  event.target.closest(draggableItemClass).classList.add('moving')
}

export const handleDragLeave = (event: any, draggableItemClass: string) => {
  event.preventDefault()
  event.dataTransfer.dropEffect = 'move'
  event.target.closest(draggableItemClass).classList.remove('moving')
}

export const handleDrop = (
  event: any,
  newIndex: number,
  draggableItemClass: string,
  itemList: any[],
  persistChangeFunction: (changedItemList: any[]) => void
) => {
  event.preventDefault()
  const dragIndex = event.dataTransfer.getData('index')
  console.log('BEFORE DROP: ', itemList)
  const newListData = [...itemList]
  const draggedItem = newListData.splice(dragIndex, 1)[0]
  newListData.splice(newIndex, 0, draggedItem)
  console.log('AFTER DROP: ', newListData)
  persistChangeFunction(newListData)

  if (draggableItemClass.length > 0)
    document.querySelectorAll(draggableItemClass).forEach((item) => item.classList.remove('moving'))
}
