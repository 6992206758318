import React from 'react'
import { QuestionPageQuizPlayer } from '../../../../utils/types'
import { Center, Grid } from '@mantine/core'
import { hostname } from '../../../../utils/axios'
import { ImageRetry } from '../../../../components/ImageRetry'

type MultiplePickAnswerProps = {
  questionPageQuizPlayer: QuestionPageQuizPlayer
}
export const MultiplePickAnswerQuizPlayer = ({
  questionPageQuizPlayer,
}: MultiplePickAnswerProps) => {
  return (
    <Grid m={0} grow gutter='xl' style={{ height: '100%' }}>
      <Grid.Col
        span={4}
        offsetSm={0.5}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <h1 className={'question-text'}>
          <b>{questionPageQuizPlayer.questionText}</b>
        </h1>
        {questionPageQuizPlayer.answers.map((x, idx) => {
          const bulletPoint = String.fromCharCode(97 + idx) + ') '
          return (
            <h2 className={'answer-text'} key={x.id}>
              {bulletPoint + x.text}
            </h2>
          )
        })}
      </Grid.Col>
      <Grid.Col span={4} style={{ marginTop: '2em' }}>
        <Center>
          <ImageRetry src={hostname + '/' + questionPageQuizPlayer.image} alt={''} width='80%' />
        </Center>
      </Grid.Col>
    </Grid>
  )
}
