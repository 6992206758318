import React from 'react'
import { QuestionPageQuizPlayer } from '../../../../utils/types'
import { Grid } from '@mantine/core'
import './style.scss'

type RiskProps = {
  questionPageQuizPlayer: QuestionPageQuizPlayer
}

export const RiskQuizPlayer = ({ questionPageQuizPlayer }: RiskProps) => {
  return (
    <Grid m={0} className={'flex-center'} grow gutter={'xl'}>
      <div className={'grid'}>
        {questionPageQuizPlayer.subQuestions.map((subQuestion, index) => (
          <div key={index} className={'sub-question'}>
            <div className={'sub-question-text'}>{subQuestion.text}</div>
          </div>
        ))}
      </div>
    </Grid>
  )
}
