import React from 'react'
/* import { Page } from '../index'

type PageEditBarProps = {
  page: Page
} */

export const PageEditBar = ({ page }: any) => {
  return <h1>{page.type}</h1>
}
