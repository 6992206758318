import React from 'react'
import { QuestionPageQuizPlayer } from '../../../../utils/types'
import { Center, Grid, Image } from '@mantine/core'
import { hostname } from '../../../../utils/axios'
import { ImageRetry } from '../../../../components/ImageRetry'

type WriteAnswerQuizPlayerProps = {
  questionPageQuizPlayer: QuestionPageQuizPlayer
}

export const WriteAnswerQuizPlayer = ({ questionPageQuizPlayer }: WriteAnswerQuizPlayerProps) => {
  return (
    <Grid m={0} grow gutter='xl'>
      <Grid.Col span={4} offsetSm={0.5}>
        <h1>{questionPageQuizPlayer.questionText}</h1>
      </Grid.Col>
      <Grid.Col span={4}>
        <Center>
          <ImageRetry src={hostname + '/' + questionPageQuizPlayer.image} alt={''} width='80%' />
        </Center>
      </Grid.Col>
    </Grid>
  )
}
