import {
  Modal,
  createStyles,
  Text,
  SimpleGrid,
  TextInput,
  Textarea,
  Group,
  Button,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'

export type ContactSupportProps = {
  isOpen: boolean
  closeModalAction: () => void
}

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan('sm')

  return {
    form: {
      boxSizing: 'border-box',
      flex: 1,
      padding: theme.spacing.xl,
      borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    fieldInput: {
      flex: 1,

      '& + &': {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },

    fieldsGroup: {
      display: 'flex',

      [BREAKPOINT]: {
        flexDirection: 'column',
      },
    },

    title: {
      marginBottom: theme.spacing.xl * 1.5,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.xl,
      },
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  }
})

export const ContactSupport = ({ isOpen, closeModalAction }: ContactSupportProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const sendMessageAction = () => {}

  return (
    <>
      <Modal centered opened={isOpen} onClose={() => closeModalAction()}>
        <form className={classes.form} onSubmit={(event) => event.preventDefault()}>
          <Text size='lg' weight={700} className={classes.title}>
            {t('contact_support_details_title')}
          </Text>
          <div className={classes.fields}>
            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
              <TextInput label={t('your_name_title')} placeholder={t('your_name_title') ?? ''} />
              <TextInput label={t('your_email_title')} placeholder='hello@mail.com' required />
            </SimpleGrid>
            <TextInput
              mt='md'
              label={t('subject_title')}
              placeholder={t('subject_title') ?? ''}
              required
            />
            <Textarea
              mt='md'
              label={t('your_message_title')}
              placeholder={t('your_message_title') ?? ''}
              minRows={3}
            />
            <Group position='right' mt='md'>
              <Button
                type='submit'
                className={classes.control}
                onClick={() => {
                  sendMessageAction()
                }}
              >
                {t('send_message_button')}
              </Button>
            </Group>
          </div>
        </form>
      </Modal>
    </>
  )
}
